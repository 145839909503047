<utclub-modal title="Islem Gecmisi" [buttons]="buttons">

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <div *ngFor="let transaction of transactionHistories">

    <table>
      <tr>
        <th>Field</th>
        <th>Old Value</th>
        <th>New Value</th>
      </tr>
      <tr *ngFor="let field of fields">
        <td>{{field}}</td>
        <td>{{transaction.oldValueObject[field]}}</td>
        <td>{{transaction.newValueObject[field]}}</td>
      </tr>
    </table>

  </div>

</utclub-modal>