<utclub-modal title="Ogrenci Secimi" [buttons]="buttons">
  <utclub-formly [form]="form" [fields]="formFields" [model]="model"> </utclub-formly>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <mat-selection-list #studentList [(ngModel)]="selectedEnrollments" [multiple]="true">
    <mat-list-option *ngFor="let enrollment of enrollments" [value]="enrollment">
      <span matListItemTitle>
        #{{ enrollment.enrollmentId }} {{ enrollment.enrollmentName }}
        @if(enrollment.externalId){
        ({{ enrollment.externalId }})
        }
        <span class="text-secondary">({{ enrollment.receipt }} / {{ enrollment.credit }})</span>
      </span>
      <span matListItemLine>
        <span *ngIf="enrollment.visaStatus"><b>Vize Durumu: </b> {{ enrollment.visaStatus | enum: 'VisaStatus' | async
          }}
        </span>
        <span><b>Program Baslangic: </b> {{ enrollment.programStartDate | date: 'dd-MM-yyyy' }}</span>
        <span *ngIf="enrollment.schoolCountryId">
          <b>Ulke: </b>
          {{ enrollment.schoolCountryId | entity: 'Country' | async }}
        </span>
      </span>
    </mat-list-option>
  </mat-selection-list>
</utclub-modal>