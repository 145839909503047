<utclub-modal title="Odeme" [buttons]="buttons">

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <utclub-transaction *ngFor="let transaction of transactions" [transaction]="transaction"></utclub-transaction>

  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]" (page)="pageChanged($event)"
    class="sticky left-0">
  </mat-paginator>

</utclub-modal>