<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span> Hesap Raporu </span>
    </h2>

    <div *ngIf="isManager"
      class="bg-foreground rounded-full border px-2 max-w-[200px] flex-auto flex items-center ml-4">
      <mat-select [(ngModel)]="branchId" (valueChange)="onChangeBranch($event)">
        <mat-option *ngFor="let serviceProvider of branches" [value]="serviceProvider.id">
          {{serviceProvider.name}}
        </mat-option>
      </mat-select>
    </div>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && result">
    <tbody>
      <tr>
        <th [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }">
          Alacak / Verecek
        </th>
        <th *ngFor="let currency of currencies" colspan="3" [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }"
          style="border-right: solid #e0e0e0 1px; border-left: solid #e0e0e0 1px;"> {{currency.name}} </th>
      </tr>

      <tr *ngFor="let debit of result.branchDebits">
        <td> {{ debit.accountCaseName }} </td>
        <ng-container *ngFor="let currency of currencies">
          <td style="border-left: solid #e0e0e0 1px;">
            <div *ngIf="!debit.credits[currency.code]" class="rounded px-2 text-green bg-green/10">
              0
            </div>
            <div *ngIf="debit.credits[currency.code]" class="rounded px-2 text-green bg-green/10 cursor-pointer"
              (click)="listTransactions(debit.credits[currency.code + 'DebitAccountId'])">
              {{ debit.credits[currency.code] }}
            </div>
          </td>
          <td>
            <div *ngIf="!debit.debits[currency.code]" class="rounded px-2 text-red bg-red/10">
              0
            </div>
            <div *ngIf="debit.debits[currency.code]" class="rounded px-2 text-red bg-red/10 cursor-pointer"
              (click)="listTransactions(debit.debits[currency.code + 'DebitAccountId'])">
              {{ debit.debits[currency.code] }}
            </div>
          </td>
          <td style="border-right: solid #e0e0e0 1px;">
            <div class="rounded px-2 text-gray bg-gray/10  cursor-pointer" (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ debit: debit, currency: currency }" [matMenuTriggerFor]="debitMenu">
              {{ (debit.credits[currency.code] ?? 0) - (debit.debits[currency.code] ?? 0) ?? '' }}
            </div>
          </td>
        </ng-container>
      </tr>


      <tr style="border-top: 1.5px solid #e0e0e0;">
        <th [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }">
          Ara Toplam
        </th>
        <ng-container *ngFor="let currency of currencies">
          <th style="border-left: solid #e0e0e0 1px;">
            <div class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              {{ credits[currency.code] }}
            </div>
          </th>
          <th>
            <div class="rounded px-2 text-red bg-red/10" style="display: inline-block;">
              {{ debits[currency.code] }}
            </div>
          </th>
          <th style="border-right: solid #e0e0e0 1px;">
            <div class="rounded px-2 text-gray bg-gray/10" style="display: inline-block;">
              {{ totals[currency.code] }}
            </div>
          </th>
        </ng-container>
      </tr>

      <tr *ngFor="let accountCase of result.accountCases">
        <td [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }"> {{accountCase.accountCaseName}} </td>
        <ng-container *ngFor="let currency of currencies">
          <td style="border-left: solid #e0e0e0 1px;" colspan="3">
            <div class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              {{ accountCase.cashes[currency.code] }}
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <th [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }"> Toplam Nakit </th>
        <ng-container *ngFor="let currency of currencies">
          <th style="border-left: solid #e0e0e0 1px;" colspan="3">
            <div class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              {{ totalCash[currency.code] }}
            </div>
          </th>
        </ng-container>
      </tr>

      <tr>
        <th [ngStyle]="{ 'min-width': columnWidth * 3 + 'px' }"> Toplam Varlik </th>
        <ng-container *ngFor="let currency of currencies">
          <th style="border-left: solid #e0e0e0 1px;" colspan="3">
            <div class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              {{ result.balances[currency.code] }}
            </div>
          </th>
        </ng-container>
      </tr>


    </tbody>
  </table>
</div>

<mat-menu #debitMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-debit="debit" let-currency="currency" matMenuContent>
    <button mat-menu-item (click)="resetDebitAccounts(debit, currency)" [disabled]="
      !debit.credits[currency.code + 'DebitAccountId'] || !debit.debits[currency.code + 'DebitAccountId'] ||
      debit.credits[currency.code] == 0 || debit.debits[currency.code] == 0">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
      <span>Hesaplari sadelestir</span>
    </button>
  </ng-template>
</mat-menu>