import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { OfferDto } from 'src/app/api/models/offerDto';
import { OfferFilterDto } from 'src/app/api/models/offerFilterDto';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { InquirySendOfferFormComponent } from './inquiry-send-offer-form/inquiry-send-offer-form.component';
import { InquiryDto } from 'src/app/api/models/inquiryDto';

@Component({
  selector: 'utclub-inquiry-form-offers',
  templateUrl: './inquiry-form-offers.component.html'
})
export class InquiryFormOffersComponent implements OnInit {
  @Input() id: number;
  @Input() inquiry: InquiryDto;

  loading: boolean = false;

  columns: string[] = ['id', 'userName', 'plannedStartDate', 'actions'];
  dataSource: MatTableDataSource<OfferDto> = null;
  pageSize: number = 10;
  page: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  offerUrl;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.offerUrl = environment.OFFER_URL;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    var filterDto: OfferFilterDto = {
      inquiryId: this.id,
      pageSize: this.pageSize,
      page: this.page
    };
    this.apiService.post<PagedResponse<OfferDto>>('student-service/offer/filter', filterDto).subscribe((response) => {
      this.dataSource = new MatTableDataSource(response.data);
      if (this.paginator) this.paginator.length = response.itemsCount;
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadData();
  }

  sendOfferDialog(offer: OfferDto) {
    this.dialog.open(InquirySendOfferFormComponent, {
      width: '500px',
      data: {
        offerId: offer.id,
        email: this.inquiry.student.email
      }
    });
  }
}
