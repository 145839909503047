<div class="card overflow-auto w-full flex flex-col">
  <div class="card-header">
    <h2>Offers</h2>
    <button (click)="loadData()" class="flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>
  </div>
  <div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

    <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table multiTemplateDataRows>
      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell>Offer ID</th>
        <td *matCellDef="let row" class="w-4" mat-cell>
          {{ row.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th *matHeaderCellDef mat-header-cell>Consultant</th>
        <td *matCellDef="let row" class="w-4" mat-cell>
          {{ row.userName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="plannedStartDate">
        <th *matHeaderCellDef mat-header-cell>Planned Start Date</th>
        <td *matCellDef="let row" class="w-4" mat-cell>
          {{ row.plannedStartDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell class="w-4 text-right">Actions</th>
        <td *matCellDef="let row" class="w-4 text-right" mat-cell>
          <button (click)="sendOfferDialog(row)" mat-icon-button color="primary" matTooltip="Send Offer" type="button">
            <mat-icon svgIcon="mat:send"></mat-icon>
          </button>
          <a
            [href]="offerUrl + row.uniqueId"
            target="_blank"
            class="cursor-pointer text-underline text-blue"
            mat-icon-button
            color="primary"
            matTooltip="View Offer"
            type="button">
            <mat-icon svgIcon="mat:visibility"></mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let subElement; columns: columns" class="trans-ease-out"></tr>
      <tr *matNoDataRow>
        <td [attr.colspan]="columns.length">
          <div class="text-center mt-3">
            <p>Inquiry has no offers</p>
          </div>
        </td>
      </tr>
    </table>
    <mat-paginator
      *ngIf="!loading && dataSource"
      [pageSize]="pageSize"
      (page)="pageChanged($event)"
      class="sticky left-0">
    </mat-paginator>
  </div>
</div>
