import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit, OnDestroy {

  onlineUsers: { id: string, name: string }[] = [];

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');

  constructor(
    private signalrService: SignalrService
  ) { }

  ngOnDestroy(): void { }

  ngOnInit() {
    this.signalrService.hubConnection.on('OnlineUsers', (users) => {
      this.onlineUsers = users;
    });
  }
}
