import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { StudentLogDto } from 'src/app/api/models/studentLogDto';
import { PagedResponse, Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';

export interface CustomStudentLogDto extends StudentLogDto {
  oldVal: any;
  newVal: any;
}

@Component({
  selector: 'utclub-student-log',
  templateUrl: './student-log.component.html',
  styleUrls: ['./student-log.component.scss']
})
export class StudentLogComponent implements AfterContentInit {
  @Input() id: number;

  data: CustomStudentLogDto[] = null;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {}

  loading: boolean = false;
  ngAfterContentInit(): void {
    this.loadData();
  }

  loadData() {
    var model = {
      id: this.id,
      paging: false
    };
    this.loading = true;
    this.cdr.detectChanges();
    this.apiService
      .post<PagedResponse<CustomStudentLogDto>>('student-service/student/studentlog/filter', model)
      .subscribe({
        next: (response) => {
          var data = response.data;
          data.forEach((log) => {
            log.oldVal = log.oldValue ? JSON.parse(log.oldValue) : null;
            log.newVal = log.newValue ? JSON.parse(log.newValue) : null;
          });

          this.data = data;

          this.logCategories = [
            { name: 'UT', logs: this.utLogs },
            { name: 'Program', logs: this.programLogs },
            { name: 'Accounting', logs: this.accountingLogs },
            { name: 'Inquiry', logs: this.inquiryLogs },
            { name: 'Enrollment', logs: this.enrollmentLogs }
          ];

          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  /* 

  #ut
  StudentInformationUpdate = 1,
  StudentPhotoUpdate = 2,
  InquiryUtInformationUpdate = 1004,
  EnrollmentUtInformationUpdate = 2004,
  EnrollmentAdvisorsUpdate = 2005,

  #program
  EnrollmentProgramInformationUpdate = 2006,
  InquiryProgramInformationUpdate = 1006,
  EnrollmentDateInformationUpdate = 2013,
  EnrollmentProgramLocationUpdate = 2009,
  EnrollmentSchoolInformationUpdate = 2014,
  EnrollmentUpdateLocationInformation = 2015,
  EnrollmentLanguageSchoolInformationUpdate = 2016,
  EnrollmentSummerSchoolInformationUpdate = 2017,
  EnrollmentAcademicSchoolInformationUpdate = 2018,
  EnrollmentInternshipInformationUpdate = 2019,

  #accounting
  EnrollmentDebitAmountsUpdate = 2008,
  EnrollmentUpdateLanguageSchoolInvoiceInformation = 2020,
  EnrollmentUpdateSummerSchoolInvoiceInformation = 2021,

  #other

  #inquiry
  InquiryCreated = 1001,
  InquiryFollowUpCreated = 1002,
  InquiryCancelled = 1003,
  InquiryImported = 1007,
  InquiryOfferCreated = 1008,
  InquiryEnrolled = 1009,
  InquiryUserChange = 1010,

  #enrollment
  EnrollmentCreated = 2001,
  EnrollmentCancelled = 2003,
  EnrollmentVisaApplicationDateUpdate = 2010,
  EnrollmentVisaResultUpdate = 2011,
  EnrollmentFlightDateUpdate = 2012,
  EnrollmentTaskUpdate = 2101,
  EnrollmentTaskCompleted = 2102,
  */

  logCategories: { name: string; logs: CustomStudentLogDto[] }[] = null;

  get utLogs(): CustomStudentLogDto[] {
    return this.data?.filter((x) => [1, 2, 3, 1004, 2004, 2005].includes(x.logType));
  }

  get programLogs(): CustomStudentLogDto[] {
    return this.data?.filter((x) => [2006, 1006, 2013, 2009, 2014, 2015, 2016, 2017, 2018, 2019].includes(x.logType));
  }

  get accountingLogs(): CustomStudentLogDto[] {
    return this.data?.filter((x) => [2008, 2020, 2021].includes(x.logType));
  }

  get inquiryLogs(): CustomStudentLogDto[] {
    return this.data?.filter((x) => [1001, 1011, 1002, 1003, 1007, 1008, 1009, 1010].includes(x.logType));
  }

  get enrollmentLogs(): CustomStudentLogDto[] {
    return this.data?.filter((x) => [2001, 2003, 2010, 2011, 2012, 2101, 2102].includes(x.logType));
  }
}
