import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Response, PagedResponse } from "./../../../../models/response.model";
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

@Component({
  selector: 'currency-field',
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>
      <input matInput [formControl]="control" [formlyAttributes]="field" [hidden]="field.hide" 
        [required]="field.props.required" type="number" [step]="0.01" />
    </mat-form-field>
  `,
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class CurrencyFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit {
  ngOnInit(): void {
  }

  get control() {
    return this.formControl as FormControl
  }
}

