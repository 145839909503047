import { AfterContentInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CityDto } from 'src/app/api/models/cityDto';
import { CountryDto } from 'src/app/api/models/countryDto';
import { SchoolBranchDto } from 'src/app/api/models/schoolBranchDto';
import { getStudentSourceFields } from 'src/app/components/utclub-formly/base-student-source-field';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { SchoolDto } from 'src/app/api/models/schoolDto';
import { LookupService } from 'src/app/services/lookup.service';
import { Enums } from 'src/app/services/enums';

@Component({
  selector: 'utclub-enrollment-create-form',
  templateUrl: './enrollment-create-form.component.html',
  styleUrls: ['./enrollment-create-form.component.scss']
})
export class EnrollmentCreateFormComponent implements AfterContentInit {
  errorMessage: string = '';
  errors: { key: string; value: string }[] = [];

  personalFormGroup: FormGroup = new FormGroup({});
  utFormGroup: FormGroup = new FormGroup({});
  programInformationFormGroup: FormGroup = new FormGroup({});
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  formArray = new FormArray([this.personalFormGroup, this.utFormGroup, this.programInformationFormGroup]);
  model: any;

  personalFields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'Ad',
            required: true
          }
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Soyad',
            required: true
          }
        },
        {
          key: 'gender',
          type: 'select',
          props: {
            label: 'Cinsiyet',
            required: true,
            options: [
              { label: 'Erkek', value: 1 },
              { label: 'Kadin', value: 2 }
            ]
          }
        },
        {
          key: 'birthDate',
          type: 'datepicker',
          props: {
            required: true,
            label: 'Birth Date'
          }
        },
        {
          key: 'email',
          type: 'input',
          props: {
            label: 'Eposta',
            required: true
          },
          validators: {
            validation: ['email']
          }
        },
        {
          key: 'phoneNumber',
          type: 'input',
          props: {
            label: 'Telefon Numarasi',
            required: true,
            minLength: 10,
            maxLength: 12
          }
        },
        {
          type: '#turkeyCity'
        },
        {
          key: 'identityNumber',
          type: 'input',
          props: {
            label: 'Kimlik Numarası',
            required: true,
            minLength: 11,
            maxLength: 11
          }
        },
        {
          key: 'educationLevel',
          type: 'enum-select',
          props: {
            label: 'Eğitim Seviyesi',
            required: true,
            enum: Enums.EducationLevel
          }
        },
        {
          key: 'lastSchool',
          type: 'input',
          props: {
            label: 'Eğitim Aldigi Son Okul'
          }
        },
        {
          key: 'address',
          type: 'input',
          props: {
            label: 'Adres',
            required: true
          }
        }
      ]
    }
  ];

  utFields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          type: '#branch',
          props: {
            required: true
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'consultantId',
          type: 'http-field',
          props: {
            label: 'Consultant',
            http: {
              url: 'auth-service/user?roleName=Consultant'
            },
            labelProp: 'fullName'
          }
        },
        {
          key: 'supportId',
          type: 'http-field',
          props: {
            label: 'Support',
            http: {
              url: 'auth-service/user?roleName=Support'
            },
            labelProp: 'fullName'
          }
        },
        {
          key: 'supervisorId',
          type: 'http-field',
          props: {
            label: 'Supervisor',
            http: {
              url: 'auth-service/user?roleName=Supervisor'
            },
            labelProp: 'fullName'
          }
        },
        {
          key: 'managerId',
          type: 'http-field',
          props: {
            label: 'Program Manager',
            http: {
              url: 'auth-service/user?roleName=ProgramManager'
            },
            labelProp: 'fullName'
          }
        },
        {
          key: 'visaResponsibleId',
          type: 'http-field',
          props: {
            label: 'Visa Responsible',
            http: {
              url: 'auth-service/user?roleName=VisaResponsible'
            },
            labelProp: 'fullName'
          }
        }
      ]
    }
  ];

  programFields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'programType',
          type: 'select',
          props: {
            label: 'Form Tipi',
            required: true,
            options: [
              { label: 'Dil Okulu', value: 1 },
              { label: 'Yaz Okulu', value: 2 },
              { label: 'Yaz Okulu Grup', value: 9 },
              { label: 'Diploma', value: 6 },
              { label: 'Internship', value: 3 },
              { label: 'Üniversite', value: 4 },
              { label: 'Lise', value: 7 },
              { label: 'Master', value: 8 },
              { label: 'Pathways', value: 10 },
              { label: 'Work and Travel', value: 5 }
            ]
          }
        },
        {
          key: 'enrollmentGroupId',
          type: 'http-field',
          props: {
            label: 'Grup',
            required: true,
            http: {
              url: 'student-service/enrollment-group',
              parentProp: 'programType',
              parentPropQuery: 'programType'
            }
          },
          hideExpression: 'model.programType != 9'
        }
      ]
    },
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'jobPlacement',
          type: 'select',
          props: {
            label: 'Basvuru Tipi',
            required: true,
            options: [
              { label: 'With Service Provider', value: 1 },
              { label: 'Self', value: 2 }
            ]
          }
        },
        {
          key: 'programId',
          type: 'http-field',
          props: {
            label: 'Program',
            http: {
              url: `student-service/program?ProgramType=3`
            }
          }
        },
        {
          key: 'languageLevel',
          type: 'select',
          props: {
            label: 'Dil Seviyesi',
            options: [
              { label: 'Seçiniz', value: null },
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 }
            ]
          }
        }
      ],
      expressions: {
        hide: 'model.programType != 3'
      }
    },
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'deposit',
          type: 'currency-field',
          props: {
            label: 'Deposit Amount'
          },
          defaultValue: null,
          expressions: {
            hide: (field: CustomFormlyFieldConfig) => this.depositExpression(field)
          }
        },
        {
          key: 'depositCurrencyId',
          type: '#currency',
          props: {
            label: 'Deposit Currency',
            required: true
          },
          expressions: {
            hide: (field: CustomFormlyFieldConfig) => this.depositExpression(field) && !field.model.deposit,
            required: 'model.deposit && model.deposit > 0'
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'programFee',
          type: 'currency-field',
          props: {
            label: 'Program Fee'
          },
          defaultValue: null,
          expressions: {
            hide: (field: CustomFormlyFieldConfig) => this.programFeeExpression(field)
          }
        },
        {
          key: 'programFeeCurrencyId',
          type: '#currency',
          props: {
            label: 'Program Fee Currency',
            required: true
          },
          expressions: {
            hide: (field: CustomFormlyFieldConfig) => this.programFeeExpression(field) && !field.model.programFee,
            required: 'model.programFee && model.programFee > 0'
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'programStartDate',
          type: 'datepicker',
          props: {
            label: 'Program Start Date',
            required: true
          }
        }
      ]
    }
  ];

  depositExpression: (field: CustomFormlyFieldConfig) => boolean = (field: CustomFormlyFieldConfig) => {
    if (field.model.programType == 3) return true;
    return false;
  };

  programFeeExpression: (field: CustomFormlyFieldConfig) => boolean = (field: CustomFormlyFieldConfig) => {
    return false;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private lookup: LookupService,
    private dialogRef: MatDialogRef<EnrollmentCreateFormComponent>,
    private eventBusService: EventBusService,
    private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngAfterContentInit(): void {
    this.stepper._getIndicatorType = () => 'number';

    if (this.defaults?.inquiryId) {
      this.loadData(this.defaults?.inquiryId);
    } else {
      this.personalFormGroup.markAllAsTouched();
      this.utFormGroup.markAllAsTouched();
      this.programInformationFormGroup.markAllAsTouched();
      this.cdr.detectChanges();
    }
  }

  loading: boolean = false;
  loadData(inquiryId: number) {
    this.loading = true;
    this.cdr.detectChanges();
    this.apiService.get(`student-service/enrollment/load-from-inquiry/${inquiryId}`).subscribe(
      (response) => {
        this.model = response.data;
        if (this.model.gender == 0) {
          this.model.gender = null;
        }

        this.personalFormGroup.markAllAsTouched();
        this.utFormGroup.markAllAsTouched();
        this.programInformationFormGroup.markAllAsTouched();
        this.loading = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onSuccess(event) {}

  submitLoading: boolean = false;
  submit() {
    this.submitLoading = true;
    this.apiService.post<Response<number>>('student-service/enrollment', this.model).subscribe(
      (response) => {
        this.router.navigate(['/enrollment/detail', response.data]);
        this.submitLoading = false;
        this.dialogRef.close();
      },
      (error) => {
        this.errors = error.error.errors;
        if (error.error.message) {
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = this.translateService.instant('errors.unknown');
        }
        this.submitLoading = false;
      }
    );
  }
}
