<utclub-modal title="Find Enrollment">
  <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel"> </utclub-formly>
  <utclub-button label="Filter" (click)="loadData()"> </utclub-button>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <div class="w-full overflow-y-scroll">
    <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table matSort>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Öğrenci</th>
        <td *matCellDef="let row" mat-cell>
          #{{ row.id }} <br />
          {{ row.student.firstName }} {{ row.student.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Durum</th>
        <td *matCellDef="let row" mat-cell>
          <div>
            <div
              class="rounded px-2 inline-block"
              [class.text-amber-500]="[1, 2].includes(row.status)"
              [class.bg-amber-100]="[1, 2].includes(row.status)"
              [class.border-amber]="[1, 2].includes(row.status)"
              [class.text-blue-500]="row.status == 3"
              [class.bg-blue-100]="row.status == 3"
              [class.border-blue]="row.status == 3"
              [class.text-green-500]="row.status == 4"
              [class.bg-green-100]="row.status == 4"
              [class.border-green]="row.status == 4"
              [class.text-red-500]="[5, 6].includes(row.status)"
              [class.bg-red-100]="[5, 6].includes(row.status)"
              [class.border-red]="[5, 6].includes(row.status)"
              [class.text-gray-500]="row.status == 7"
              [class.bg-gray-200]="row.status == 7"
              [class.border-gray]="row.status == 7">
              {{ row.status | enum: 'EnrollmentStatus' | async }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>Durum</th>
        <td *matCellDef="let row" mat-cell>
          <div class="rounded px-2 inline-block" [class]="'enrollment-type-' + row.programType">
            {{ row.programType | enum: 'ProgramType' | async }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ut">
        <th *matHeaderCellDef mat-header-cell>UT</th>
        <td *matCellDef="let row" mat-cell>
          <div class="overflow-hidden flex-auto">
            <div class="font-medium">{{ row.consultantId | entity: 'User' | async }}</div>
            <div>{{ row.branchId | entity: 'Branch' | async }}</div>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        (click)="rowClick(row)"
        class="hover:bg-gray-500 cursor-pointer trans-ease-out"
        mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="visibleColumns.length">No data matching the filter.</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [hidePageSize]="true"
    [length]="totalItemCount"
    (page)="pageEvent($event)"
    class="sticky left-0">
  </mat-paginator>
</utclub-modal>
