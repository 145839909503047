import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FormlyFormOptions } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CountryDto } from 'src/app/api/models/countryDto';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { UtclubTableComponent } from 'src/app/components/utclub-table/utclub-table.component';
import { FeeDto } from 'src/app/models/fee.model';
import { Response, PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-inquiry-offer-form-fee-table',
  templateUrl: './inquiry-offer-form-fee-table.component.html',
  styleUrls: ['./inquiry-offer-form-fee-table.component.scss'],
  animations: [fadeInUp400ms, stagger40ms]
})
export class InquiryOfferFormFeeTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() addToOffer: EventEmitter<FeeDto> = new EventEmitter<FeeDto>();
  @Input() programType!: number;

  dataSource: MatTableDataSource<FeeDto> = null;
  loading: boolean = false;
  pageSize: number = 10;
  pageIndex: number = 0;
  totalItemCount: number = 0;

  filterModel: any = {};
  schoolField: CustomFormlyFieldConfig = {
    key: 'schoolId',
    type: 'select',
    props: { label: 'Okul' }
  };
  filterFields: CustomFormlyFieldConfig[];

  columns: string[] = ['schoolBranchId', 'programId', 'priceExpireDate', 'campaignExpireDate', 'actions'];

  selection = new SelectionModel<FeeDto>(true, []);

  @ViewChild('utclubTable', { static: true }) table: UtclubTableComponent;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService
  ) {}
  ngOnInit(): void {
    this.loadSchools('student-service/school');
  }

  ngAfterViewInit(): void {
    this.loadData();
    this.configureFilterForm();
    this.filterFields = [
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
        fieldGroup: [
          {
            type: '#country'
          },
          {
            type: '#cityByCountry'
          },
          this.schoolField,
          {
            key: 'programId',
            type: 'http-field',
            props: {
              label: 'Program',
              http: {
                url: 'student-service/program?programType=' + this.programType
              }
            }
          }
        ]
      }
    ];
  }
  ngOnDestroy(): void {
    this.filterFormSubs?.unsubscribe();
  }

  loadData() {
    this.loading = true;
    this.apiService
      .post<PagedResponse<FeeDto>>('student-service/fee/filter', {
        ...this.filterModel,
        page: this.pageIndex,
        pageSize: this.pageSize
      })
      .subscribe((response) => {
        this.totalItemCount = response.itemsCount;
        this.dataSource = new MatTableDataSource(response.data);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  addToOfferClick(row: FeeDto) {
    this.addToOffer.emit(row);
  }

  filterFormSubs: Subscription;
  configureFilterForm() {
    this.filterFormSubs = this.filterForm.valueChanges.subscribe((value: any) => {
      var schoolLoadUrl = 'student-service/school';
      if (value.countryId) {
        schoolLoadUrl += '?countryId=' + value.countryId;
      }
      if (value.cityId) {
        if (schoolLoadUrl.indexOf('?') > -1) {
          schoolLoadUrl += '&cityId=' + value.cityId;
        } else {
          schoolLoadUrl += '?cityId=' + value.cityId;
        }
      }
      if (value.countryId || value.cityId) this.loadSchools(schoolLoadUrl);
      this.pageIndex = 0;
      this.loadData();
      this.cdr.detectChanges();
    });
  }

  loadSchools(loadUrl) {
    this.apiService.get<Response<CountryDto[]>>(loadUrl).subscribe({
      next: (response) => {
        this.schoolField.props.options = response.data.map((x) => {
          return { label: x.name, value: x.id };
        });
      }
    });
  }

  filterOpened: boolean = true;
  filterForm = new FormGroup({});
  filterToggle() {
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    this.loadData();
  }

  deleteSelect(feeDto: FeeDto) {
    this.selection.deselect(feeDto);
  }
}
