<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <utclub-button buttonType="button" [label]="'Filtre'" (click)="filterToggle()"
      [icon]="filterOpened ? 'mat:keyboard_arrow_down' : 'mat:keyboard_arrow_right'"></utclub-button>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>

    <utclub-button *ngFor="let button of actions" [button]="button">
    </utclub-button>
  </div>

  <div *ngIf="filterOpened" @fadeInUp class="p-4">
    <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel">
    </utclub-formly>

    <div>
      <utclub-button buttonType="button" [label]="'Filtrele'" (click)="loadData()" class="mr-2">
      </utclub-button>
      <utclub-button buttonType="button" [label]="'Temizle'" [color]="'warn'" (click)="clearFilter()">
      </utclub-button>
    </div>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table matSort
    (matSortChange)="sortChanged($event)">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Tarih </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'dd-MM-yy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="executorUser">
      <th mat-header-cell *matHeaderCellDef> İslemi Yapan </th>
      <td mat-cell *matCellDef="let element"> {{element.executorUserName}} </td>
    </ng-container>

    <ng-container matColumnDef="person">
      <th mat-header-cell *matHeaderCellDef> Ogrenci / Servis Saglayici </th>
      <td mat-cell *matCellDef="let element">

        <span *ngIf="element.debitType == 1">
          {{ element.enrollmentName }} <br>
          #{{ element.enrollmentId }}
        </span>
        <span *ngIf="element.debitType == 2">
          {{ element.staffName }}
        </span>
        <span *ngIf="element.debitType == 3">
          {{ element.serviceProviderName }} <br>
          {{ element.enrollmentName }} #{{ element.enrollmentId }}
        </span>
        <span *ngIf="element.debitType == 4" style="display: flex; align-items: center; justify-content: start;">
          {{ element.debtorAccountName }}
          <mat-icon svgIcon="mat:arrow_forward"></mat-icon>
          {{ element.creditorAccountName }}
        </span>

      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Detay </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="element.debitType">
          <div *ngSwitchCase="1">
            {{element.enrollmentName}}<br>
            #{{element.enrollmentId}}
          </div>
          <div *ngSwitchCase="2">
            {{ element.staffName }}
          </div>
          <div *ngSwitchCase="3">
            {{ element.serviceProviderName }}
          </div>
          <div *ngSwitchCase="4">
            {{ element.debtorAccountName }} > {{ element.creditorAccountName }}
          </div>
          <div *ngSwitchCase="5">
            {{ element.schoolName }}
          </div>
          <div *ngSwitchCase="6">
            {{ element.ministryProviderName }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="debitType">
      <th mat-header-cell *matHeaderCellDef> İslem Tipi </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="element.debitType">
          <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block;"> Ogrenci
          </div>
          <div *ngSwitchCase="2" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;"> Personel
          </div>
          <div *ngSwitchCase="3" class="rounded px-2 text-primary bg-primary/10" style="display: inline-block;"> Servis
            Saglayici
          </div>
          <div *ngSwitchCase="4" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;"> Ofis Ofise
          </div>
          <div *ngSwitchCase="5" class="rounded px-2 text-blue bg-blue/10" style="display: inline-block;"> Okul
          </div>
          <div *ngSwitchCase="6" class="rounded px-2 text-red bg-red/10" style="display: inline-block;"> Hizmet
            Saglayici
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef> Detay </th>
      <td mat-cell *matCellDef="let element">

        <div *ngIf="element.debitType == 1">
          {{ element.debitTypeStudent | enum:'DebitTypeEnrollment' | async }}
        </div>
        <div *ngIf="element.debitType == 2">
          {{ element.debitTypeStaff | enum:'DebitTypeStaff' | async }}
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Miktar </th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{ element.amount | currency:element.currencyId | async}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef> Not </th>
      <td mat-cell *matCellDef="let element">
        {{ element.note }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
      <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
        <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ entity: row }"
          [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row @fadeInUp (click)="rowClicked(row)" class="hover:bg-hover trans-ease-out cursor-pointer"
      *matRowDef="let row; columns: columns;"></tr>
  </table>

  <mat-paginator [pageSize]="50" (page)="pageChanged($event)" class="sticky left-0">
  </mat-paginator>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-entity="entity" matMenuContent>
    <utclub-button *ngFor="let button of rowActions" [button]="button" [entity]="entity">
    </utclub-button>
  </ng-template>
</mat-menu>