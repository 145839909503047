import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { PagedResponse, Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

export interface ProgramFeeCreditDto {
  enrollmentId: number;
  enrollmentName: string;
  currencyId: number;
  totalCredit: number;
  totalReceipt: number;
  transactions: ProgramFeeCreditTransactionDto[];

  expanded: boolean;
}

export interface ProgramFeeCreditTransactionDto {
  id: number;
  date: string;
  type: number;
  relatedBranchName: string;
  relatedBranchId: number | null;
  executorUserName: string;
  executorUserId: number;
  accountName: string;
  accountId: number | null;
  currencyId: number;
  amount: number;
  note: string;
}

@Component({
  selector: 'utclub-credit-program-fee',
  templateUrl: './credit-program-fee.component.html',
  styleUrls: ['./credit-program-fee.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class CreditProgramFeeComponent implements AfterViewInit {
  data: ProgramFeeCreditDto[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading = false;
  pageSize: number = 50;
  pageIndex: number = 0;
  constructor(
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService,
    private snackbar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.cdr.detectChanges();

    var url = `accounting-service/credit/program-fee-credits`;
    this.apiService
      .post<PagedResponse<ProgramFeeCreditDto>>(url, {
        page: this.paginator?.pageIndex ?? this.pageIndex,
        pageSize: this.paginator?.pageSize ?? this.pageSize
      })
      .subscribe({
        next: (response) => {
          this.data = response.data;
          this.loading = false;
          this.paginator.length = response.itemsCount;
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.snackbar.open(error.error.message, 'Close', { duration: 5000 });
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }
  pageChanged(event) {
    this.loadData();
  }
}
