<div class="vex-user-menu">
  <a
    routerLink="/identity/profile"
    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Profil</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  @if (isOriginalAccount) {
    @if (isManager) {
      <a (click)="changeUser()" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
        <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
        <div class="vex-user-menu-item__label">Hesap Degistir</div>
      </a>
    }
  } @else {
    <a
      (click)="revertOriginalUser()"
      class="vex-user-menu-item"
      matRipple
      matRippleColor="rgb(var(--color-primary), 0.1)">
      <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
      <div class="vex-user-menu-item__label">Orijinal Hesaba Dön</div>
    </a>
  }

  <a (click)="logout()" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Cikis Yap</div>
  </a>
</div>
