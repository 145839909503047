<div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">





  <span class="flex-1"></span>

  <button *ngIf="form.dirty" class="flex-none" mat-raised-button matTooltip="Kaydet" [color]="'primary'"
    (click)="save()">
    Kaydet
  </button>

  <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
    <mat-icon svgIcon="mat:more_horiz"></mat-icon>
  </button>

</div>


<div @fadeInUp class="card p-8">

  <form [formGroup]="form" class="mb-5">
    <div class="grid grid-cols-2">
      <div>
        <h4 class="body-2 text-secondary mt-16">Customer</h4>
        <table class="mt-2 w-full">
          <tbody>
            <tr>
              <td class="text-secondary body-2" width="10%">Name</td>
              <td class="text-left border-b" width="90%" colspan="5">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.name}}</span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [(ngModel)]="model.name" formControlName="name" class="w-full">
                  </ng-template>
                </editable>
              </td>
            </tr>
            <tr>
              <td class="text-secondary body-2" width="10%">Address</td>
              <td class="text-left border-b" width="90%" colspan="5">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.address}}</span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [(ngModel)]="model.address" formControlName="address" class="w-full">
                  </ng-template>
                </editable>
              </td>
            </tr>
            <!-- <tr>
                <td class="text-secondary body-2" width="15%">Phone</td>
                <td class="text-left border-b" width="35%" colspan="2">
                  <editable>
                    <ng-template viewMode>
                      <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.phoneNumber}}</span>
                    </ng-template>
                    <ng-template editMode>
                      <input matInput [(ngModel)]="model.phoneNumber" formControlName="phoneNumber" class="w-full">
                    </ng-template>
                  </editable>
                </td>
                <td class="text-secondary body-2" width="10%">Email</td>
                <td class="text-left border-b" width="40%" colspan="2">
                  <editable>
                    <ng-template viewMode>
                      <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.email}}</span>
                    </ng-template>
                    <ng-template editMode>
                      <input matInput [(ngModel)]="model.email" formControlName="email" class="w-full">
                    </ng-template>
                  </editable>
                </td>
              </tr> -->
            <tr>
              <td class="text-secondary body-2" width="10%">Country</td>
              <td class="text-left border-b" width="24%">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.country}}</span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [(ngModel)]="model.country" formControlName="country" class="w-full">
                  </ng-template>
                </editable>
              </td>
              <td class="text-secondary body-2" width="10%">City</td>
              <td class="text-left border-b" width="23%">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.city}}</span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [(ngModel)]="model.city" formControlName="city" class="w-full">
                  </ng-template>
                </editable>
              </td>
              <td class="text-secondary body-2" width="10%">ZIP</td>
              <td class="text-left border-b" width="23%">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.zip}} </span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [(ngModel)]="model.zip" formControlName="zip" class="w-full">
                  </ng-template>
                </editable>
              </td>
            </tr>
            <!-- <tr>
                <td class="text-secondary body-2 w-10">VAT</td>
                <td class="text-left border-b w-90" colspan="5">
                  <editable>
                    <ng-template viewMode>
                      <span class="cursor-pointer flex w-full h-full"> &nbsp; {{model.taxNumber}}</span>
                    </ng-template>
                    <ng-template editMode>
                      <input matInput [(ngModel)]="model.taxNumber" formControlName="taxNumber" class="w-full">
                    </ng-template>
                  </editable>
                </td>
              </tr> -->
          </tbody>
        </table>
      </div>

      <div class="text-right body-1 flex flex-col items-end">
        <table>
          <tbody>
            <tr>
              <td class="text-secondary body-2">
                FATURA NO
              </td>
              <td class="text-left pl-4">
                <span *ngIf="model.number"> {{model.number}} </span>
                <mat-icon *ngIf="!model.number" svgIcon="mat:info" class="ml-2"
                  matTooltip="Faturayi kaydettikten sonra numara olusacaktir."></mat-icon>
              </td>
            </tr>
            <tr>
              <td class="text-secondary body-2">FATURA TARIHI</td>
              <td class="text-left pl-4">
                <editable>
                  <ng-template viewMode>
                    <span class="cursor-pointer">{{model.date | date: 'dd/MM/yyyy'}}</span>
                  </ng-template>
                  <ng-template editMode>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="model.date" formControlName="date"
                      class="w-full">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </ng-template>
                </editable>
              </td>
            </tr>
            <tr>
              <td class="text-secondary body-2">FATURA DURUMU</td>
              <td>
                <editable (save)="invoiceStatusUpdated()">
                  <ng-template viewMode>
                    <span *ngIf="model.invoiceStatus == 0" class="cursor-pointer"> Odeme Bekliyor </span>
                    <span *ngIf="model.invoiceStatus == 1" class="cursor-pointer"> Odeme Alindi </span>
                    <span *ngIf="model.invoiceStatus == 2" class="cursor-pointer"> Taslak </span>
                    <span *ngIf="model.invoiceStatus == 3" class="cursor-pointer"> Batik </span>
                  </ng-template>

                  <ng-template editMode>
                    <div class="bg-foreground rounded-full border px-2 flex-auto flex items-center ml-4"
                      style="width: 200px;">
                      <mat-select [(ngModel)]="model.invoiceStatus" formControlName="invoiceStatus">
                        <mat-option *ngFor="let invoiceStatus of invoiceStatuses" [value]="invoiceStatus.id">
                          {{invoiceStatus.name}}
                        </mat-option>
                      </mat-select>
                    </div>
                  </ng-template>
                </editable>
              </td>
            </tr>
            <tr>
              <td class="text-secondary body-2">PARA BIRIMI</td>
              <td>
                <editable (save)="currencyUpdated()">
                  <ng-template viewMode>
                    <span *ngIf="!model.currencyId || model.currencyId == 0" class="cursor-pointer flex w-full h-full"> &nbsp; </span>
                    <span *ngIf="model.currencyId != 0" class="cursor-pointer"> {{currencyName}} </span>
                  </ng-template>
                  <ng-template editMode>
                    <div class="bg-foreground rounded-full border px-2 flex-auto flex items-center ml-4"
                      style="width: 200px;">
                      <mat-select [(ngModel)]="model.currencyId" formControlName="currencyId">
                        <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                          {{currency.name}}
                        </mat-option>
                      </mat-select>
                    </div>
                  </ng-template>
                </editable>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-3">
          <div class="text-secondary">
            <p class="mb-2" style="display: flex; justify-content: end;">
              <img src="/assets/img/logo.png" class="max-w-[200px]">
            </p>
            <p class="m-0">Taksim Meydani Mete Cd. Mete Palas Apt. No:20/9</p>
            <p class="m-0">Beyoglu - İstanbul Turkey</p>
            <p class="m-0 mt-2"> info&#64;unitedtowers.com </p>
            <p class="m-0"> +90 212 244 5005 </p>
          </div>
        </div>
      </div>
    </div>

    <table class="table w-full mt-16">
      <thead>
        <tr>
          <td class="body-2 text-secondary">DESCRIPTION</td>
          <td class="body-2 text-secondary" width="20%">PRICE</td>
          <td class="body-2 text-secondary print-no" width="5%">
            <button mat-icon-button (click)="addLine()">
              <mat-icon svgIcon="mat:add"></mat-icon>
            </button>
          </td>
        </tr>
      </thead>
      <tbody class="mt-2" formArrayName="items">

        <tr *ngFor="let item of items.controls; let i=index" [formGroupName]="i">
          <td class="py-2 border-b">
            <editable>
              <ng-template viewMode>
                <span class="cursor-pointer">{{item.value.name}}</span>
              </ng-template>
              <ng-template editMode>
                <input matInput formControlName="name" class="w-full">
              </ng-template>
            </editable>
          </td>
          <td class="py-2 border-b" width="20%">

            <editable>
              <ng-template viewMode>
                <span class="cursor-pointer" *ngIf="model.currencyId">{{item.value.price | currency:model.currencyId |
                  async}}</span>
                <span class="cursor-pointer" *ngIf="!model.currencyId">{{item.value.price}}</span>
              </ng-template>
              <ng-template editMode>
                <input matInput formControlName="price" class="w-full">
              </ng-template>
            </editable>
          </td>

          <td class="py-2 border-b print-no" width="5%">
            <button mat-icon-button (click)="removeLine(i)">
              <mat-icon svgIcon="mat:remove"></mat-icon>
            </button>
        </tr>

      </tbody>
    </table>

    <div class="mt-8 flex flex-col items-end">
      <table class="table w-full subheading-2 font-medium pr-6">
        <tbody>
          <tr>
            <td class="headline font-medium text-right pt-6">TOTAL</td>
            <td class="headline font-medium pl-12 pt-6" width="20%" *ngIf="model.currencyId">
              {{model.total | currency:model.currencyId | async}} </td>
            <td class="headline font-medium pl-12 pt-6" width="20%" *ngIf="!model.currencyId"> {{model.total}} </td>
            <td class="headline font-medium pl-12 pt-6 print-no" width="5%" *ngIf="!model.currencyId"> {{model.total}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <h4 class="body-2 text-secondary mt-16 mb-3">Wire Transfer Information</h4>
    <div *ngFor="let bank of currencyBanks" class="p-4 rounded border mb-3">

      <table class="mt-2 w-full">
        <tbody>
          <tr>
            <td class="text-secondary body-2" width="20%">Account Name:</td>
            <td class="text-left" width="80%" colspan="3">{{bank.accountName}}</td>
          </tr>
          <tr>
            <td class="text-secondary body-2" width="20%">Bank Name:</td>
            <td class="text-left" width="30%">{{bank.bankName}}</td>
            <td class="text-secondary body-2" width="20%">Account Currency:</td>
            <td class="text-left" width="30%">{{currencyName}}</td>
          </tr>
          <tr>
            <td class="text-secondary body-2" width="20%">IBAN:</td>
            <td class="text-left" width="30%">{{bank.iban}}</td>
            <td class="text-secondary body-2" width="20%">Switf Code:</td>
            <td class="text-left" width="30%">{{bank.swiftCode}}</td>
          </tr>
        <tbody>
          <tr>
            <td class="text-secondary body-2" width="20%">Bank Address:</td>
            <td class="text-left" width="80%" colspan="3">{{bank.bankAddress}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

</div>



<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>

    <button mat-menu-item (click)="selectCustomer()">
      <mat-icon svgIcon="mat:person"></mat-icon>
      Musteri Sec
    </button>

    <button mat-menu-item (click)="selectStudents()">
      <mat-icon svgIcon="mat:school"></mat-icon>
      Ogrencileri Getir
    </button>

    <!-- PDF indir butonu -->
    <button mat-menu-item (click)="pdf()" *ngIf="model.number">
      <mat-icon svgIcon="mat:file_download"></mat-icon>
      PDF Olarak Indir
    </button>

    <!-- PDF gonder butonu -->
    <button mat-menu-item (click)="openEmailForm()" *ngIf="model.number">
      <mat-icon svgIcon="mat:send"></mat-icon>
      PDF Olarak Gonder
    </button>

  </ng-template>
</mat-menu>