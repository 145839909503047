import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionHistoryDto } from 'src/app/api/models/transactionHistoryDto';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';

export interface CustomTransactionHistoryDto extends TransactionHistoryDto {
  newValueObject: any;
  oldValueObject: any;
}


@Component({
  selector: 'utclub-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent {
  transactionId: number;

  transactionHistories: CustomTransactionHistoryDto[];
  loading: boolean = false;

  fields: string[] = [];

  public buttons: UtclubButtonInterface[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.transactionId = data.transactionId;
  }

  ngOnInit(): void {
  }
  ngAfterContentInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.apiService.get<Response<CustomTransactionHistoryDto[]>>('accounting-service/transaction/history/' + this.transactionId).subscribe(response => {
      this.transactionHistories = response.data;
      this.transactionHistories.forEach(transactionHistory => {
        var newValue = JSON.parse(transactionHistory.newValue);
        var oldValue = JSON.parse(transactionHistory.oldValue);
        transactionHistory.newValueObject = newValue;
        transactionHistory.oldValueObject = oldValue;
        var keys = Object.keys(newValue);
        keys.forEach(key => {
          if (this.fields.indexOf(key) == -1) {
            this.fields.push(key);
          }
        });
        keys = Object.keys(oldValue);
        keys.forEach(key => {
          if (this.fields.indexOf(key) == -1) {
            this.fields.push(key);
          }
        });
      });
      this.loading = false;
      this.cdr.detectChanges();
    });
  }
}
