import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { EmailValidator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { SearchableSelectField } from './components/utclub-formly/formly/fields/searchable-select-field.component';

import { registerHttpOptionsExtension } from './components/utclub-formly/formly/extensions/http-options-formly.extensions';
import { registerEnumOptionsExtension } from './components/utclub-formly/formly/extensions/enum-options-formly.extensions';

import { ApiService } from './services/api.service';
import { EnumsService } from './services/enums.service';
import { PipesModule } from './pipes/pipes.module';
import { EnrollmentField } from './components/utclub-formly/formly/fields/enrollment-field.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { DatepickerFieldComponent } from './components/utclub-formly/formly/fields/datepicker-field.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule
} from '@angular/material/datepicker';
import { UtclubDynamicContentModule } from './components/utclub-dynamic-content/utclub-dynamic-content.module';
import { BigRadioField } from './components/utclub-formly/formly/fields/big-radio-field.component';
import { DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { EntityService } from './services/entity.service';
import { EditableModule } from '@ngneat/edit-in-place';
import { RepeatFieldComponent } from './components/utclub-formly/formly/fields/repeat-field.component';
import { DirectivesModule } from './directives/directives.module';
import { InputTurkishCharachtersDirective } from './directives/input-turkish-charachters.directive';
import { CurrencyFieldComponent } from './components/utclub-formly/formly/fields/currency-field.component';
import { registerAutoCompeteOffExtension } from './components/utclub-formly/formly/extensions/autocomplete-off.formly.extensions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MonthpickerFieldComponent } from './components/utclub-formly/formly/fields/monthpicker-field.component';
import { FileFieldComponent } from './components/utclub-formly/formly/fields/file-field.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormlyFieldStepper } from './components/utclub-formly/formly/fields/stepper-field.component';
import { CachedSelectFieldComponent } from './components/utclub-formly/formly/fields/cached-select-field.component';
import { SelectFieldComponent } from './components/utclub-formly/formly/fields/select-field.component';
import { EnumFieldComponent } from './components/utclub-formly/formly/fields/enum-field.component';
import { ExpansionFieldComponent } from './components/utclub-formly/formly/fields/expansion-field.component';
import { UtclubSearchInputComponent } from './components/utclub-search-input/utclub-search-input.component';
import { HttpSelectFieldComponent } from './components/utclub-formly/formly/fields/http-select-field.component';
import { CustomFieldType } from './components/utclub-formly/formly/custom-field-type';
import { CustomFormlyFieldConfig } from './components/utclub-formly/formly/custom-formly-field-config';
import {
  getAccountCaseField,
  getAvailableTransactionAccountField,
  getBranchField,
  getCityByCountryField,
  getCityField,
  getConsultantField,
  getCorporateInquiryField,
  getCountryField,
  getCurrencyField,
  getEnrollmentField,
  getEnrollmentGroupField,
  getPartnerField,
  getProgramField,
  getRoleField,
  getSchoolBranchBySchoolField,
  getSchoolBranchField,
  getSchoolField,
  getServiceProviderEducationField,
  getServiceProviderField,
  getServiceProviderOperationField,
  getStudyAbroadPointField,
  getStudyAbroadRepresentativeField,
  getTownByCityField,
  getTurkeyCityField,
  getUserField,
  getWebPortalField
} from './app.fields';
import { EmailValidatorFn, EmailValidatorMessage } from './components/utclub-formly/formly/validators/email-validation';
import { RequiredValidatorMessage } from './components/utclub-formly/formly/validators/required-validation';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, InputTurkishCharachtersDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    //Formly
    ReactiveFormsModule,
    FormlyPresetModule,
    FormlyModule.forRoot({
      validators: [{ name: 'email', validation: EmailValidatorFn }],
      validationMessages: [
        { name: 'required', message: RequiredValidatorMessage },
        { name: 'email', message: EmailValidatorMessage }
      ],
      types: [
        { name: 'enrollment-field', component: EnrollmentField },
        { name: 'searchable-select', component: SearchableSelectField },
        { name: 'cached-select', component: CachedSelectFieldComponent },
        { name: 'enum-select', component: EnumFieldComponent },
        { name: 'select-field', component: SelectFieldComponent },
        { name: 'datepicker', component: DatepickerFieldComponent },
        { name: 'monthpicker', component: MonthpickerFieldComponent },
        { name: 'big-radio', component: BigRadioField },
        { name: 'repeat', component: RepeatFieldComponent },
        { name: 'currency-field', component: CurrencyFieldComponent },
        { name: 'filepicker', component: FileFieldComponent },
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },
        { name: 'expansion', component: ExpansionFieldComponent },
        { name: 'http-field', component: HttpSelectFieldComponent }
      ],
      presets: [
        { name: 'country', config: getCountryField() },
        { name: 'city', config: getCityField() },
        { name: 'turkeyCity', config: getTurkeyCityField() },
        { name: 'cityByCountry', config: getCityByCountryField() },
        { name: 'townByCity', config: getTownByCityField() },
        { name: 'branch', config: getBranchField() },
        { name: 'school', config: getSchoolField() },
        { name: 'schoolBranchBySchool', config: getSchoolBranchBySchoolField() },
        { name: 'schoolBranch', config: getSchoolBranchField() },
        { name: 'serviceProvider', config: getServiceProviderField() },
        { name: 'serviceProviderEducation', config: getServiceProviderEducationField() },
        { name: 'serviceProviderOperation', config: getServiceProviderOperationField() },
        { name: 'currency', config: getCurrencyField() },
        { name: 'studyAbroadRepresentative', config: getStudyAbroadRepresentativeField() },
        { name: 'studyAbroadPoint', config: getStudyAbroadPointField() },
        { name: 'corporateInquiry', config: getCorporateInquiryField() },
        { name: 'webPortal', config: getWebPortalField() },
        { name: 'enrollment', config: getEnrollmentField() },
        { name: 'enrollmentGroup', config: getEnrollmentGroupField() },
        { name: 'user', config: getUserField() },
        { name: 'consultant', config: getConsultantField() },
        { name: 'partner', config: getPartnerField() },
        { name: 'role', config: getRoleField() },
        { name: 'availableTransactionAccountField', config: getAvailableTransactionAccountField() },
        { name: 'accountCase', config: getAccountCaseField() },
        { name: 'program', config: getProgramField() }
      ]
    }),
    FormlyMaterialModule,
    FormlyMatFormFieldModule,

    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTableModule,

    // Vex
    VexModule,
    CustomLayoutModule,
    MatSnackBarModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'tr'
    }),
    FormsModule,
    PipesModule,
    DirectivesModule,

    UtclubDynamicContentModule,
    MatMomentDateModule,
    EditableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerHttpOptionsExtension,
      deps: [ApiService]
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerEnumOptionsExtension,
      deps: [EnumsService]
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerAutoCompeteOffExtension
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'tr-TR' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy },
    DatePipe,
    EntityService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
