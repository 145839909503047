<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p> Bugun </p>
  <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>Online Kullanicilar</h3>
  <mat-list-item *ngFor="let user of onlineUsers">
    <mat-icon matListItemIcon class="text-green" svgIcon="mat:check_circle"></mat-icon>
    <div matListItemTitle> {{ user.name }} </div>
  </mat-list-item>
</mat-nav-list>

<!-- <div class="vex-list-item" matRipple>
  <p>CPU Load (71% / 100%)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="71" color="primary" mode="determinate"></mat-progress-bar>
  </p>
</div>

<div class="vex-list-item" matRipple>
  <p>RAM Usage (6,175 MB / 16,384 MB)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="34" color="accent" mode="determinate"></mat-progress-bar>
  </p>
</div>

<div class="vex-list-item" matRipple>
  <p>CPU Temp (43° / 80°)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="54" color="warn" mode="determinate"></mat-progress-bar>
  </p>
</div> -->