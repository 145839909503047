import { CustomFormlyFieldConfig } from './components/utclub-formly/formly/custom-formly-field-config';

export function getCountryField(): CustomFormlyFieldConfig {
  return {
    key: 'countryId',
    type: 'http-field',
    props: {
      label: 'Ülke',
      http: {
        url: 'location-service/country'
      }
    }
  };
}

export function getCityField(): CustomFormlyFieldConfig {
  return {
    key: 'cityId',
    type: 'http-field',
    props: {
      label: 'Şehir',
      http: {
        url: 'location-service/city'
      }
    }
  };
}

export function getTurkeyCityField(): CustomFormlyFieldConfig {
  return {
    key: 'cityId',
    type: 'http-field',
    props: {
      label: 'Şehir',
      http: {
        url: 'location-service/city?CountryCode=TUR'
      }
    }
  };
}

export function getCityByCountryField(): CustomFormlyFieldConfig {
  return {
    key: 'cityId',
    type: 'http-field',
    props: {
      label: 'Şehir',
      http: {
        url: 'location-service/city',
        parentProp: 'countryId',
        parentPropQuery: 'countryId'
      }
    }
  };
}

export function getTownByCityField(): CustomFormlyFieldConfig {
  return {
    key: 'townId',
    type: 'http-field',
    props: {
      label: 'İlçe',
      http: {
        url: 'location-service/town',
        parentProp: 'cityId',
        parentPropQuery: 'CityId'
      }
    }
  };
}

export function getEnrollmentGroupField(): CustomFormlyFieldConfig {
  return {
    key: 'enrollmentGroupId',
    type: 'http-field',
    props: {
      label: 'Enrollment Group',
      http: {
        url: 'student-service/enrollment-group'
      }
    }
  };
}

export function getServiceProviderField(): CustomFormlyFieldConfig {
  return {
    key: 'serviceProviderId',
    type: 'http-field',
    props: {
      label: 'Servis Sağlayıcı',
      http: {
        url: 'accounting-service/serviceprovider'
      }
    }
  };
}

export function getServiceProviderEducationField(): CustomFormlyFieldConfig {
  return {
    key: 'serviceProviderId',
    type: 'http-field',
    props: {
      label: 'Servis Sağlayıcı',
      http: {
        url: 'accounting-service/serviceprovider?serviceProviderType=1'
      }
    }
  };
}

export function getServiceProviderOperationField(): CustomFormlyFieldConfig {
  return {
    key: 'serviceProviderId',
    type: 'http-field',
    props: {
      label: 'Servis Sağlayıcı',
      http: {
        url: 'accounting-service/serviceprovider?serviceProviderType=2'
      }
    }
  };
}

export function getSchoolField(): CustomFormlyFieldConfig {
  return {
    key: 'schoolId',
    type: 'http-field',
    props: {
      label: 'Okul',
      http: {
        url: 'student-service/school'
      }
    }
  };
}

export function getSchoolBranchBySchoolField(): CustomFormlyFieldConfig {
  return {
    key: 'schoolBranchId',
    type: 'http-field',
    props: {
      label: 'Okul Şubesi',
      http: {
        url: 'student-service/school-branch',
        parentProp: 'schoolId',
        parentPropQuery: 'schoolId'
      }
    }
  };
}

export function getSchoolBranchField(): CustomFormlyFieldConfig {
  return {
    key: 'schoolBranchId',
    type: 'http-field',
    props: {
      label: 'Okul Şubesi',
      http: {
        url: 'student-service/school-branch'
      }
    }
  };
}

export function getCurrencyField(): CustomFormlyFieldConfig {
  return {
    key: 'currencyId',
    type: 'http-field',
    props: {
      label: 'Para Birimi',
      http: {
        url: 'accounting-service/currency'
      }
    }
  };
}

export function getBranchField(): CustomFormlyFieldConfig {
  return {
    key: 'branchId',
    type: 'http-field',
    props: {
      label: 'Şube',
      http: {
        url: 'location-service/branch'
      }
    }
  };
}

export function getEnrollmentField(): CustomFormlyFieldConfig {
  return {
    key: 'enrollmentId',
    type: 'enrollment-field',
    props: {
      label: 'Enrollment ID',
      required: true
    }
  };
}

export function getStudyAbroadPointField(): CustomFormlyFieldConfig {
  return {
    key: 'studyAbroadPointId',
    type: 'http-field',
    props: {
      label: 'Yurtdisi Egitim Noktasi',
      http: {
        url: 'accounting-service/studyabroad/point'
      }
    }
  };
}

export function getStudyAbroadRepresentativeField(): CustomFormlyFieldConfig {
  return {
    key: 'studyAbroadRepresentativeId',
    type: 'http-field',
    props: {
      label: 'Yurtdisi Egitim Temsilcisi',
      http: {
        url: 'accounting-service/studyabroad/representative'
      }
    }
  };
}

export function getCorporateInquiryField(): CustomFormlyFieldConfig {
  return {
    key: 'corporateInquiryId',
    type: 'http-field',
    props: {
      label: 'Kurumsal Tanitim Formu',
      labelProp: 'companyName',
      http: {
        url: 'student-service/corporate-inquiry'
      }
    }
  };
}

export function getWebPortalField(): CustomFormlyFieldConfig {
  return {
    key: 'webPortalId',
    type: 'http-field',
    props: {
      label: 'Web Portal',
      http: {
        url: 'location-service/web-portal'
      }
    }
  };
}

export function getUserField(): CustomFormlyFieldConfig {
  return {
    key: 'userId',
    type: 'http-field',
    props: {
      label: 'Kullanıcı',
      labelProp: 'fullName',
      http: {
        url: 'auth-service/user'
      }
    }
  };
}

export function getConsultantField(): CustomFormlyFieldConfig {
  return {
    key: 'userId',
    type: 'http-field',
    props: {
      label: 'Danışman',
      labelProp: 'fullName',
      http: {
        url: 'auth-service/user?roleName=Consultant'
      }
    }
  };
}

export function getPartnerField(): CustomFormlyFieldConfig {
  return {
    key: 'userId',
    type: 'http-field',
    props: {
      label: 'Partner',
      labelProp: 'fullName',
      http: {
        url: 'auth-service/user?roleName=Partner'
      }
    }
  };
}

export function getRoleField(): CustomFormlyFieldConfig {
  return {
    key: 'roleId',
    type: 'http-field',
    props: {
      label: 'Rol',
      valueProp: 'name',
      labelProp: 'displayName',
      http: {
        url: 'auth-service/role'
      }
    }
  };
}

export function getAvailableTransactionAccountField(): CustomFormlyFieldConfig {
  return {
    key: 'transactionAccountId',
    type: 'http-field',
    props: {
      label: 'Hesap',
      http: {
        url: 'accounting-service/account/available-transaction-accounts'
      }
    }
  };
}

export function getAccountCaseField(): CustomFormlyFieldConfig {
  return {
    key: 'accountCaseId',
    type: 'http-field',
    props: {
      label: 'Kasa',
      http: {
        url: 'accounting-service/accountcase'
      }
    }
  };
}

export function getProgramField(): CustomFormlyFieldConfig {
  return {
    key: 'programId',
    type: 'http-field',
    props: {
      label: 'Program',
      http: {
        url: 'student-service/program'
      }
    }
  };
}
