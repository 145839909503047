import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { TableColumn, UtclubTableColumn } from 'src/@vex/interfaces/table-column.interface';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { UtclubTabInterface } from 'src/app/components/utclub-tab/utclub-tab.component';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { UtclubConfirmDialogService } from 'src/app/components/utclub-confirm-dialog/utclub-confirm-dialog.service';
import { InvoiceDto } from 'src/app/api/models/invoiceDto';
import { Enums } from 'src/app/services/enums';

@Component({
  selector: 'utclub-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  animations: [fadeInUp400ms, stagger40ms]
})
export class InvoiceComponent {
  actions: UtclubButtonInterface[] = [
    {
      label: 'Yeni Ekle',
      color: 'primary',
      click: () => {
        var tabInterface: UtclubTabInterface = {
          label: 'Yeni Fatura',
          component: InvoiceFormComponent,
          id: 'new-invoice',
          singleTab: true
        };
        this.eventBusService.emit(new EventData('tab-added', tabInterface));
      },
      buttonType: 'button'
    }
  ];
  rowActions: UtclubButtonInterface[] = [
    {
      label: 'Duzenle',
      color: 'primary',
      click: (row) => {
        var tabInterface: UtclubTabInterface = {
          label: row.number + ' - ' + row.name,
          component: InvoiceFormComponent,
          data: {
            id: row.id
          },
          id: 'invoice-' + row.id,
          singleTab: true
        };
        this.eventBusService.emit(new EventData('tab-added', tabInterface));
      },
      buttonType: 'menu-item'
    },
    {
      label: 'Sil',
      color: 'warn',
      click: (row) => {
        this.deleteService.deleteConfirmDialog('accounting-service/invoice/' + row.id, (entity) => {
          this.loadData();
        });
      },
      buttonType: 'menu-item'
    }
  ];

  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loading: boolean = false;
  columns: UtclubTableColumn[] = [
    { property: 'number', visible: true, label: 'Numara', columnType: 'text' },
    { property: 'name', visible: true, label: 'Isim', columnType: 'text' },
    { property: 'date', visible: true, label: 'Tarih', columnType: 'custom' },
    { property: 'invoiceStatus', visible: true, label: 'Fatura Durumu', columnType: 'custom' },
    { property: 'total', visible: true, label: 'Toplam', columnType: 'custom' },
    { property: 'actions', visible: true, label: '', columnType: 'actions' }
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  totalItemCount: number = 0;
  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private eventBusService: EventBusService,
    private deleteService: UtclubConfirmDialogService
  ) {}

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnInit(): void {}

  loadData() {
    this.loading = true;
    this.apiService
      .post<PagedResponse<InvoiceDto>>('accounting-service/invoice/filter', {
        ...this.filterModel,
        page: this.paginator.pageIndex ?? this.pageIndex,
        pageSize: this.paginator.pageSize ?? this.pageSize
      })
      .subscribe((response) => {
        response.data.forEach((item) => {
          item.total = 0;
          item.items.forEach((invoiceItem) => {
            item.total += invoiceItem.price * invoiceItem.quantity;
          });
          item.total = parseFloat(item.total.toFixed(2));
        });
        this.totalItemCount = response.itemsCount;
        this.paginator.length = response.itemsCount;
        this.dataSource = new MatTableDataSource(response.data);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  rowClicked(row) {
    var tabInterface: UtclubTabInterface = {
      label: row.number + ' - ' + row.name,
      component: InvoiceFormComponent,
      data: {
        id: row.id
      },
      id: 'invoice-' + row.id,
      singleTab: true
    };
    this.eventBusService.emit(new EventData('tab-added', tabInterface));
  }
  sortChanged(event) {
    console.log('sortChanged', event);
  }
  pageChanged(event) {
    this.loadData();
  }

  filterOpened: boolean = false;
  filterModel: any = {};
  filterForm = new FormGroup({});
  filterFields: CustomFormlyFieldConfig[] = null;
  filterToggle() {
    if (!this.filterFields) {
      this.filterFields = [
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'input',
              key: 'number',
              props: {
                label: 'Numara'
              }
            },
            {
              type: 'input',
              key: 'search',
              props: {
                label: 'Ara'
              }
            },
            {
              key: 'invoiceStatus',
              type: 'enum-select',
              props: {
                label: 'Fatura Durumu',
                enum: Enums.InvoiceStatus
              }
            }
          ]
        }
      ];
    }
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    this.loadData();
  }
  trackByProperty<T>(index: number, column: UtclubTableColumn) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns.filter((column) => column.visible).map((column) => column.property);
  }
}
