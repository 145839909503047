import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-stepper',
  template: `
    <mat-stepper #stepper [linear]="true">
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last">
        <ng-template matStepLabel>{{ step.props.label }}</ng-template>
        {{ step | json  }}
        <formly-field [field]="step"></formly-field>

        <div>
          <button *ngIf="index !== 0" mat-button matStepperPrevious>Back</button>

          <button *ngIf="!last" mat-button matStepperNext [attr.disabled]="!isValid(step)">Next</button>

          <button *ngIf="last" class="btn btn-primary" [attr.disabled]="!form.valid" type="submit">Submit</button>
        </div>
      </mat-step>



      <!-- <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Fill out your name</ng-template>
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
          </mat-form-field>
          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
        <form [formGroup]="secondFormGroup">
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done.</p>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step> -->
    </mat-stepper>




    <!-- <mat-stepper>
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last">
        <ng-template matStepLabel>{{ step.props.label }}</ng-template>
        <formly-field [field]="step"></formly-field>

        <div>
          <button *ngIf="index !== 0" mat-button matStepperPrevious>Back</button>

          <button *ngIf="!last" mat-button matStepperNext [attr.disabled]="!isValid(step)">Next</button>

          <button *ngIf="last" class="btn btn-primary" [attr.disabled]="!form.valid" type="submit">Submit</button>
        </div>
      </mat-step>
    </mat-stepper> -->

    <!-- <mat-stepper [linear]="true" #stepper>
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last">
        <ng-template matStepLabel>{{ step.props.label }}</ng-template>
        <formly-field [field]="step"></formly-field>

        <div>
          <button matStepperPrevious *ngIf="index !== 0" class="btn btn-primary" type="button">Back</button>

          <button matStepperNext *ngIf="!last" class="btn btn-primary" type="button" [attr.disabled]="!isValid(step)">
            Next
          </button>

          <button *ngIf="last" class="btn btn-primary" [attr.disabled]="!form.valid" type="submit">Submit</button>
        </div>
      </mat-step>
    </mat-stepper> -->
  `,
})
export class FormlyFieldStepper extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }
}