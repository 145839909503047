import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Response, PagedResponse } from '../../../../models/response.model';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { MatDialog } from '@angular/material/dialog';
import { UtclubSelectEnrollmentComponent } from 'src/app/components/utclub-select-enrollment/utclub-select-enrollment.component';
import { Enrollment } from 'src/app/models/enrollment.model';

@Component({
  selector: 'enrollment-field',
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>
      <input
        matInput
        [formControl]="control"
        [formlyAttributes]="field"
        [required]="field.props.required"
        placeholder="Oğrenci No." />
      <mat-icon matSuffix svgIcon="mat:search" (click)="searchEnrollment()" class="cursor-pointer"></mat-icon>
    </mat-form-field>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

    <div class="pb-6 flex items-center" *ngIf="enrollment">
      <div
        @scaleIn
        class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
        <mat-icon class="icon-sm" svgIcon="mat:person"></mat-icon>
      </div>

      <div @fadeInRight>
        <p class="m-0 body-1">
          {{ enrollment.student.firstName }} {{ enrollment.student.lastName }} #{{ enrollment.id }}
        </p>
        <p class="m-0 caption text-hint">Öğrenci</p>
      </div>

      <div
        @scaleIn
        (click)="removeStudent()"
        class="w-10 h-10 rounded-full bg-red/10 text-red ml-auto flex items-center justify-center">
        <mat-icon class="icon-sm" svgIcon="mat:close"></mat-icon>
      </div>
    </div>

    <div class="pb-6 flex items-center" *ngIf="errorMsg">
      <div
        @scaleIn
        class="w-10 h-10 rounded-full bg-red/10 text-red ltr:mr-3 rtl:ml-3 flex items-center justify-center">
        <mat-icon class="icon-sm" svgIcon="mat:person_off"></mat-icon>
      </div>

      <div @fadeInRight>
        <p class="m-0 body-1 text-red">{{ errorMsg }}</p>
      </div>
    </div>
  `,
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms]
})
export class EnrollmentField extends FieldType<CustomFormlyFieldConfig> implements OnInit {
  loading = false;
  loaded = false;
  enrollment: Enrollment;
  errorMsg;

  constructor(
    private apiSerice: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {
    super();
  }

  timeout: any;
  ngOnInit(): void {
    this.field.props.keydown = (field, event) => {
      this.loaded = false;
      clearTimeout(this.timeout);
      if (event.key === 'Enter') {
        this.loadEnrollment();
      }
      this.timeout = setTimeout(() => {
        this.loadEnrollment();
      }, 1000);
    };
    this.field.props.blur = (field, event) => {
      this.loadEnrollment();
    };
  }
  loadEnrollment() {
    if (!this.control.value) return;
    if (this.loaded) return;
    this.loading = true;
    this.errorMsg = null;
    this.enrollment = null;
    this.apiSerice.get<Response<Enrollment>>(`student-service/enrollment/${this.control.value}?controlPermissions=false`).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.setEnrollment(response.data);
        }
        this.loading = false;
        this.loaded = true;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.errorMsg = error.error.message;
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
  }

  setEnrollment(enrollment: Enrollment) {
    this.eventBusService.emit(new EventData('enrollment-loaded', enrollment));
    this.enrollment = enrollment;
    this.control.setValue(enrollment.id);
  }

  searchEnrollment() {
    this.matDialog
      .open(UtclubSelectEnrollmentComponent, {
        width: '600px'
      })
      .afterClosed()
      .subscribe((enrollment: Enrollment) => {
        if (enrollment) {
          this.setEnrollment(enrollment);
        }
      });
  }

  removeStudent() {
    this.enrollment = null;
  }

  get control() {
    return this.formControl as FormControl;
  }
}
