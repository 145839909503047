import { AfterContentInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AccountDto } from 'src/app/api/models/accountDto';
import { BranchDto } from 'src/app/api/models/branchDto';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { AuthService } from 'src/app/services/auth.service';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-transfer-form',
  template: `
    <utclub-modal title="Transfer">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"></utclub-form>
    </utclub-modal>
  `
})
export class TransferFormComponent implements AfterContentInit {
  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  accounts: AccountDto[];

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Tarih',
        placeholder: '',
        required: false
      },
      expressions: {
        hide: (field: CustomFormlyFieldConfig) => {
          return !this.authService.isInRole('Manager');
        }
      }
    },
    {
      key: 'relatedBranchId',
      type: '#branch',
      props: {
        required: true
      }
    },
    {
      key: 'fromAccountId',
      type: '#availableTransactionAccountField',
      props: {
        label: 'Su hesaptan*',
        required: true
      }
    },
    {
      key: 'toAccountId',
      type: 'cached-select',
      props: {
        label: 'Su hesaba*',
        required: true,
        observable: (fromAccountId) => {
          var fromAccount = this.accounts.find((x) => x.id == fromAccountId);
          if (!fromAccount) return [];
          var currencyId = fromAccount.currencyId;
          return this.lookup.getAccountsByCurrencyId$(currencyId);
        },
        observableProp: 'fromAccountId'
      },
      expressions: {
        'props.disabled': '!model.fromAccountId'
      }
    },
    {
      key: 'amount',
      type: 'input',
      props: {
        label: 'Tutar',
        placeholder: '',
        required: true
      }
    },
    {
      key: 'note',
      type: 'textarea',
      props: {
        label: 'Not',
        placeholder: '',
        required: false,
        rows: 3
      }
    }
  ];
  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<TransferFormComponent>,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private lookup: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }
  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: this.fields,
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/transfer' : 'accounting-service/transfer/' + id,
      loadDataUrl: !id ? null : 'accounting-service/transfer/' + id
    };

    this.lookup.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    });
  }
}
