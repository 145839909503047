export class Enums {
  static AccountType = {
    key: 'AccountType',
    values: {
      BranchAccount: 1,
      BranchManagerAccount: 2,
      BankAccount: 3,
      OldAccount: 4
    }
  };

  static CreditType = {
    key: 'CreditType',
    values: {
      ServiceProviderEducation: 1,
      ServiceProviderOperation: 2,
      School: 3,
      ProgramFee: 4
    }
  };

  static CreditTypeServiceProviderOperation = {
    key: 'CreditTypeServiceProviderOperation',
    values: {
      Translation: 1,
      FlightTicket: 2,
      Accommodation: 3,
      HealthInsurance: 4
    }
  };

  static DebitType = {
    key: 'DebitType',
    values: {
      Student: 1,
      ServiceProvider: 3,
      BranchToBranch: 4,
      School: 5,
      MinistryProvider: 6
    }
  };

  static DebitTypeEnrollment = {
    key: 'DebitTypeEnrollment',
    values: {
      Commission: 1,
      ProgramFee: 2
    }
  };

  static DebitTypeStaff = {
    key: 'DebitTypeStaff',
    values: {
      SalaryExpense: 1,
      BonusExpense: 2,
      RewardExpense: 3,
      ExtraExpenses: 4,
      AdvanceExpense: 5
    }
  };

  static DebitStatus = {
    key: 'DebitStatus',
    values: {
      Pending: 0,
      Completed: 1
    }
  };

  static DebitTypeMinistryProvider = {
    key: 'DebitTypeMinistryProvider',
    values: {
      NotSet: 0,
      SchoolCommission: 1,
      HealthInsurance: 2,
      Translation: 3,
      Accommodation: 4,
      FlightTicket: 5
    }
  };

  static InvoiceStatus = {
    key: 'InvoiceStatus',
    values: {
      Waiting: 0,
      Paid: 1,
      Draft: 2,
      Bankruptcy: 3
    }
  };

  static MinistryProviderType = {
    key: 'MinistryProviderType',
    values: {
      FlightTicket: 1,
      Accommodation: 2,
      Insurance: 3,
      Broker: 4,
      TranslationService: 5
    }
  };

  static PaymentType = {
    key: 'PaymentType',
    values: {
      Education: 1,
      Operation: 2,
      Staff: 3,
      YEN: 4,
      Partnership: 5
    }
  };

  static PaymentTypeEducationDepartment = {
    key: 'PaymentTypeEducationDepartment',
    values: {
      WorkAndTravel: 1,
      LanguageSchool: 2,
      University: 3,
      Internship: 4,
      SummerSchoolGroup: 5,
      SummerSchoolIndividual: 6,
      Diploma: 7,
      HighSchool: 8,
      Master: 9,
      Pathways: 10
    }
  };

  static PaymentTypeEducationPaymentUnit = {
    key: 'PaymentTypeEducationPaymentUnit',
    values: {
      ProgramFeeRefund: 1,
      DepositFeeRefund: 2,
      Discount: 3,
      Punishment: 4,
      ServiceProvider: 5,
      Sponsor: 6,
      PinVisaAndServiceExpenses: 7,
      FlightTicketExpenses: 8,
      InsuranceExpenses: 9,
      RepresentativeExpenses: 10,
      TranslationAndTranslationExpenses: 11,
      SummerSchoolOrganizationExpenses: 12,
      ReturnFromServiceProvider: 13
    }
  };

  static PaymentTypeOperationPaymentUnit = {
    key: 'PaymentTypeOperationPaymentUnit',
    values: {
      BuildingRentAndDuesExpenses: 1,
      AssetExpenses: 2,
      KitchenAndCleaningExpenses: 3,
      Foodexpenses: 4,
      StationeryExpenses: 5,
      MaintenanceRepairExpenses: 6,
      BankProvisionExpenses: 7,
      DonationsandAids: 8,
      PrintingExpenses: 9,
      AdvertisingPromotionAndEssentryExpenses: 10,
      InformationProcessingExpenses: 11,
      TransportationExpenses: 12,
      InsuranceAndSecurityExpenses: 13,
      GeneralOrganizationExpenses: 14,
      FairStandSeminarExpenses: 15,
      InternetPromotionExpenses: 16,
      CommunicationAndTelephoneExpenses: 17,
      WaterExpenses: 18,
      DomesticCargoExpenses: 21,
      InternationalCargoExpenses: 22,
      VATDeclarationPayments: 23,
      Corporationtax: 24,
      ConciseDeclarationPayments: 25,
      ConsultancyExpenses: 26,
      BagkurPremiums: 27,
      SSKPremiumPayments: 28,
      AssociationMembershipAndDues: 29,
      OtherTaxAndFeePayments: 30,
      UtClubExpenses: 31,
      TranslationAndTranslationExpenses: 32,
      PinVisaAndServiceExpenses: 33,
      OtherMiscellaneousExpenses: 34,
      UnallocatedExpenses: 35,
      FlightTicketExpenses: 36,
      VehicleExpenses: 37,
      RelicsGiven: 38,
      InternationalFairsAndFamTripExpenses: 39,
      HeadOfficePayments: 40,
      GznHayri: 41,
      ReturnFromServiceProvider: 42,
      CorporatePromotionExpenses: 43,
      CorporateTrainingActivityExpenses: 44,
      LawyerExpenses: 45,
      StaffMotivationExpenses: 46,
      ConsumerArbitrationCommittee: 47
    }
  };

  static PaymentTypeStaffPaymentUnit = {
    key: 'PaymentTypeStaffPaymentUnit',
    values: {
      SalaryExpense: 1,
      BonusExpense: 2,
      RewardExpense: 3,
      ExtraExpenses: 4,
      AdvanceExpense: 5
    }
  };

  static PaymentTypeYENDepartment = {
    key: 'PaymentTypeYENDepartment',
    values: {
      StudyAbroadRepresentative: 1,
      StudyAbroadPoint: 2
    }
  };

  static PaymentTypePartnership = {
    key: 'PaymentTypePartnership',
    values: {
      MehmetTaslitepe: 1,
      NizamettinTursun: 2,
      KemalTatar: 3,
      OnderOztutuncu: 4,
      MustafaGencoglu: 5
    }
  };

  static ReceiptType = {
    key: 'ReceiptType',
    values: {
      Education: 1,
      Operation: 2
    }
  };

  static ReceiptTypeEducationDepartments = {
    key: 'ReceiptTypeEducationDepartments',
    values: {
      WorkAndTravelRevenues: 1,
      LanguageEducationRevenues: 2,
      UniversityRevenues: 3,
      InternshipRevenues: 4,
      SummerSchoolGroupRevenues: 5,
      SummerSchoolIndividualRevenues: 6,
      CertificateDiplomaRevenues: 7,
      HighSchoolRevenues: 8,
      MasterRevenues: 9,
      PathwaysRevenues: 10
    }
  };

  static ReceiptTypeEducationReceiptUnits = {
    key: 'ReceiptTypeEducationReceiptUnits',
    values: {
      ProgramFee: 1,
      DepositFee: 2,
      SchoolCommission: 3,
      ServiceProviderCommission: 11,
      FlightTicketRevenues: 4,
      PinServiceVisaFeeRevenues: 5,
      TranslationRevenues: 6,
      BonusIncome: 7,
      UsTaxRevenues: 8,
      ConsultingFees: 9,
      ReturnFromServiceProvider: 10,
      Accommodation: 12
    }
  };

  static ReceiptTypeOperationReceiptUnits = {
    key: 'ReceiptTypeOperationReceiptUnits',
    values: {
      UsTaxRevenues: 1,
      InsuranceRevenues: 2,
      PhoneCardRevenues: 3,
      AdvertisingRevenues: 4,
      BonusIncome: 5,
      OverseasRevenues: 6,
      UTClubRevenues: 7,
      FairRevenues: 8,
      FlightTicketRevenues: 9,
      OtherMiscellaneousIncomes: 10,
      UndistributedRevenues: 11,
      EmergencyCashierRevenues: 12,
      ReceivedRelics: 13,
      OnlineEnglish: 14,
      GaziantepBranchRevenue: 15,
      AnkaraBranchRevenue: 16,
      IzmirBranchRevenue: 17,
      VisaConsultancy: 18,
      GznHayri: 19,
      ReturnFromServiceProvider: 20,
      MinistryProvider: 21,
      Accommodation: 22
    }
  };

  static TransactionType = {
    key: 'TransactionType',
    values: {
      Receipt: 1,
      Payment: 2,
      Exchange: 3,
      Transfer: 4
    }
  };

  static AccommodationType = {
    key: 'AccommodationType',
    values: {
      Family: 1,
      Campus: 2
    }
  };

  static AirportWelcomingType = {
    key: 'AirportWelcomingType',
    values: {
      No: 1,
      OneWay: 2,
      TwoWay: 3
    }
  };

  static CompanyContactType = {
    key: 'CompanyContactType',
    values: {
      ContactPerson: 1,
      PremiumResponsible: 2
    }
  };

  static CompanyType = {
    key: 'CompanyType',
    values: {
      CramSchool: 1,
      TrainingCoach: 2,
      LanguageSchool: 3,
      Company: 4,
      PrimaryEducation: 5,
      HighSchool: 6,
      University: 7
    }
  };

  static CorporateInquiryLogType = {
    key: 'CorporateInquiryLogType',
    values: {
      None: 0,
      Created: 1,
      FollowUpUpdate: 2,
      CorporateInformationUpdate: 3,
      ContactAdded: 4,
      RecordAdded: 5
    }
  };

  static CorporateInquiryRecordType = {
    key: 'CorporateInquiryRecordType',
    values: {
      NotContacted: 1,
      ContactedNotResponse: 2,
      ContactedCantAgreed: 3,
      AgreedCantGrouped: 4,
      AgreedGrouped: 5,
      ContactedWillRedirect: 6
    }
  };

  static DiscountType = {
    key: 'DiscountType',
    values: {
      Fixed: 1,
      Rate: 2,
      Manual: 3
    }
  };

  static EducationLevel = {
    key: 'EducationLevel',
    values: {
      Unspecified: 0,
      SecondarySchoolStudent: 1,
      SecondarySchoolGraduate: 2,
      HighSchoolStudent: 3,
      HighSchoolGraduate: 4,
      UniversityStudent: 5,
      UniversityGraduate: 6,
      MasterStudent: 7,
      MasterGraduate: 8,
      AssociateStudent: 9,
      AssociateGraduate: 10
    }
  };

  static EnrollmentAcademicPlacementChannel = {
    key: 'EnrollmentAcademicPlacementChannel',
    values: {
      WithServiceProvider: 1,
      DirectlySchool: 2,
      WithMinistryProvider: 3,
      WithUt: 4
    }
  };

  static EnrollmentApplicationStatus = {
    key: 'EnrollmentApplicationStatus',
    values: {}
  };

  static EnrollmentTaskDebitType = {
    key: 'EnrollmentTaskDebitType',
    values: {
      Yes: 1,
      NoNeed: 2
    }
  };

  static EnrollmentTaskCreditType = {
    key: 'EnrollmentTaskCreditType',
    values: {
      Yes: 1,
      NoNeed: 2
    }
  };

  static EnrollmentFileType = {
    key: 'EnrollmentFileType',
    values: {
      Passport: 10,
      AgreementForm: 20,
      RegistrationForm: 21,
      NetInvoice: 30,
      GrossInvoice: 31,
      AccommodationReceipt: 40,
      AirportMeetingReceipt: 41,
      HealthInsuranceReceipt: 42,
      SchoolCommissionReceipt: 43,
      FlightTicketReceipt: 44,
      TranslationReceipt: 45,
      ServiceProviderDebit: 46,
      MinistryProviderDebit: 47,
      Other: 100
    }
  };

  static EnrollmentInternshipRequestedService = {
    key: 'EnrollmentInternshipRequestedService',
    values: {
      JobPlacement: 1,
      Ds160: 2,
      ServiceProviderInterview: 3,
      EmployerInterview: 4
    }
  };

  static EnrollmentJobPlacementType = {
    key: 'EnrollmentJobPlacementType',
    values: {
      ServiceProvider: 1,
      Self: 2
    }
  };

  static EnrollmentPlacementApplicationStatus = {
    key: 'EnrollmentPlacementApplicationStatus',
    values: {
      Pending: 1,
      Approved: 2,
      Cancelled: 3,
      Rejected: 4
    }
  };

  static EnrollmentPlacementApplicationType = {
    key: 'EnrollmentPlacementApplicationType',
    values: {
      School: 1,
      Internship: 2
    }
  };

  static EnrollmentPremiumOwnerType = {
    key: 'EnrollmentPremiumOwnerType',
    values: {
      Consultant: 11,
      Branch: 21,
      StudyAbroadRepresentative: 31,
      StudyAbroadPoint: 32,
      CompanyContact: 34,
      WebPortal: 35
    }
  };

  static EnrollmentStatus = {
    key: 'EnrollmentStatus',
    values: {
      Application: 1,
      Travel: 2,
      OnProgram: 3,
      Completed: 4,
      Canceled: 5,
      VisaRejected: 6,
      OnHold: 7,
      VisaApplication: 8,
      Orientation: 9,
      Placement: 10
    }
  };

  static EnrollmentTaskStage = {
    key: 'EnrollmentTaskStage',
    values: {
      Record: 1,
      Travel: 2,
      Accounting: 3,
      OnProgram: 4,
      Review: 5,
      Finish: 6
    }
  };

  static EnrollmentTaskStatus = {
    key: 'EnrollmentTaskStatus',
    values: {
      Pending: 0,
      Todo: 1,
      Completed: 2
    }
  };

  static VisaResult = {
    key: 'VisaResult',
    values: {
      NoNeedToApply: 1,
      Approved: 2,
      Rejected: 3,
      ReApplication: 4
    }
  };

  static FeePriceType = {
    key: 'FeePriceType',
    values: {
      Accommodation: 1,
      Program: 2,
      HealthInsurance: 3,
      MaterialPrice: 4
    }
  };

  static FeePricingType = {
    key: 'FeePricingType',
    values: {
      Weekly: 1,
      Fixed: 2
    }
  };

  static Gender = {
    key: 'Gender',
    values: {
      Male: 1,
      Female: 2
    }
  };

  static InquiryCancelledType = {
    key: 'InquiryCancelledType',
    values: {
      NoUtProgramsMatched: 1,
      UsedAnotherAgency: 2,
      NotInterestAtAll: 3,
      UsedOwnSources: 4,
      Duplicate: 5,
      ChangeOfPlans: 6,
      NoResponse: 7
    }
  };

  static DurationType = {
    key: 'DurationType',
    values: {
      InquiryFirstAction: 1,
      InquiryToEnrollment: 2,
      InquiryCancellation: 3,
      EnrollmentCancellation: 4
    }
  };

  static InquiryRating = {
    key: 'InquiryRating',
    values: {
      Unspecified: 0,
      Low: 1,
      Medium: 2,
      High: 3,
      None: 4
    }
  };

  static CorporateRating = {
    key: 'CorporateRating',
    values: {
      Low: 1,
      Medium: 2,
      High: 3
    }
  };

  static InquiryFollowUpStatus = {
    key: 'InquiryFollowUpStatus',
    values: {
      Active: 1,
      Cancelled: 2
    }
  };

  static InquiryStatus = {
    key: 'InquiryStatus',
    values: {
      Pending: 1,
      Enrolled: 3,
      Cancelled: 4
    }
  };

  static LanguageLevel = {
    key: 'LanguageLevel',
    values: {}
  };

  static PartnerPackageType = {
    key: 'PartnerPackageType',
    values: {
      Silver: 1,
      Gold: 2,
      Platinum: 3,
      Individual: 4
    }
  };

  static PremiumPreferenceType = {
    key: 'PremiumPreferenceType',
    values: {
      Consultant: 11,
      Branch: 21,
      PartnerPackage: 41
    }
  };

  static PremiumType = {
    key: 'PremiumType',
    values: {
      Percentage: 1,
      Fixed: 2,
      FixedForSchoolType: 3
    }
  };

  static ProgramConsultantLevel = {
    key: 'ProgramConsultantLevel',
    values: {
      Consultant: 1,
      Manager: 2,
      Supervisor: 3,
      Support: 4
    }
  };

  static ProgramType = {
    key: 'ProgramType',
    values: {
      LanguageSchool: 1,
      SummerSchool: 2,
      Internship: 3,
      University: 4,
      WorkAndTravel: 5,
      Diploma: 6,
      HighSchool: 7,
      Master: 8,
      SummerSchoolGroup: 9,
      Pathways: 10
    }
  };

  static SchoolPremiumType = {
    key: 'SchoolPremiumType',
    values: {
      A: 1,
      B: 2,
      C: 3
    }
  };

  static StudentLogType = {
    key: 'StudentLogType',
    values: {
      StudentInformationUpdate: 1,
      StudentPhotoUpdate: 2,
      InquiryCreated: 1001,
      InquiryFollowUpCreated: 1002,
      InquiryCancelled: 1003,
      InquiryUtInformationUpdate: 1004,
      InquiryProgramInformationUpdate: 1006,
      InquiryImported: 1007,
      InquiryOfferCreated: 1008,
      InquiryEnrolled: 1009,
      InquiryUserChange: 1010,
      EnrollmentCreated: 2001,
      EnrollmentCancelled: 2003,
      EnrollmentUtInformationUpdate: 2004,
      EnrollmentAdvisorsUpdate: 2005,
      EnrollmentProgramInformationUpdate: 2006,
      EnrollmentDebitAmountsUpdate: 2008,
      EnrollmentProgramLocationUpdate: 2009,
      EnrollmentVisaApplicationDateUpdate: 2010,
      EnrollmentVisaResultUpdate: 2011,
      EnrollmentFlightDateUpdate: 2012,
      EnrollmentDateInformationUpdate: 2013,
      EnrollmentSchoolInformationUpdate: 2014,
      EnrollmentUpdateLocationInformation: 2015,
      EnrollmentLanguageSchoolInformationUpdate: 2016,
      EnrollmentSummerSchoolInformationUpdate: 2017,
      EnrollmentAcademicSchoolInformationUpdate: 2018,
      EnrollmentInternshipInformationUpdate: 2019,
      EnrollmentUpdateLanguageSchoolInvoiceInformation: 2020,
      EnrollmentUpdateSummerSchoolInvoiceInformation: 2021,
      EnrollmentUpdateExtraInformation: 2022,
      EnrollmentTaskUpdate: 2101,
      EnrollmentTaskCompleted: 2102
    }
  };

  static StudentSource = {
    key: 'StudentSource',
    values: {
      Internet: 1,
      UtClub: 2,
      StudyAbroadPoint: 3,
      FuarStand: 4,
      Standard: 5,
      Referans: 6,
      School: 7,
      SosyalMedya: 8,
      WebPortal: 9,
      CorporateInquiry: 10,
      StudyAbroadRepresentative: 11
    }
  };

  static SubProgramType = {
    key: 'SubProgramType',
    values: {
      IntensiveProgram: 1,
      SemiIntensiveProgram: 2,
      AcademicYearProgram: 3,
      IntensiveBusinessEnglish: 4,
      IntensiveProgramEvening: 5,
      SemiIntensiveProgramEvening: 6,
      VacationCourse: 7,
      SummerSchoolGroup: 8,
      ExecutiveProgram: 9,
      MedicalEnglish: 10
    }
  };

  static SummerSchoolIndividualPricingType = {
    key: 'SummerSchoolIndividualPricingType',
    values: {
      Normal: 1,
      Package: 2
    }
  };

  static SummerSchoolType = {
    key: 'SummerSchoolType',
    values: {
      Individual: 1,
      Group: 2
    }
  };

  static VisaStatus = {
    key: 'VisaStatus',
    values: {
      Unknown: 0,
      Yes: 1,
      No: 2,
      Pending: 3,
      NotRequired: 4,
      Cancelled: 5,
      Placed: 7,
      Extension: 8,
      Application: 9,
      ReApplication: 10
    }
  };

  static UserStatus = {
    key: 'UserStatus',
    values: {
      Active: 1,
      Inactive: 2
    }
  };

  static BranchType = {
    key: 'BranchType',
    values: {
      MainBranch: 1,
      Branch: 2,
      Franchise: 3
    }
  };

  static ServiceProviderType = {
    key: 'ServiceProviderType',
    values: {
      Education: 1,
      Operation: 2
    }
  };
}
