import { ChangeDetectorRef, Component, ContentChildren, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

export interface EnrollmentCreditBalanceDto {
  enrollmentId: number;
  enrollmentName: string;
  schoolCountryId: number | null;
  visaStatus: number;
  programStartDate: string | null;
  receipt: number;
  credit: number;
  externalId?: string;
}

@Component({
  selector: 'utclub-invoice-form-select-students',
  templateUrl: './invoice-form-select-students.component.html'
})
export class InvoiceFormSelectStudentsComponent implements OnInit {
  form = new FormGroup({});
  formFields: CustomFormlyFieldConfig[] = [
    {
      key: 'type',
      type: 'select',
      props: {
        label: 'Kurum Türü',
        options: [
          { label: 'Okul', value: 1 },
          { label: 'Servis Sağlayıcı | Egitim', value: 2 },
          { label: 'Servis Sağlayıcı | Operasyon', value: 3 }
        ]
      }
    },
    {
      type: '#school',
      expressions: {
        hide: 'model.type != 1'
      }
    },
    {
      type: '#serviceProviderEducation',
      expressions: {
        hide: 'model.type != 2'
      }
    },
    {
      type: '#serviceProviderOperation',
      expressions: {
        hide: 'model.type != 3'
      }
    }
  ];
  model: any = {};

  enrollments: EnrollmentCreditBalanceDto[];
  selectedEnrollments: EnrollmentCreditBalanceDto[] = [];
  buttons: UtclubButtonInterface[] = [
    {
      label: 'İptal',
      color: 'warn',
      click: () => {
        this.matDialogRef.close();
      },
      buttonType: 'button'
    },
    {
      label: 'Tamam',
      color: 'primary',
      click: () => {
        this.matDialogRef.close(this.selectedEnrollments);
      },
      buttonType: 'button'
    }
  ];
  loading = false;
  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private matDialogRef: MatDialogRef<InvoiceFormSelectStudentsComponent>,
    private lookup: LookupService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value: any) => {
      if (value.schoolId || value.serviceProviderId) {
        this.loadCredits();
      }
    });
  }

  loadCredits() {
    this.loading = true;
    this.cdr.detectChanges();
    var model = {
      type: this.model.type,
      providerId: this.model.type == 1 ? this.model.schoolId : this.model.serviceProviderId
    };
    this.apiService
      .post<Response<EnrollmentCreditBalanceDto[]>>('student-service/enrollment/credit-balances-by-provider', model)
      .subscribe({
        next: (response) => {
          this.enrollments = response.data;
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }
}
