import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthService } from './auth.service';
import { EventBusService, EventData } from './event-bus.service';
import { Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AccessTokenDto } from "../api/models/accessTokenDto";
import { Response } from "../models/response.model";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  loginSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private eventBusService: EventBusService,
    private apiService: ApiService
  ) { }

  public hubConnection: signalR.HubConnection

  public async installConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.NOTIFICATION_HUB_URL + '?access_token=' + this.authService.tokenValue, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    if (!this.authService.isExpiredValue) {
      this.startHubConnection();
    }

    this.eventBusService.on('userLoggedIn', (data) => {
      if (this.hubConnection.state != signalR.HubConnectionState.Connected) {
        this.startHubConnection();
      }
    });

    this.eventBusService.on('userLoggedOut', (data) => {
      if (this.hubConnection.state == signalR.HubConnectionState.Connected) {
        this.stopHubConnection();
      }
    });
  }

  async startHubConnection() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');
        this.hubConnection.on('NewEvent', (notification) => {
          this.eventBusService.emit(new EventData(notification.eventType, notification));
        });
        this.hubConnection.on('UpdateAccessToken', () => {
          this.apiService.post<Response<AccessTokenDto>>("auth-service/auth/refresh-token", {
            token: this.authService.tokenValue,
            refreshToken: this.authService.refreshTokenValue
          }).subscribe(response => {
            this.authService.setCredentials(response.data);
          }, error => {
            this.authService.logout();
          });
        });
        this.hubConnection.invoke('GetUsers');
      })
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  async stopHubConnection() {
    this.hubConnection
      .stop()
      .then(() => {
        console.log('Connection stopped');
      })
      .catch(err => console.log('Error while stopping connection: ' + err));
  }
}
