<div class="row mb-2">
  <div class="flex">
    <div class="mr-4">
      <img
        *ngIf="student.photo"
        @fadeInRight
        [src]="env.CDN_URL + student.photo"
        class="rounded w-32 h-32 aspect-square" />
    </div>
    <div @fadeInRight class="flex-1">
      <div class="m-0 mb-2 body-1 text-sm relative">
        <div class="flex items-center">
          <mat-icon
            svgIcon="mat:tag"
            class="mr-2 cursor-copy"
            color="primary"
            [cdkCopyToClipboard]="entityId.toString()"
            (click)="copyToClipboard()"></mat-icon>
          <span class="m-0"> {{ entityId }} </span>
        </div>
        <div
          *ngIf="enrollmentStatus"
          class="rounded text-center px-4 py-2 cursor-pointer absolute right-0 top-0"
          [class]="'enrollment-status-' + enrollmentStatus">
          {{ enrollmentStatus | enum: 'EnrollmentStatus' | async }}
        </div>

        <div
          *ngIf="programType"
          [class]="'program-type-' + programType"
          class="rounded text-center px-4 py-2 cursor-pointer absolute right-0 top-0">
          {{ programType | enum: 'ProgramType' | async }}
        </div>
      </div>
      <div class="m-0 mb-2 body-1 text-sm">
        <div class="flex items-center">
          <mat-icon
            svgIcon="mat:person"
            class="mr-2 cursor-copy"
            color="primary"
            [cdkCopyToClipboard]="student.firstName + ' ' + student.lastName"
            (click)="copyToClipboard()"></mat-icon>
          <span class="m-0">{{ student.firstName }} {{ student.lastName }}</span>
        </div>
      </div>
      <div class="m-0 mb-2 body-1 text-sm">
        <div class="flex items-center">
          <mat-icon
            svgIcon="mat:phone"
            class="mr-2 cursor-copy"
            color="primary"
            [cdkCopyToClipboard]="student.phoneNumber"
            (click)="copyToClipboard()"></mat-icon>
          <span class="m-0">{{ student.phoneNumber }}</span>
        </div>
      </div>
      <div class="m-0 body-1 text-sm">
        <div class="flex items-center">
          <mat-icon
            svgIcon="mat:email"
            class="mr-2 cursor-copy"
            color="primary"
            [cdkCopyToClipboard]="student.email"
            (click)="copyToClipboard()"></mat-icon>
          <span class="m-0">{{ student.email }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
