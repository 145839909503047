import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubDynamicContentComponent } from './utclub-dynamic-content.component';
import { ComponentLoaderDirective } from './component-loader.directive';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    UtclubDynamicContentComponent,
    ComponentLoaderDirective
  ],
  exports: [
    UtclubDynamicContentComponent,
    ComponentLoaderDirective,

    MatDialogModule
  ],
  imports: [
    CommonModule
  ]
})
export class UtclubDynamicContentModule { }
