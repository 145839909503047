import { DialogRef } from "@angular/cdk/dialog";
import { AfterViewInit, ChangeDetectorRef, Inject, Injectable, InjectionToken, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ApiService } from "src/app/services/api.service";
import { UtclubButtonInterface } from "../utclub-button/utclub-button.component";
import { CustomFormlyFieldConfig } from "../utclub-formly/formly/custom-formly-field-config";
import { HttpResponse } from "@angular/common/http";
import { UtclubTableColumn } from "src/@vex/interfaces/table-column.interface";
import { UtclubTableComponent } from "./utclub-table.component";
import { MatTableDataSource } from "@angular/material/table";
import { PagedResponse } from "src/app/models/response.model";

export interface UtclubTablePageInterface {
  url: string;

  filterFields?: CustomFormlyFieldConfig[];
}

export declare const UTCLUB_TABLE_PAGE_INSTANCE: InjectionToken<any>;

@Injectable()
export abstract class UtclubTablePageComponent implements OnInit, AfterViewInit {

  _pageInstance: UtclubTablePageInterface
  _apiService: ApiService;
  _cdr: ChangeDetectorRef;

  dataSource: MatTableDataSource<any> | null = null;
  loading: boolean = false;
  columns: UtclubTableColumn[];

  pageSize: number = 10;
  pageIndex: number = 0;
  totalItemCount: number = 0;

  filterModel: any = {};
  filterFields: CustomFormlyFieldConfig[] = null;
  constructor(
    @Inject(UTCLUB_TABLE_PAGE_INSTANCE) pageInstance: UtclubTablePageInterface,
    apiService: ApiService,
    cdr: ChangeDetectorRef
  ) {
    this._pageInstance = pageInstance;
    this._apiService = apiService;
    this._cdr = cdr;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this._apiService.post<PagedResponse<any[]>>(this._pageInstance.url, {
      ...this.filterModel,
      page: this.pageIndex,
      pageSize: this.pageSize,
    }).subscribe(response => {
      this.totalItemCount = response.itemsCount;
      this.totalItemCount = response.itemsCount;
      this.dataSource = new MatTableDataSource(response.data);
      this.loading = false;
      this._cdr.detectChanges();
    });
  }

  pageChanged() {
    this.loadData();
  }
}




