<mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

<table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table>

  <ng-container matColumnDef="enrollment">
    <th mat-header-cell *matHeaderCellDef> Ogrenci </th>
    <td mat-cell *matCellDef="let element">
      #{{element.enrollmentId }}<br>
      {{element.enrollmentName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalCredit">
    <th mat-header-cell *matHeaderCellDef> Borc </th>
    <td mat-cell *matCellDef="let element" class="text-red">
      {{element.totalCredit | currency:element.currencyId | async}}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalReceipt">
    <th mat-header-cell *matHeaderCellDef> Tahsilat </th>
    <td mat-cell *matCellDef="let element" class="text-green">
      {{element.totalReceipt | currency:element.currencyId | async}}
    </td>
  </ng-container>

  <ng-container matColumnDef="totalDiff">
    <th mat-header-cell *matHeaderCellDef> Fark </th>
    <td mat-cell *matCellDef="let element" class="text-amber font-medium">
      {{element.totalDiff | currency:element.currencyId | async}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row @fadeInUp *matRowDef="let subElement; columns: columns;"
    class="hover:bg-hover trans-ease-out cursor-pointer">
  </tr>
</table>


<mat-toolbar>
  <mat-toolbar-row>
    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]" class="sticky left-0"></mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>

<!-- <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Tarih </th>
    <td mat-cell *matCellDef="let element">
      {{element.date | date:'dd-MM-yyyy HH:mm':'+300'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Islem Tipi </th>
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="element.type">
        <div *ngSwitchCase="1" class="rounded px-2 text-red bg-red/10" style="display: inline-block;"> Borc
        </div>
        <div *ngSwitchCase="2" class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
          Tahsilat
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="relatedBranch">
    <th mat-header-cell *matHeaderCellDef> Ofis </th>
    <td mat-cell *matCellDef="let element"> {{element.relatedBranchName}} </td>
  </ng-container>

  <ng-container matColumnDef="executorUser">
    <th mat-header-cell *matHeaderCellDef> İslemi Yapan </th>
    <td mat-cell *matCellDef="let element"> {{element.executorUserName}} </td>
  </ng-container>

  <ng-container matColumnDef="account">
    <th mat-header-cell *matHeaderCellDef> Hesap </th>
    <td mat-cell *matCellDef="let element">
      {{ element.accountName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="detail">
    <th mat-header-cell *matHeaderCellDef> Detay </th>
    <td mat-cell *matCellDef="let element">
      {{ element.detail }}
    </td>
  </ng-container>

  <ng-container matColumnDef="note">
    <th mat-header-cell *matHeaderCellDef> Not </th>
    <td mat-cell *matCellDef="let element">
      {{ element.note }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef> Miktar </th>
    <td mat-cell *matCellDef="let subElement">
      {{subElement.amount | currency:subElement.currencyId | async}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row @fadeInUp *matRowDef="let subElement; columns: columns;"
    class="hover:bg-hover trans-ease-out cursor-pointer">
  </tr>
</table> -->