<div class="overflow-auto w-full flex flex-col">
  <div class="card-header">
    <h2>Review</h2>
    <button
      (click)="openUpdateStudentDialog()"
      class="flex-none"
      mat-icon-button
      matTooltip="Ogrenciyi Duzenle"
      type="button">
      <mat-icon svgIcon="mat:edit"></mat-icon>
    </button>
  </div>
  <div class="p-4">
    <utclub-student
      [student]="inquiry.student"
      [programType]="inquiry.programType"
      [entityId]="inquiry.id"></utclub-student>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> Source :</span>
          <span class="info-value"> {{ inquiry.studentSource | enum: 'StudentSource' | async }} </span>
        </div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> Oluşturma Tarihi :</span>
          <span class="info-value"> {{ inquiry.date | date: 'dd/MM/yyyy' }} </span>
        </div>
        <div class="info-line" @fadeInRight *ngIf="inquiry.referrerText">
          <span class="info-title"> Source Text :</span>
          <span class="info-value"> {{ inquiry.referrerText }} </span>
        </div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> Branch :</span>
          <span class="info-value"> {{ inquiry.branchId | entity: 'Branch' | async }} </span>
        </div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> Consultant :</span>
          <span class="info-value"> {{ inquiry.consultantId | entity: 'User' | async }} </span>
        </div>
      </div>
      <div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> City :</span>
          <span class="info-value"> {{ inquiry.student.cityId | entity: 'City' | async }} </span>
        </div>
        <div class="info-line" @fadeInRight>
          <span class="info-title"> Follow Up Date :</span>
          <span class="info-value"> {{ inquiry.followUpDate | date: 'dd/MM/yyyy' }} </span>
        </div>
        <div class="info-line" @fadeInRight *ngIf="inquiry.plannedStartDate">
          <span class="info-title"> Planned Start Date :</span>
          <span class="info-value"> {{ inquiry.plannedStartDate | date: 'MM/yyyy' }} </span>
        </div>
      </div>
    </div>
  </div>
</div>
