import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { LookupService } from 'src/app/services/lookup.service';

export interface InquiryExtraInformationUpdateDto {
  extra1?: string | null;
  extra2?: string | null;
  extra3?: string | null;
}

@Component({
  selector: 'utclub-inquiry-form-extra-edit',
  template: `
    <div class="card overflow-auto w-full flex flex-col">
      <div class="flex items-center border-b-2 border-b-primary py-2 px-4">
        <h2 class="m-0 flex-auto">Extra Information</h2>
      </div>
      <div class="p-4">
        <utclub-form *ngIf="formInterface" [form]="formInterface" (success)="success($event)"></utclub-form>
      </div>
    </div>
  `
})
export class InquiryFormExtraEditComponent implements AfterContentInit {
  @Output() inquiryUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() inquiry: InquiryDto;
  formInterface: UtclubFormInterface;

  @ViewChild(UtclubFormComponent, { static: false }) form: UtclubFormComponent;
  constructor(private lookUpService: LookupService) {}

  ngAfterContentInit(): void {
    var id = this.inquiry.id;
    var model: InquiryExtraInformationUpdateDto = {
      extra1: this.inquiry.extra1,
      extra2: this.inquiry.extra2,
      extra3: this.inquiry.extra3
    };
    this.formInterface = {
      fields: [
        {
          key: 'extra1',
          type: 'input',
          props: {
            label: 'Extra 1',
            placeholder: ''
          }
        },
        {
          key: 'extra2',
          type: 'input',
          props: {
            label: 'Extra 2',
            placeholder: ''
          }
        },
        {
          key: 'extra3',
          type: 'input',
          props: {
            label: 'Extra 3',
            placeholder: ''
          }
        }
      ],
      defaultModel: model,
      submitMethod: 'put',
      submitUrl: 'student-service/inquiry/' + id + '/extra-information'
    };
  }

  success(event) {
    this.inquiryUpdated.emit();
  }
}
