import { AfterContentInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EnrollmentTransactionDto } from 'src/app/api/models/enrollmentTransactionDto';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Enums } from 'src/app/services/enums';
import { LookupService } from 'src/app/services/lookup.service';
import { Enrollment } from 'src/app/models/enrollment.model';

@Component({
  selector: 'vex-receipt-form',
  templateUrl: './receipt-form.component.html'
})
export class ReceiptFormComponent implements AfterContentInit, OnDestroy {
  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  enrollmentSubs: Subscription;
  receiptTypeEducationReceiptUnitSubs: Subscription;

  schoolId?: number;

  enrollment: Enrollment;
  receiptTypeEducationReceiptUnit: number;

  enrollmentDebitTransactionsLoading: boolean = false;
  enrollmentDebitTransactions: EnrollmentTransactionDto[];

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'receiptType',
      type: 'big-radio',
      props: {
        label: 'İslem Tipi',
        required: true,
        enum: Enums.ReceiptType
      },
      expressions: {
        hide: 'model.receiptType'
      }
    },
    {
      key: 'date',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Tarih',
        placeholder: '',
        required: false
      },
      expressions: {
        hide: (field: CustomFormlyFieldConfig) => {
          return !this.authService.isInRole('Manager') || !field.model.receiptType;
        }
      }
    },
    {
      key: 'enrollmentId',
      type: 'enrollment-field',
      props: {
        label: 'Ogrenci',
        required: true
      },
      expressions: {
        hide: 'model.receiptType != 1 || !model.receiptType',
        'props.required': (field: CustomFormlyFieldConfig) => {
          if (field.model.receiptType == 1 && field.model.receiptTypeEducationReceiptUnit == 7) {
            return false;
          }
          return true;
        }
      }
    },
    {
      key: 'relatedBranchId',
      type: '#branch',
      props: {
        required: true
      },
      expressions: {
        hide: '!model.receiptType'
      }
    },
    {
      key: 'receiptTypeEducationDepartments',
      type: 'enum-select',
      hide: true,
      props: {
        label: 'Department',
        required: true,
        enum: Enums.ReceiptTypeEducationDepartments
      },
      expressions: {
        hide: 'model.receiptType != 1'
      }
    },
    {
      key: 'receiptTypeEducationReceiptUnit',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Gelir Kalemleri',
        required: true,
        enum: Enums.ReceiptTypeEducationReceiptUnits
      },
      expressions: {
        hide: 'model.receiptType != 1'
      }
    },
    {
      type: '#school',
      props: {
        required: true
      },
      expressions: {
        hide: 'model.receiptTypeEducationReceiptUnit != 3'
      }
    },
    {
      key: 'receiptTypeOperationReceiptUnit',
      type: 'enum-select',
      hide: true,
      props: {
        label: 'Gelir Kalemleri',
        required: true,
        enum: Enums.ReceiptTypeOperationReceiptUnits
      },
      expressions: {
        hide: 'model.receiptType != 2'
      }
    },
    {
      type: '#serviceProvider',
      props: {
        required: true
      },
      expressions: {
        hide: `model.receiptTypeOperationReceiptUnit != 21  
                && model.receiptTypeOperationReceiptUnit != 22
                && model.receiptTypeOperationReceiptUnit != 2
                && model.receiptTypeOperationReceiptUnit != 9
                && model.receiptTypeEducationReceiptUnit != 11 
                && model.receiptTypeEducationReceiptUnit != 12
                && model.receiptTypeEducationReceiptUnit != 4`
      }
    },
    {
      key: 'accountId',
      type: '#availableTransactionAccountField',
      props: {
        required: true
      },
      expressions: {
        hide: '!model.receiptType'
      }
    },
    {
      key: 'amount',
      type: 'currency-field',
      props: {
        label: 'Tutar',
        placeholder: '',
        required: true
      },
      expressions: {
        hide: '!model.receiptType'
      }
    },
    {
      key: 'note',
      type: 'textarea',
      props: {
        label: 'Not',
        placeholder: '',
        required: false,
        rows: 3
      },
      expressions: {
        hide: '!model.receiptType'
      }
    }
  ];
  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ReceiptFormComponent>,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: this.fields,
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/receipt' : 'accounting-service/receipt/' + id,
      loadDataUrl: !id ? null : 'accounting-service/receipt/' + id
    };
    this.enrollmentSubs = this.eventBusService.on('enrollment-loaded', (data) => {
      this.form.formGroup.controls['relatedBranchId'].setValue(data.branchId);
      switch (data.programType) {
        case 1:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(2);
          break;
        case 2:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(6);
          break;
        case 3:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(4);
          break;
        case 4:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(3);
          break;
        case 5:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(1);
          break;
        case 6:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(7);
          break;
        case 7:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(8);
          break;
        case 8:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(9);
          break;
        case 9:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(5);
          break;
        case 10:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(10);
          break;
        default:
          this.form.formGroup.controls['receiptTypeEducationDepartments'].setValue(0);
          break;
      }
      this.schoolId = data.schoolId;
      if (data) {
        this.enrollment = data;
        this.controlEnrollmentDebits();
      }
    });
  }

  valueChanges(value: any) {
    if (value.receiptTypeEducationReceiptUnit == 3 && this.schoolId) {
      this.form.model.schoolId = this.schoolId;
    }
    if (this.form.model.receiptTypeEducationReceiptUnit) {
      this.receiptTypeEducationReceiptUnit = this.form.model.receiptTypeEducationReceiptUnit;
      this.controlEnrollmentDebits();
    }
  }

  controlEnrollmentDebits() {
    if (
      this.enrollment &&
      this.receiptTypeEducationReceiptUnit &&
      (this.receiptTypeEducationReceiptUnit == 1 || this.receiptTypeEducationReceiptUnit == 3)
    ) {
      this.enrollmentDebitTransactionsLoading = true;
      var type;
      if (this.receiptTypeEducationReceiptUnit == 1) {
        type = 2;
      } else if (this.receiptTypeEducationReceiptUnit == 3) {
        type = 1;
      }
      this.apiService
        .get<
          Response<EnrollmentTransactionDto[]>
        >('accounting-service/transaction/enrollment-debit-transactions/' + this.enrollment.id + '/' + type + '/')
        .subscribe(
          (response) => {
            this.enrollmentDebitTransactions = response.data;
            this.enrollmentDebitTransactionsLoading = false;
            this.cdr.detectChanges();
          },
          (error) => {
            this.enrollmentDebitTransactionsLoading = false;
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.enrollmentSubs.unsubscribe();
  }
}
