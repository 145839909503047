import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { PagedResponse, Response } from 'src/app/models/response.model';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { BranchBalanceDto } from 'src/app/api/models/branchBalanceDto';
import { BranchDebitBalanceDto } from 'src/app/api/models/branchDebitBalanceDto';
import { CurrencyDto } from 'src/app/api/models/currencyDto';
import { TransactionDto } from 'src/app/api/models/transactionDto';
import { MatDialog } from '@angular/material/dialog';
import { DebitAccountTransactionsModalComponent } from './debit-account-transactions-modal/debit-account-transactions-modal.component';
import { BranchDto } from 'src/app/api/models/branchDto';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { ResetDebitAccountsCommandDto } from 'src/app/api/models/resetDebitAccountsCommandDto';


@Component({
  selector: 'utclub-branch-balances',
  templateUrl: './branch-balances.component.html',
  styleUrls: ['./branch-balances.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class BranchBalancesComponent implements OnInit {
  branchDataSource: MatTableDataSource<any> | null = null;
  debitsDataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  loading: boolean = false;

  currencies: CurrencyDto[] = [];

  credits: any = {};
  debits: any = {};
  totals: any = {};
  totalCash: any = {};

  result: BranchBalanceDto;

  columnWidth = 50;

  isManager: boolean = false;

  branches: BranchDto[] = [];
  branchId?: number = null;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private entityService: EntityService,
    private matDialog: MatDialog,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.isManager = this.authService.isInRole('Manager');
    this.currencies = await this.entityService.getEntities('Currency');
    this.loadData();
    this.apiService.get<Response<BranchDto[]>>('location-service/branch').subscribe(response => {
      this.branches = response.data;
    });
  }

  loadData() {
    this.credits = {};
    this.debits = {};
    this.totals = {};
    this.totalCash = {};
    this.currencies.forEach(currency => {
      this.credits[currency.code] = 0;
      this.debits[currency.code] = 0;
      this.totals[currency.code] = 0;
      this.totalCash[currency.code] = 0;
    });

    this.loading = true;
    var url = 'accounting-service/account/branch-balances';
    if (this.branchId)
      url += '?branchId=' + this.branchId;
    this.apiService.get<Response<BranchBalanceDto>>(url).subscribe(response => {
      response.data.branchDebits.forEach((item: BranchDebitBalanceDto) => {
        this.currencies.forEach(element => {
          if (!item.debits[element.code]) {
            item.debits[element.code] = 0;
          }
          if (!item.credits[element.code]) {
            item.credits[element.code] = 0;
          }

          this.credits[element.code] += item.credits[element.code];
          this.debits[element.code] += item.debits[element.code];
          var total = item.credits[element.code] - item.debits[element.code];
          this.totals[element.code] += total;
        });
      });
      response.data.accountCases.forEach((item) => {
        this.currencies.forEach(element => {
          this.totalCash[element.code] += item.cashes[element.code];
        });
      });
      this.result = response.data;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  listTransactions(debitAccountId: number) {
    this.matDialog.open(DebitAccountTransactionsModalComponent, {
      width: '800px',
      height: 'auto',
      data: {
        debitAccountId: debitAccountId
      }
    });
  }

  onChangeBranch(e) {
    this.branchId = e;
    this.loadData();
  }

  resetDebitAccounts(debit: BranchDebitBalanceDto, currency: CurrencyDto) {
    var model: ResetDebitAccountsCommandDto = {
      branchDebitAccountIds: [
        debit.credits[currency.code + 'DebitAccountId'],
        debit.debits[currency.code + 'DebitAccountId']
      ],
      type: 1
    };
    this.apiService.post('accounting-service/debit/reset-debit-accounts', model).subscribe(response => {
      this.loadData();
    });
  }
}
