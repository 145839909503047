<div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

  <h2 *ngIf="selection.hasValue()"
    class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
    <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
      item<span *ngIf="selection.selected.length > 1">s</span> selected</span>
  </h2>
  <ng-container *ngIf="selection.hasValue()">
    <utclub-button *ngFor="let button of selectionActions" [button]="button"></utclub-button>
  </ng-container>

  <utclub-button *ngIf="filterFields" buttonType="button" [label]="'Filter'" (click)="filterToggle()"
    [icon]="filterOpened ? 'mat:keyboard_arrow_down' : 'mat:keyboard_arrow_right'" class="mr-4"></utclub-button>

  <button (click)="refreshData()" class="flex-none" mat-icon-button matTooltip="Yenile" type="button">
    <mat-icon svgIcon="mat:refresh"></mat-icon>
  </button>

  <ng-content select="[top-actions]"></ng-content>

  <span class="flex-1"></span>

  <button [matMenuTriggerFor]="columnFilterMenu" class="mr-4 flex-none" mat-icon-button matTooltip="Kolonlari Sec"
    type="button">
    <mat-icon svgIcon="mat:filter_list"></mat-icon>
  </button>

  <utclub-button *ngFor="let button of topActions" [button]="button">
  </utclub-button>
</div>

<div *ngIf="filterOpened" @fadeInUp class="p-4">
  <utclub-formly [form]="filterForm" [fields]="localFilterFields" [model]="filterModel">
  </utclub-formly>

  <div>
    <utclub-button buttonType="button" [label]="'Filtrele'" (click)="filtered()" class="mr-2">
    </utclub-button>
    <utclub-button buttonType="button" [label]="'Temizle'" [color]="'warn'" (click)="clearFilter()">
    </utclub-button>
  </div>
</div>



<mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

<table #table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort (matSortChange)="sortChange($event)">

  <!-- CHECKBOX-->
  <ng-container matColumnDef="checkbox">
    <th *matHeaderCellDef mat-header-cell>
      <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
      </mat-checkbox>
    </th>
    <td *matCellDef="let row" class="w-4" mat-cell>
      <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
        [checked]="selection.isSelected(row)" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- ACTION -->
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
      <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ entity: row }"
        [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
        <mat-icon svgIcon="mat:more_horiz"></mat-icon>
      </button>
    </td>
  </ng-container>


  <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
    <ng-container *ngIf="column.columnType === 'text'" [matColumnDef]="column.property" [sticky]="column.sticky">
      <th *matHeaderCellDef mat-header-cell>
        {{ column.label }}
      </th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
        {{ row[column.property] }}
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnType === 'enum'" [matColumnDef]="column.property">
      <th *matHeaderCellDef mat-header-cell>
        {{ column.label }}
      </th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
        {{ row[column.property] | enum:column.enum | async }}
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnType === 'entity'" [matColumnDef]="column.property">
      <th *matHeaderCellDef mat-header-cell>
        {{ column.label }}
      </th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
        <span *ngIf="row[column.property]">
          {{ row[column.property] | entity:column.entity | async }}
        </span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnType === 'date'" [matColumnDef]="column.property">
      <th *matHeaderCellDef mat-header-cell>
        {{ column.label }}
      </th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
        <span *ngIf="row[column.property]">
          {{ row[column.property] | date:'dd-MM-yyyy HH:mm':'+300' }}

        </span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnType === 'currency'" [matColumnDef]="column.property">
      <th *matHeaderCellDef mat-header-cell>
        {{ column.label }}
      </th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
        <span *ngIf="row[column.property]">
          {{ row[column.property] | number:'1.2-2' }}
        </span>
      </td>
    </ng-container>
  </ng-container>

  <ng-content></ng-content>

  <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
  <tr (click)="rowClicked(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
    class="hover:bg-hover trans-ease-out" [class.cursor-pointer]="rowClick" mat-row></tr>
</table>

<mat-paginator [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" [pageSize]="pageSize"
  (page)="pageEvent($event)" [length]="totalItemCount" class="sticky left-0">
</mat-paginator>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-entity="entity" matMenuContent>
    <utclub-button *ngFor="let button of rowActions" [button]="button" [entity]="entity">
    </utclub-button>
  </ng-template>
</mat-menu>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns" class="mat-mdc-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>