import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div style="margin-bottom: 10px;">
      <button mat-raised-button color="primary" (click)="add()">{{ field.props.label }} Ekle</button>
    </div>

    <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-baseline">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="col-1 flex align-items-center">
        <button mat-icon-button color="second" type="button" (click)="remove(i)">
          <mat-icon [svgIcon]="'mat:close'"></mat-icon>
        </button>
      </div>
    </div>
  `
})
export class RepeatFieldComponent extends FieldArrayType {}
