import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { CurrencyDto } from 'src/app/api/models/currencyDto';

export interface CreditSchoolBalanceDto {
  schoolId: number | null;
  schoolName: string;
  currencyId: number;
  credit: number;
  receipt: number;
  diff: number;
  expanded: boolean;
}

@Component({
  selector: 'utclub-school-balances',
  templateUrl: './school-balances.component.html',
  styleUrls: ['./school-balances.component.scss']
})
export class SchoolBalancesComponent {
  currencies: CurrencyDto[];
  currencyId: number | null = null;
  loading: boolean = false;

  totalCredit: number = 0;
  totalReceipt: number = 0;

  data: CreditSchoolBalanceDto[];

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.apiService.get<Response<CurrencyDto[]>>('accounting-service/currency').subscribe(response => {
      this.currencies = response.data;
    });
    this.loadData();
  }

  onCurrencyIdChange(event) {
    this.currencyId = event;
    this.loadData();
  }

  loadData() {
    this.totalCredit = 0;
    this.totalReceipt = 0;
    this.loading = true;
    this.apiService.post<Response<CreditSchoolBalanceDto[]>>('accounting-service/credit/get-school-balances', {
      currencyId: this.currencyId
    }).subscribe(response => {
      response.data.forEach((item: CreditSchoolBalanceDto) => {
        this.totalCredit += item.credit;
        this.totalReceipt += item.receipt;
      });

      this.data = response.data;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

}
