<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span> Servis Sağlayıcı | Operasyon </span>
    </h2>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-select #input formControlName="serviceProviderId" class="w-60 rounded p-2 bg-white text-gray-600 mr-4">
        <mat-option>
          <ngx-mat-select-search
            [closeSvgIcon]="'mat:close'"
            [placeholderLabel]="'Ara...'"
            [formControl]="searchCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngIf="loadingItems">
          <div class="flex items-center justify-between">
            <span> Yükleniyor... </span>
            <span>
              <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
            </span>
          </div>
        </mat-option>

        <mat-option *ngIf="anyErrors" (click)="loadItems()">
          <div class="flex items-center justify-between text-red-500">
            <span>Bir hata oluştu</span>
            <button mat-icon-button>
              <mat-icon svgIcon="mat:refresh"></mat-icon>
            </button>
          </div>
        </mat-option>

        <mat-option *ngIf="!searchCtrl.value && !loadingItems && !anyErrors" [value]="null"> Seçiniz </mat-option>

        <mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>

      <input type="number" step="1" formControlName="year" class="w-20 rounded p-2 bg-white text-gray-600 mr-4" />
    </form>

    <button
      class="ml-4 flex-none"
      mat-raised-button
      (click)="onSubmit()"
      matTooltip="Yenile"
      type="button"
      color="primary">
      Getir
    </button>

    <span class="flex-1"></span>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && data">
    <thead>
      <tr>
        <th>Ofis</th>
        <th>Alacak</th>
        <th>Tahsilat</th>
        <th>Fark</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of data">
        <tr (click)="item.expanded = !item.expanded">
          <td>
            {{ item.branchId | entity: 'Branch' | async }} |
            {{ item.currencyId | entity: 'Currency' | async }}
          </td>
          <td>
            <p class="text-secondary m-0">
              <span class="font-medium text-red"> {{ item.totalCredit | currency: item.currencyId | async }} </span>
            </p>
          </td>
          <td>
            <p class="text-secondary m-0">
              <span class="font-medium text-green"> {{ item.totalReceipt | currency: item.currencyId | async }} </span>
            </p>
          </td>
          <td>
            <p class="text-secondary m-0">
              <span class="font-medium text-blue">
                {{ item.totalDiff | currency: item.currencyId | async }}
              </span>
            </p>
          </td>
        </tr>
        <tr *ngIf="item.expanded">
          <td colspan="5">
            <table>
              <thead>
                <tr>
                  <th>Tarih / No</th>
                  <th>Enrollment</th>
                  <th>Tip</th>
                  <th>Miktar</th>
                  <th>Not</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of item.transactions">
                  <td class="text-center">
                    #{{ transaction.id }} <br />
                    {{ transaction.date | date: 'dd-MM-yyyy HH:mm' : '+300' }}
                  </td>
                  <td>
                    <span *ngIf="transaction.enrollmentId">
                      {{ transaction.enrollmentName }}<br />
                      #{{ transaction.enrollmentId }}
                    </span>
                  </td>
                  <td>
                    <span *ngIf="transaction.type == 1" class="text-white bg-red-500 rounded-full px-2">Alacak</span>
                    <span *ngIf="transaction.type == 2" class="text-white bg-green-500 rounded-full px-2"
                      >Tahsilat</span
                    >
                  </td>
                  <td>{{ transaction.amount | currency: transaction.currencyId | async }}</td>
                  <td>{{ transaction.note }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td><b>Toplam</b></td>
        <td>
          <p class="text-secondary m-0">
            <span class="font-medium text-red"> {{ totalCredit | currency: 0 | async }} </span>
          </p>
        </td>
        <td>
          <p class="text-secondary m-0">
            <span class="font-medium text-green"> {{ totalReceipt | currency: 0 | async }} </span>
          </p>
        </td>
        <td>
          <p class="text-secondary m-0">
            <span class="font-medium text-blue"> {{ totalDiff | currency: 0 | async }} </span>
          </p>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
