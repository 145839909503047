<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span> Kasa Nakit Raporu </span>
    </h2>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Kasa İsmi </th>
      <td mat-cell *matCellDef="let element">
        {{element.name}}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>Toplam:</b>
      </td>
    </ng-container>

    <ng-container *ngFor="let currency of currencies" [matColumnDef]="currency">
      <th mat-header-cell *matHeaderCellDef> {{currency}} </th>
      <td mat-cell *matCellDef="let element">
        {{ element.accounts[currency] | number:'1.2-2'  }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <b>{{ currencyTotals[currency] | number:'1.2-2' }}</b>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row @fadeInUp (click)="rowClicked(row)" class="hover:bg-hover trans-ease-out cursor-pointer"
      *matRowDef="let row; columns: columns;"></tr>
    <tr mat-footer-row *matFooterRowDef="columns"></tr>
  </table>
</div>