import { AfterContentInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import moment from 'moment';
import { Moment } from 'moment';
import { AccommodationDto } from 'src/app/api/models/accommodationDto';
import { PagedResponse, Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { InquiryOfferFormFeeTableComponent } from './inquiry-offer-form-fee-table/inquiry-offer-form-fee-table.component';
import { AccommodationFilterDto } from 'src/app/api/models/accommodationFilterDto';
import { UtclubConfirmDialogComponent } from 'src/app/components/utclub-confirm-dialog/utclub-confirm-dialog.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Offer } from 'src/app/models/offer.model';
export interface OfferCreateDto {
  inquiryId?: number;
  healthInsurance?: boolean;
  courier?: boolean;
  materialPrice?: boolean;
  exam?: boolean;
  airportWelcomingType?: number;
  plannedStartDate?: string | null;
  items?: Array<OfferItemCreateDto> | null;
  comment?: string | null;
}

export interface OfferItemCreateDto {
  schoolBranchId: number;
  programId: number;

  feeId: number;
  week?: number;
  accommodationId?: number | null;
  accommodationWeek?: number | null;
}

@Component({
  selector: 'utclub-inquiry-offer-form',
  templateUrl: './inquiry-offer-form.component.html',
  styleUrls: ['./inquiry-offer-form.component.scss']
})
export class InquiryOfferFormComponent implements AfterContentInit {
  model: OfferCreateDto = { items: [] };
  programType: number;

  accommodationFees: { [key: number]: AccommodationDto[] } = {};
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild(InquiryOfferFormFeeTableComponent, { static: true }) feeTable: InquiryOfferFormFeeTableComponent;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<InquiryOfferFormComponent>,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.model.inquiryId = this.defaults.inquiryId;
    this.programType = this.defaults.programType;
  }

  ngAfterContentInit(): void {
    this.stepper._getIndicatorType = () => 'number';
  }

  submitLoading: boolean;
  submit() {
    if (this.model.items.length == 0) {
      this.snackbar.open('Please select fees', 'Close', { duration: 3000 });
      return;
    }

    var items: OfferItemCreateDto[] = [];
    this.model.items.forEach((element) => {
      if (!element.week || element.week < 1) return;
      if (element.accommodationId && (!element.accommodationWeek || element.accommodationWeek < 1)) {
        this.snackbar.open('Please enter accommodation week', 'Close', { duration: 3000 });
        return;
      }
      items.push({
        feeId: element.feeId,
        week: element.week,
        accommodationId: element.accommodationId,
        accommodationWeek: element.accommodationWeek,
        programId: element.programId,
        schoolBranchId: element.schoolBranchId
      });
    });

    if (items.length == 0) {
      this.snackbar.open('Please enter week', 'Close', { duration: 3000 });
      return;
    }

    var model: OfferCreateDto = { ...this.model };
    model.items = items;

    this.submitLoading = true;
    this.cdr.detectChanges();
    this.apiService.post('student-service/offer', model).subscribe(
      (response: Response<Offer>) => {
        this.dialog.open(UtclubConfirmDialogComponent, {
          data: {
            title: 'Offer Created',
            description: 'Offer has been created successfully',
            buttons: [
              {
                label: 'Open Offer',
                color: 'primary',
                click: () => {
                  window.open(environment.OFFER_URL + response.data.uniqueId, '_blank');
                },
                buttonType: 'button'
              }
            ]
          }
        });
        this.submitLoading = false;
        this.cdr.detectChanges();
      },
      (error) => {
        this.snackbar.open(error.error.message, 'Close', { duration: 3000 });
        this.submitLoading = false;
        this.cdr.detectChanges();
      }
    );
  }

  nextPage() {
    if (this.feeTable.selection.selected.length == 0) {
      this.snackbar.open('Please select programs', 'OK', { duration: 3000 });
      return;
    }
    if (this.feeTable.selection.selected.length > 3) {
      this.snackbar.open('Please select only 3 programs', 'OK', { duration: 3000 });
      return;
    }
    this.model.items = [];
    if (this.feeTable.selection.selected.length == 1) {
      var element = this.feeTable.selection.selected[0];
      this.model.items.push({
        schoolBranchId: element.schoolBranchId,
        programId: element.programId,
        feeId: element.id,
        week: null,
        accommodationId: null,
        accommodationWeek: null
      });
      this.model.items.push({
        schoolBranchId: element.schoolBranchId,
        programId: element.programId,
        feeId: element.id,
        week: null,
        accommodationId: null,
        accommodationWeek: null
      });
      this.model.items.push({
        schoolBranchId: element.schoolBranchId,
        programId: element.programId,
        feeId: element.id,
        week: null,
        accommodationId: null,
        accommodationWeek: null
      });
      this.loadAccommodation(element.schoolBranchId);
    } else {
      this.feeTable.selection.selected.forEach((element) => {
        this.model.items.push({
          schoolBranchId: element.schoolBranchId,
          programId: element.programId,
          feeId: element.id,
          week: 1,
          accommodationId: null,
          accommodationWeek: null
        });
        this.loadAccommodation(element.schoolBranchId);
      });
    }
    this.stepper.next();
  }

  removeFromOffer(index: number) {
    this.model.items.splice(index, 1);
    this.cdr.detectChanges();
  }

  loadAccommodation(schoolBranchId: number) {
    if (this.accommodationFees[schoolBranchId]) return;
    this.accommodationFees[schoolBranchId] = [];
    var accommodationFilterDto: AccommodationFilterDto = { schoolBranchId: schoolBranchId, paging: false };
    this.apiService
      .post('student-service/accommodation/filter', accommodationFilterDto)
      .subscribe((response: PagedResponse<AccommodationDto>) => {
        this.accommodationFees[schoolBranchId] = response.data;
      });
  }

  backPage() {
    this.model.items = [];
    this.stepper.previous();
  }

  dateFilter(d: Moment): boolean {
    if (!d) d = moment();
    var day = d.day();
    return day == 1;
  }
}
