import { CustomFormlyFieldConfig } from './../custom-formly-field-config';
import { EnumsService } from 'src/app/services/enums.service';

export class EnumOptionsExtension {
  constructor(private enumsService: EnumsService) { }

  prePopulate(field: CustomFormlyFieldConfig): void {
    
  }
};

export function registerEnumOptionsExtension(enumsService: EnumsService) {
  return {
    extensions: [
      {
        name: 'enum-options-extension',
        extension: new EnumOptionsExtension(enumsService)
      }
    ]
  };
}