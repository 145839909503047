import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubConfirmDialogComponent } from './utclub-confirm-dialog.component';
import { UtclubModalModule } from '../utclub-modal/utclub-modal.module';
import { UtclubButtonModule } from '../utclub-button/utclub-button.module';



@NgModule({
  declarations: [
    UtclubConfirmDialogComponent
  ],
  exports: [
    UtclubConfirmDialogComponent
  ],
  imports: [
    CommonModule,

    UtclubModalModule,
    UtclubButtonModule
  ]
})
export class UtclubConfirmDialogModule { }
