import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function EmailValidatorFn(
  c: AbstractControl,
  field: FormlyFieldConfig,
  options?: {
    [id: string]: any;
  }
): ValidationErrors | null {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(c.value) ? null : { email: true };
}

export function EmailValidatorMessage(err, field: CustomFormlyFieldConfig) {
  return `"${field.formControl.value}" eposta adresi geçerli değil`;
}
