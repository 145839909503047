import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { GetSchoolBalanceTransactionsQueryDto } from 'src/app/api/models/getSchoolBalanceTransactionsQueryDto';
import { SchoolBalanceTransactionDto } from 'src/app/api/models/schoolBalanceTransactionDto';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'utclub-school-balances-transactions',
  templateUrl: './school-balances-transactions.component.html',
  styleUrls: ['./school-balances-transactions.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class SchoolBalancesTransactionsComponent implements OnInit, AfterViewInit {
  @Input() currencyId: number;
  @Input() schoolId: number;

  dataSource: MatTableDataSource<SchoolBalanceTransactionDto> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading: boolean = false;
  columns: string[] = ["enrollment", "totalCredit", "totalReceipt", "totalDiff"];

  pageSize: number = 10;
  pageIndex: number = 0;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.loadData();
  }

  loadData() {
    var model: GetSchoolBalanceTransactionsQueryDto = {
      currencyId: this.currencyId,
      schoolId: this.schoolId
    };
    this.apiService.post<PagedResponse<SchoolBalanceTransactionDto>>('accounting-service/credit/get-school-balance-transactions', model).subscribe(response => {
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.loading = false;
    });
  }

  sortChanged(event) {
  }
    
}
