import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'utclub-formly',
  templateUrl: './utclub-formly.component.html'
})
export class UtclubFormlyComponent implements OnInit {
  @Input() form = new FormGroup({});

  @Input() fields: FormlyFieldConfig[];
  @Input() model: any;
  @Input() showSubmit: boolean = false;

  @Input() loading: boolean = false;
  @Input() errorMessage: string = '';
  @Input() validatonMessages: { key: string; value: string }[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSubmit() {
    this.submit.emit(this.model);
  }
}
