import { Component, Input } from '@angular/core';

@Component({
  selector: 'student-log-value-line',
  template: ` <div class="flex flex-row items-center">{{ title }}: {{ oldValue }} => {{ newValue }}</div> `
})
export class StudentLogValueLineComponent {
  @Input() title: string;
  @Input() newValue: string;
  @Input() oldValue: string;
}
