import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'utclub-inquiry-form-activate-dialog',
  standalone: true,
  imports: [MatDialogModule, MatDividerModule, MatButtonModule, MatProgressBarModule],
  templateUrl: './inquiry-form-activate-dialog.component.html',
  styleUrl: './inquiry-form-activate-dialog.component.scss'
})
export class InquiryFormActivateDialogComponent {
  matDialogRef: any;
  loading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<InquiryFormActivateDialogComponent>,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.matDialogRef = dialogRef;
  }

  continue() {
    this.loading = true;
    this.cdr.detectChanges();
    this.apiService.put<Response>('student-service/inquiry/' + this.defaults.inquiryId + '/activate', {}).subscribe({
      next: (response) => {
        this.dialogRef.close({ refresh: true });
      },
      error: (error) => {
        this.snackbar.open('Bir hata oluştu', 'Kapat', {
          duration: 2000
        });
        this.dialogRef.close({ refresh: true });
      }
    });
  }
}
