import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { InvoiceInformationDto } from 'src/app/api/models/invoiceInformationDto';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-invoice-form-select-customer',
  templateUrl: './invoice-form-select-customer.component.html'
})
export class InvoiceFormSelectCustomerComponent {
  model: any = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'invoiceInformationId',
      type: 'http-field',
      props: {
        label: 'Fatura Bilgileri',
        http: {
          url: 'accounting-service/invoiceinformation'
        }
      }
    }
  ];
  buttons: UtclubButtonInterface[] = [
    {
      label: 'İptal',
      color: 'warn',
      click: () => {
        this.matDialogRef.close();
      },
      buttonType: 'button'
    },
    {
      label: 'Tamam',
      color: 'primary',
      click: () => {
        this.loadInvoiceInformation();
      },
      buttonType: 'button'
    }
  ];
  invoiceInformationIdSubs: Subscription;
  invoiceInformation: any;

  constructor(
    private matDialogRef: MatDialogRef<InvoiceFormSelectCustomerComponent>,
    private apiService: ApiService
  ) {}

  loadInvoiceInformation() {
    this.apiService
      .get<Response<InvoiceInformationDto>>('accounting-service/invoiceinformation/' + this.model.invoiceInformationId)
      .subscribe({
        next: (response) => {
          this.matDialogRef.close(response.data);
        }
      });
  }
}
