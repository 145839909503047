import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { AccessTokenDto } from '../api/models/accessTokenDto';
import { Response } from '../models/response.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.authService.tokenValue;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
          return this.handle401Error(authReq, next);
        }
        throw error;
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    this.authService.logout(true);
    throw new Error('Unauthorized');
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);

  //     const accessToken = this.authService.tokenValue;
  //     const refreshToken = this.authService.refreshTokenValue;

  //     if (refreshToken)
  //       return this.apiService.post<Response<AccessTokenDto>>("auth-service/auth/refresh-token", {
  //         token: accessToken,
  //         refreshToken: refreshToken
  //       }).pipe(switchMap((response: Response<AccessTokenDto>) => {
  //         this.isRefreshing = false;

  //         this.authService.setCredentials(response.data);
  //         this.refreshTokenSubject.next(response.data.token);

  //         return next.handle(this.addTokenHeader(request, response.data.token));
  //       }), catchError((err) => {
  //         this.isRefreshing = false;

  //         this.authService.logout();
  //         return throwError(err);
  //       }));
  //   }

  //   return this.refreshTokenSubject.pipe(
  //     filter(token => token !== null),
  //     take(1),
  //     switchMap((token) => next.handle(this.addTokenHeader(request, token)))
  //   );
  // }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }
}
