import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtclubTableColumn } from 'src/@vex/interfaces/table-column.interface';
import { UtclubButtonInterface } from '../utclub-button/utclub-button.component';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { UtclubConfirmDialogService } from '../utclub-confirm-dialog/utclub-confirm-dialog.service';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomFormlyFieldConfig } from '../utclub-formly/formly/custom-formly-field-config';
import { UtclubTabInterface } from '../utclub-tab/utclub-tab.component';

export interface Entity {

}

@Component({
  selector: 'utclub-table',
  templateUrl: './utclub-table.component.html',
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class UtclubTableComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() columns: UtclubTableColumn[] = [];
  @Input() dataSource: MatTableDataSource<any> | null = null;
  @Input() loading: boolean = false;

  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() rowActions: UtclubButtonInterface[] = [];
  @Input() topActions: UtclubButtonInterface[] = [];
  @Input() selectionActions: UtclubButtonInterface[] = [];
  @Input() dialogWidth: string = '700px';
  @Input() panelClass: string = '';

  @Input() pageSize: number;
  @Output() pageSizeChange = new EventEmitter<number>();
  @Input() pageIndex: number;
  @Output() pageIndexChange = new EventEmitter<number>();
  @Input() totalItemCount: number;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Output() pageChanged = new EventEmitter<any>();

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  itemsProperty: string;

  public selection = new SelectionModel<any>(true, []);

  constructor(
    private dialog: MatDialog,
    private utclubConfirmDialogService: UtclubConfirmDialogService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }


  ngAfterViewInit(): void {
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
  }

  addColumnDef(columnDef: MatColumnDef) {
    this.table.addColumnDef(columnDef);
  }

  pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageSizeChange.emit(event.pageSize);
    this.pageIndexChange.emit(event.pageIndex);
    this.pageChanged.emit();
  }
  refreshData() {
    this.pageChanged.emit();
  }

  sortChange(event) {
    // this.loadDataFunc()
  }

  rowClicked(row: any) {
    this.rowClick.emit(row);
  }

  trackByProperty<T>(index: number, column: UtclubTableColumn) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  // FILTER
  @Input() filterModel: any = {};
  @Output() filterModelChange = new EventEmitter<any>();
  @Input() filterFields: CustomFormlyFieldConfig[] = null;
  @Output() filter = new EventEmitter<any>();

  filterOpened: boolean = false;
  localFilterFields: CustomFormlyFieldConfig[] = null;
  filterForm = new FormGroup({});
  filterToggle() {
    if (!this.localFilterFields) {
      this.localFilterFields = this.filterFields;
      this.filterForm.valueChanges.subscribe((value) => {
        this.filterModelChange.emit(this.filterModel);
      });
    }
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    this.filterModelChange.emit(this.filterModel);
    this.filtered();
  }
  filtered() {
    this.filter.emit();
  }

  public addCreateAction(component: any, data: any = {}) {
    this.topActions.push({
      label: 'Yeni',
      icon: 'mat:add',
      click: () => {
        data.formType = 'create';
        this.dialog.open(component, {
          width: this.dialogWidth,
          data: data,
          panelClass: this.panelClass
        }).afterClosed().subscribe(updatedEntity => {
          if (updatedEntity?.refresh) {
            this.pageEvent({ pageIndex: 0, pageSize: this.pageSize });
          }
        });
      },
      buttonType: 'mini-fab'
    })
  }

  public addUpdateAction(component: any) {
    this.rowActions.push({
      label: 'Duzenle',
      icon: 'mat:edit',
      click: (row) => {
        var model = { ...row };
        model.formType = 'update';
        this.dialog.open(component, {
          width: this.dialogWidth,
          data: model,
          panelClass: this.panelClass
        }).afterClosed().subscribe(updatedEntity => {
          if (updatedEntity?.refresh) {
            this.pageEvent({ pageIndex: 0, pageSize: this.pageSize });
          }
        });
      },
      buttonType: 'menu-item'
    })
  }
}
