<div *ngIf="!loading && followUps" class="overflow-auto w-full flex flex-col">
  <div class="card-header">
    <h2>Follow Ups</h2>
    <button
      (click)="openNewFollowUpDialog()"
      class="flex-none"
      mat-icon-button
      matTooltip="New Follow Up"
      type="button">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
    <div style="max-height: 500px" class="p-3 block overflow-scroll">
      <div class="mb-2" *ngIf="inquiry.cancelledType">
        <div class="bg-red-light border rounded p-2 flex items-center">
          <div class="flex-auto text-dark">
            <p class="font-medium">{{ inquiry.cancelledUserName }}</p>

            <div class="flex flex-col sm:flex-row sm:gap-4">
              <div class="flex-auto">
                <p class="text-secondary text-xs">
                  <span class="font-medium">Cancelled Reaseon:</span>
                  {{ inquiry.cancelledType | enum: 'InquiryCancelledType' | async }}
                </p>
              </div>
            </div>

            <div class="flex items-center gap-4 mt-1">
              <div class="relative flex-none">
                <div class="text-red bg-red-light w-8 h-8 rounded flex-none flex items-center justify-center">0</div>
              </div>
              <div class="overflow-hidden flex-auto">
                <p *ngIf="inquiry.cancelledComment">{{ inquiry.cancelledComment }}</p>
              </div>
            </div>
          </div>
        </div>
        <span class="d-block text-right text-hint">
          {{ inquiry.cancelledDate | date: 'dd/MM/yyyy HH:mm' }}
        </span>
      </div>

      <div @fadeInUp class="mb-2" *ngFor="let followUp of followUps">
        <div class="bg-app-bar border rounded p-2 flex items-center">
          <div class="flex-auto text-dark">
            <p class="font-medium">{{ followUp.userName }}</p>

            <div class="flex flex-col sm:flex-row sm:gap-4">
              <div class="flex-auto">
                <p class="text-secondary font-medium text-xs">
                  Follow Up Date: {{ followUp.followUpDate | date: 'dd/MM/yyyy' }}
                </p>
              </div>
              <div class="flex-auto" *ngIf="followUp.completedDate">
                <p class="text-secondary font-medium text-xs">
                  Completed Date: {{ followUp.completedDate | date: 'dd/MM/yyyy' }}
                </p>
              </div>
            </div>

            <div class="flex items-center gap-4 mt-1">
              <div class="relative flex-none">
                <div
                  [class.text-gray]="followUp.rating == 0"
                  [class.bg-gray-light]="followUp.rating == 0"
                  [class.text-amber]="followUp.rating == 1"
                  [class.bg-amber-light]="followUp.rating == 1"
                  [class.text-primary]="followUp.rating == 2"
                  [class.bg-primary-light]="followUp.rating == 2"
                  [class.text-green]="followUp.rating == 3"
                  [class.bg-green-light]="followUp.rating == 3"
                  [class.text-red]="followUp.rating == 4"
                  [class.bg-red-light]="followUp.rating == 4"
                  class="w-8 h-8 rounded flex-none flex items-center justify-center">
                  {{ followUp.rating | enum: 'InquiryRating' | async }}
                </div>
              </div>
              <div class="overflow-hidden flex-auto">
                <p *ngIf="followUp.comment" [innerHtml]="followUp.comment"></p>
              </div>
            </div>
          </div>
        </div>

        <span class="d-block text-right text-hint">
          {{ followUp.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </span>
      </div>
    </div>
  </div>
</div>
