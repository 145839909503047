<div class="border border-dashed border-gray-300 rounded p-3 mb-6 transaction-{{transaction.transactionType}}">
  <div class="flex flex-stack mb-3">
    <div class="me-3">


      <p>
        #{{transaction.id}} <br>
        {{transaction.date | date:'dd-MM-yyyy HH:mm':'+300'}} | {{transaction.relatedBranchName}} <br>
        {{transaction.executorUserName}}
      </p>

    </div>
    <div class="m-0">
      <div class="rounded px-2 text-gray bg-gray/10">
        <span *ngIf="transaction.transactionType == 1">
          {{ transaction.receipt.accountName }}
        </span>
        <span *ngIf="transaction.transactionType == 2">
          {{ transaction.payment.accountName }}
        </span>

        <span *ngIf="transaction.transactionType == 3">
          {{ transaction.exchange.fromAccountName }}
        </span>
        <span *ngIf="transaction.transactionType == 3" class="mx-2">
          <mat-icon svgIcon="mat:arrow_forward"></mat-icon>
        </span>
        <span *ngIf="transaction.transactionType == 3">
          {{ transaction.exchange.toAccountName }}
        </span>

        <span *ngIf="transaction.transactionType == 4">
          {{ transaction.transfer.fromAccountName }}
        </span>
        <span *ngIf="transaction.transactionType == 4" class="mx-2">
          >
        </span>
        <span *ngIf="transaction.transactionType == 4">
          {{ transaction.transfer.toAccountName }}
        </span>

      </div>
    </div>
  </div>

  <div class="flex flex-stack">
    <div>
      <div style="display: flex; align-items: center; justify-content: center;">
        <div [ngSwitch]="transaction.transactionType">
          <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block;"> Tahsilat
          </div>
          <div *ngSwitchCase="2" class="rounded px-2 text-red bg-red/10" style="display: inline-block;"> Odeme </div>
          <div *ngSwitchCase="3" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;"> Doviz
          </div>
          <div *ngSwitchCase="4" class="rounded px-2 text-cyan bg-cyan/10" style="display: inline-block;"> Transfer
          </div>
        </div>
      </div>
    </div>

    <span>

      <div style="display: flex; align-items: center; justify-content: center;">
        <span *ngIf="transaction.transactionType == 1">
          {{ transaction.receipt.amount | currency:transaction.receipt.accountCurrencyId | async }}
        </span>
        <span *ngIf="transaction.transactionType == 2">
          {{ transaction.payment.amount | currency:transaction.payment.accountCurrencyId | async }}
        </span>

        <span *ngIf="transaction.transactionType == 3">
          {{ transaction.exchange.fromAccountAmount | currency:transaction.exchange.fromAccountCurrencyId | async }}
        </span>
        <span *ngIf="transaction.transactionType == 3" class="mx-2">
          >
        </span>
        <span *ngIf="transaction.transactionType == 3">
          {{ transaction.exchange.toAccountAmount | currency:transaction.exchange.toAccountCurrencyId | async }}
        </span>

        <span *ngIf="transaction.transactionType == 4">
          {{ transaction.transfer.amount | currency:transaction.transfer.toAccountCurrencyId | async }}
        </span>
      </div>

    </span>
  </div>
</div>