import { LookupService } from 'src/app/services/lookup.service';
import { CustomFormlyFieldConfig } from './formly/custom-formly-field-config';

export function getStudentSourceFields(lookup: LookupService): CustomFormlyFieldConfig {
  return {
    fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
    fieldGroup: [
      {
        key: 'studentSource',
        type: 'select',
        props: {
          label: 'Form Kaynagi',
          required: true,
          options: [
            { value: 5, label: 'Standard' },
            { value: 2, label: 'UT Club' }, // enrollmentId
            { value: 3, label: 'Yurtdisi Egitim Noktasi' },
            { value: 11, label: 'Yurtdisi Egitim Temsilcisi' },
            { value: 4, label: 'Fuar/Stand' }, // fuar ismi
            { value: 6, label: 'Referans' }, // referans ismi
            { value: 7, label: 'School' }, // schoolId
            { value: 8, label: 'Sosyal Medya' }, // sosyal medya ismi
            { value: 10, label: 'Kurumsal Tanitim' }, // kurumsal tanitim ismi
            { value: 9, label: 'Web Portal' }, // web portal
            { value: 1, label: 'Internet' }
          ]
        }
      },

      // 2, Utclub
      {
        key: 'referrerId',
        type: '#enrollment',
        expressions: {
          hide: 'model.studentSource != 2'
        }
      },
      // 3, Yurtdisi Egitim Noktasi
      {
        key: 'referrerId',
        type: '#studyAbroadPoint',
        expressions: {
          hide: 'model.studentSource != 3'
        }
      },
      // 11, Yurtdisi Egitim Temsilcisi
      {
        key: 'referrerId',
        type: '#studyAbroadRepresentative',
        expressions: {
          hide: 'model.studentSource != 11'
        }
      },
      // 4, Fuar/Stand
      {
        key: 'referrerText',
        type: 'input',
        props: {
          label: 'Fuart/Stand Adi'
        },
        expressions: {
          hide: 'model.studentSource != 4'
        }
      },
      // 6, Referans
      {
        key: 'referrerText',
        type: 'input',
        props: {
          label: 'Referans'
        },
        expressions: {
          hide: 'model.studentSource != 6'
        }
      },
      // 7, School
      {
        key: 'referrerId',
        type: '#school',
        expressions: {
          hide: 'model.studentSource != 7'
        }
      },
      // 8, Sosyal Medya
      {
        key: 'referrerText',
        type: 'input',
        props: {
          label: 'Sosyal Medya Adi'
        },
        expressions: {
          hide: 'model.studentSource != 8'
        }
      },
      // 10, Kurumsal Tanitim
      {
        key: 'referrerId',
        type: '#corporateInquiry',
        expressions: {
          hide: 'model.studentSource != 10'
        }
      },
      // 9, Web Portal
      {
        key: 'referrerId',
        type: '#webPortal',
        expressions: {
          hide: 'model.studentSource != 9'
        }
      }
    ]
  };
}
