import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubPageComponent } from './utclub-page.component';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    UtclubPageComponent
  ],
  exports: [
    UtclubPageComponent
  ],
  imports: [
    CommonModule,

    PageLayoutModule,
    BreadcrumbsModule,
    SecondaryToolbarModule,
    MatIconModule
  ]
})
export class UtclubPageModule { }
