import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InquiryFormComponent } from './inquiry-form.component';
import { InquiryFormReviewComponent } from './inquiry-form-review/inquiry-form-review.component';
import { InquiryFormUtEditComponent } from './inquiry-form-forms/inquiry-form-ut-edit.component';
import { InquiryFormProgramEditComponent } from './inquiry-form-forms/inquiry-form-program-edit.component';
import { InquiryFormFollowUpModule } from './inquiry-form-follow-up/inquiry-form-follow-up.module';
import { UtclubButtonModule } from 'src/app/components/utclub-button/utclub-button.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { UtclubModalModule } from 'src/app/components/utclub-modal/utclub-modal.module';
import { UtclubFormlyModule } from 'src/app/components/utclub-formly/utclub-formly.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatDialogModule } from '@angular/material/dialog';
import { InquiryFormOffersComponent } from './inquiry-form-offers/inquiry-form-offers.component';
import { InquiryOfferFormComponent } from './inquiry-offer-form/inquiry-offer-form.component';
import { UtclubTableModule } from 'src/app/components/utclub-table/utclub-table.module';
import { InquiryOfferFormFeeTableComponent } from './inquiry-offer-form/inquiry-offer-form-fee-table/inquiry-offer-form-fee-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { InquiryFormChangeUserComponent } from './inquiry-form-forms/inquiry-form-change-user.component';
import { UtclubRatingModule } from 'src/app/components/utclub-rating/utclub-rating.module';
import { UtclubPageModule } from 'src/app/components/utclub-page/utclub-page.module';
import { UtclubFormModule } from 'src/app/components/utclub-form/utclub-form.module';
import { EnrollmentCreateFormModule } from '../../enrollment/enrollment-create-form/enrollment-create-form.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtclubStudentModule } from 'src/app/components/utclub-student/utclub-student.module';
import { StudentFormModule } from '../../student/student-form/student-form.module';
import { StudentLogModule } from '../../student/student-log/student-log.module';
import { InquiryFormExtraEditComponent } from './inquiry-form-forms/inquiry-form-extra-edit.component';
import { InquirySendOfferFormComponent } from './inquiry-form-offers/inquiry-send-offer-form/inquiry-send-offer-form.component';

@NgModule({
  declarations: [
    InquiryFormComponent,
    InquiryFormReviewComponent,
    InquiryFormUtEditComponent,
    InquiryFormProgramEditComponent,
    InquiryFormOffersComponent,
    InquiryOfferFormComponent,
    InquiryOfferFormFeeTableComponent,
    InquiryFormChangeUserComponent,
    InquiryFormExtraEditComponent,
    InquirySendOfferFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatDialogModule,

    UtclubButtonModule,
    UtclubPageModule,
    PipesModule,

    InquiryFormFollowUpModule,
    EnrollmentCreateFormModule,
    StudentFormModule,
    StudentLogModule,

    UtclubModalModule,
    UtclubFormlyModule,
    UtclubFormModule,
    UtclubTableModule,
    UtclubRatingModule,
    UtclubStudentModule,

    FormlyModule,
    FormlyMaterialModule,
    DirectivesModule,

    MatDialogModule
  ]
})
export class InquiryFormModule {}
