import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubModalComponent } from './utclub-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { UtclubButtonModule } from '../utclub-button/utclub-button.module';



@NgModule({
  declarations: [
    UtclubModalComponent
  ],
  exports: [
    UtclubModalComponent
  ],
  imports: [
    CommonModule,

    UtclubButtonModule,

    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatDividerModule
  ]
})
export class UtclubModalModule { }
