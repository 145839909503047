import { ChangeDetectorRef, Component, ElementRef, NgIterable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { EnumsService } from 'src/app/services/enums.service';
import { MatSelect } from '@angular/material/select';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { ReplaySubject, Subject, Subscription, take, takeUntil } from 'rxjs';

@Component({
  selector: 'formly-custom-select',
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>

      <mat-select
        #input
        [multiple]="field.props.multiple"
        [required]="field.props.required"
        [formControl]="control"
        [formlyAttributes]="field"
        (opened)="onOpened()">
        <mat-option>
          <ngx-mat-select-search
            [closeSvgIcon]="'mat:close'"
            [formControl]="searchCtrl"
            placeholderLabel="Ara..."></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngIf="!field.props.required && !searchCtrl.value" [value]="null"> Seçiniz </mat-option>

        <mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class SearchableSelectField extends FieldType<CustomFormlyFieldConfig> implements OnInit, OnDestroy {
  @ViewChild('input') input: MatSelect;
  searchCtrl = new FormControl();

  filteredItems: ReplaySubject<{ label: string; value: any }[]> = new ReplaySubject<{ label: string; value: any }[]>();
  _onDestroy = new Subject<void>();
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
    this.filteredItems.next(this.field.props.options.slice());
  }

  setInitialValue() {
    this.filteredItems.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.input.compareWith = (o1: any, o2: any) => {
        return o1 && o2 && o1 === o2;
      };
    });
  }

  filterItems() {
    if (!this.field.props.options) {
      return;
    }
    // get the search keyword
    let search = this.searchCtrl.value;
    if (!search) {
      this.filteredItems.next(this.field.props.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(this.field.props.options.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  onOpened() {
    this.searchCtrl.setValue('');
  }

  onClosed() {
    console.warn(this.input.selected);
  }

  get control() {
    return this.formControl as FormControl;
  }
}
