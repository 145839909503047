<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Create Offer</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col p-0 offer-modal" style="padding: 0">
  <mat-horizontal-stepper #stepper [linear]="true">
    <mat-step label="Select Programs" style="padding: 0">
      <utclub-inquiry-offer-form-fee-table [programType]="programType"></utclub-inquiry-offer-form-fee-table>
    </mat-step>
    <mat-step label="Enter Informations" style="padding: 0">
      <div class="p-4">
        <mat-form-field class="w-full" [appearance]="'outline'">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [matDatepickerFilter]="dateFilter"
            [(ngModel)]="model.plannedStartDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-divider></mat-divider>
        <div *ngFor="let item of model.items; let i = index" class="p-2 my-2 rounded border-gray-400 border">
          <div class="flex items-center">
            <h2 class="flex-1 text-gray-600">
              {{ item.schoolBranchId | entity: 'SchoolBranch' | async }} |
              {{ item.programId | entity: 'Program' | async }}
            </h2>
            <button mat-icon-button type="button" (click)="removeFromOffer(i)">
              <mat-icon class="text-secondary" svgIcon="mat:delete"></mat-icon>
            </button>
          </div>
          <div class="flex items-center gap-4">
            <mat-form-field class="w-full" [appearance]="'outline'">
              <mat-label>Program Week</mat-label>
              <input matInput type="number" [(ngModel)]="item.week" />
            </mat-form-field>
            @if (item.week > 0) {
              <mat-form-field class="w-full" [appearance]="'outline'">
                <mat-label>Accommodation</mat-label>
                <mat-select [(ngModel)]="item.accommodationId">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option
                    *ngFor="let accommodation of accommodationFees[item.schoolBranchId]"
                    [value]="accommodation.id">
                    {{ accommodation.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            }
            @if (item.accommodationId) {
              <mat-form-field class="w-full" [appearance]="'outline'">
                <mat-label>Accommodation Week</mat-label>
                <input matInput type="number" [(ngModel)]="item.accommodationWeek" />
              </mat-form-field>
            }
          </div>
        </div>
        <div class="p-2 my-2 rounded border-gray-400 border">
          <div class="flex items-center justify-start gap-4">
            <mat-checkbox [(ngModel)]="model.courier"> Mail </mat-checkbox>
            <mat-checkbox [(ngModel)]="model.materialPrice"> Material </mat-checkbox>
            <mat-checkbox [(ngModel)]="model.healthInsurance"> Health Insurance </mat-checkbox>
            <mat-checkbox [(ngModel)]="model.exam"> Exam </mat-checkbox>
          </div>
          <mat-radio-group [(ngModel)]="model.airportWelcomingType">
            <mat-radio-button [value]="1">No Meeting on Airport</mat-radio-button>
            <mat-radio-button [value]="2">One way Meeting on Airport</mat-radio-button>
            <mat-radio-button [value]="3">Return Meeting on Airport</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field class="w-full" [appearance]="'outline'">
          <mat-label>Comment</mat-label>
          <textarea matInput [(ngModel)]="model.comment" rows="3"></textarea>
        </mat-form-field>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button
    *ngIf="stepper.selectedIndex == 0"
    mat-raised-button
    color="primary"
    (click)="nextPage()"
    [disabled]="feeTable.selection.selected.length == 0">
    Next
  </button>

  <button *ngIf="stepper.selectedIndex == 1" mat-raised-button color="accent" (click)="backPage()">Back</button>
  <mat-spinner
    *ngIf="stepper.selectedIndex == 1 && submitLoading"
    class="mr-2"
    diameter="30"
    mode="indeterminate"
    color="accent"></mat-spinner>
  <button
    *ngIf="stepper.selectedIndex == 1"
    mat-raised-button
    color="primary"
    (click)="submit()"
    [disabled]="submitLoading">
    Submit
  </button>
</mat-dialog-actions>
