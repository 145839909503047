import { Component, Input } from '@angular/core';
import { TransactionDto } from 'src/app/api/models/transactionDto';

@Component({
  selector: 'utclub-transaction',
  templateUrl: './utclub-transaction.component.html',
  styleUrls: ['./utclub-transaction.component.scss']
})
export class UtclubTransactionComponent {
  @Input() transaction: TransactionDto;
}
