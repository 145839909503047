import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[turkish-charachters-to-english]'
})
export class InputTurkishCharachtersDirective {

  constructor() {
  }

  @HostListener('mouseenter') onMouseEnter() {

  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    // var str = $('#input').val();

    // str_array = str.split('');

    // for (var i = 0, len = str_array.length; i < len; i++) {
    //   str_array[i] = charMap[str_array[i]] || str_array[i];
    // }

    // str = str_array.join('');

    // var clearStr = str.replace(/[Cosugi]/gi, "");


    // var regex = new RegExp(this.InputRestriction);
    // var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    // if (regex.test(str)) {
    //   return true;
    // }

    // event.preventDefault();


    return true;
  }
}
