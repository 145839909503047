import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { ApiService } from 'src/app/services/api.service';
import { NotificationFilterDto } from 'src/app/api/models/notificationFilterDto';
import { PagedResponse } from 'src/app/models/response.model';
import { NotificationDto } from 'src/app/api/models/notificationDto';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { MatPaginator } from '@angular/material/paginator';
import { UtclubTabInterface } from 'src/app/components/utclub-tab/utclub-tab.component';
import { InquiryFormComponent } from 'src/app/pages/inquiry/inquiry-form/inquiry-form.component';
import { Router } from '@angular/router';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: NotificationDto[];

  trackById = trackById;

  loading: boolean = false;

  pageSize: number = 10;
  page: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private eventBusService: EventBusService,
    private router: Router,
    private popoverRef: PopoverRef<ToolbarNotificationsDropdownComponent>
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    var filterDto: NotificationFilterDto = {
      archived: false,
      pageSize: this.pageSize,
      page: this.page
    };
    this.apiService
      .post<PagedResponse<NotificationDto>>('notification-service/notification/filter', filterDto)
      .subscribe((response) => {
        this.notifications = response.data;
        this.paginator.length = response.itemsCount;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadData();
  }

  notificationClicked(notification: NotificationDto) {
    var attributes = JSON.parse(notification.attributes);
    this.readNotificaiton(notification);
    switch (notification.notificationType) {
      case 'INQUIRY_MATCHES':
        var attritbutes = JSON.parse(notification.attributes);
        var tabInterface: UtclubTabInterface = {
          label: 'Form: ' + attritbutes.FirstName + ' ' + attritbutes.LastName,
          component: InquiryFormComponent,
          data: {
            id: attritbutes.InquiryId
          },
          id: 'inquiry-' + attritbutes.InquiryId,
          singleTab: true
        };
        this.eventBusService.emit(new EventData('tab-added', tabInterface));
        break;
      case 'ENROLLMENT_NOT_DEBITED':
      case 'ENROLLMENT_NOT_VISA_STATUS_SAVED':
        this.router.navigateByUrl('/enrollment/detail/' + attributes.EnrollmentId + '/program');
        break;
      default:
        break;
    }
    this.popoverRef.close();
  }

  readNotificaitons() {
    this.apiService.post('notification-service/notification/set-read', {}).subscribe((response) => {
      this.notifications.forEach((notification: NotificationDto) => {
        var now = DateTime.local();
        notification.readAt = now.toISO();
      });
      this.eventBusService.emit(new EventData('DecreaseUnreadMessages', { count: 0 }));
      this.cdr.markForCheck();
    });
  }
  archiveNotificaitons() {
    this.apiService.post('notification-service/notification/set-archived', {}).subscribe((response) => {
      this.notifications = [];
      this.cdr.markForCheck();
    });
  }
  readNotificaiton(entity: NotificationDto) {
    this.apiService.post('notification-service/notification/set-read/' + entity.id, {}).subscribe((response) => {
      var now = DateTime.local();
      entity.readAt = now.toISO();
      this.eventBusService.emit(new EventData('DecreaseUnreadMessages', { count: -1 }));
      this.cdr.markForCheck();
    });
  }
  archiveNotificaiton(entity: NotificationDto) {
    this.apiService.post('notification-service/notification/set-archived/' + entity.id, {}).subscribe((response) => {
      this.notifications = this.notifications.filter((notification: NotificationDto) => notification.id != entity.id);
      this.cdr.markForCheck();
    });
  }
}
