import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {

  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) {
    if (this.authService.userValue) {
      var user = this.authService.userValue;
      if (activatedRouteSnapshot.data.roles) {
        var hasRole = false;
        if (!hasRole) {
          this.router.navigateByUrl('auth/login');
          return false;
        }
      }
      return true;
    }
    this.router.navigateByUrl('auth/login');
    return false;
  }
}