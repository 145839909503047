import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

export interface SchoolCommissionCreditDto {
  enrollmentId: number;
  enrollmentName: string
  visaStatus: number;
  currencyId: number;
  totalCredit: number;
  totalReceipt: number;
  totalDiff: number;
  transactions: SchoolCommissionCreditTransactionDto[];

  expanded: boolean;
}

export interface SchoolCommissionCreditTransactionDto {
  id: number;
  date: string;
  type: number;
  relatedBranchName: string;
  relatedBranchId: number | null;
  executorUserName: string;
  executorUserId: number;
  accountName: string;
  accountId: number | null;
  currencyId: number;
  amount: number;
  note: string;
}

@Component({
  selector: 'utclub-credit-school-commission',
  templateUrl: './credit-school-commission.component.html',
  styleUrls: ['./credit-school-commission.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class CreditSchoolCommissionComponent {
  formGroup: FormGroup = new FormGroup({
    schoolId: new FormControl(),
    year: new FormControl(new Date().getFullYear())
  });
  searchCtrl = new FormControl();
  @ViewChild('input') input: MatSelect;

  totalCredit: number;
  totalReceipt: number;
  totalDiff: number;

  data: SchoolCommissionCreditDto[];
  loading = false;
  constructor(
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService,
    private snackbar: MatSnackBar
  ) {}

  items: any[];
  loadingItems: boolean = false;
  anyErrors: boolean = false;
  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  _onDestroy = new Subject<void>();
  onSubmit() {
    this.totalCredit = 0;
    this.totalReceipt = 0;
    this.totalDiff = 0;
    this.loading = true;
    this.cdr.detectChanges();
    var url = `accounting-service/credit/school-commission-credits/${this.formGroup.value.schoolId}?year=${this.formGroup.value.year}`;
    this.apiService.get<Response<SchoolCommissionCreditDto[]>>(url).subscribe({
      next: (response) => {
        response.data.forEach((item: SchoolCommissionCreditDto) => {
          this.totalCredit += item.totalCredit;
          this.totalReceipt += item.totalReceipt;
        });
        this.totalDiff = this.totalCredit - this.totalReceipt;

        this.data = response.data;
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.snackbar.open(error.error.message, 'Close', { duration: 5000 });
      }
    });
  }

  ngOnInit(): void {
    this.loadItems();
    this.searchCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterItems();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.searchCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(this.items.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }
  loadItems() {
    this.loadingItems = true;
    this.anyErrors = false;
    var url = 'student-service/school';
    this.apiService.request('get', url).subscribe({
      next: (response: HttpEvent<Response<any>>) => {
        if (response instanceof HttpResponse) {
          var items = response.body.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name
            };
          });
          this.items = items;
          this.filteredItems.next(this.items.slice());
          this.loadingItems = false;
          this.cdr.detectChanges();
        }
      },
      error: (error) => {
        this.anyErrors = true;
        this.loadingItems = false;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingItems = false;
        this.cdr.detectChanges();
      }
    });
  }
}
