import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { share, timer, tap, map, ReplaySubject, pipe, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { CountryDto } from '../api/models/countryDto';
import { Response } from '../models/response.model';
import { HttpClient } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { CityDto } from '../api/models/cityDto';
import { BranchDto } from '../api/models/branchDto';
import { TownDto } from '../api/models/townDto';
import { UserDto } from '../api/models/userDto';
import { RoleDto } from '../api/models/roleDto';
import { StudyAbroadPointDto } from '../api/models/studyAbroadPointDto';
import { StudyAbroadRepresentativeDto } from '../api/models/studyAbroadRepresentativeDto';
import { CurrencyDto } from '../api/models/currencyDto';
import { CorporateInquiryDto } from '../api/models/corporateInquiryDto';
import { WebPortalDto } from '../api/models/webPortalDto';
import { ProgramDto } from '../api/models/programDto';
import { SchoolDto } from '../api/models/schoolDto';
import { SchoolBranchDto } from '../api/models/schoolBranchDto';
import { AccountCaseDto } from '../api/models/accountCaseDto';
import { AccountDto } from '../api/models/accountDto';
import { CompanyContactDto } from '../api/models/companyContactDto';
import { InvoiceInformationDto } from '../api/models/invoiceInformationDto';

export interface ServiceProviderDto {
  id: number;
  name: string;
  serviceProviderType: number;
  note: string;
}

// default cache timeout is 1 hour
export const CACHE_TIMEOUT = 1000 * 60 * 60;

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  constructor(private authService: AuthService) {}

  getLookupObservable<T>(url: string, cacheTimeout: number = CACHE_TIMEOUT, cacheKey: string = null): Observable<T[]> {
    if (cacheKey) {
      var cachedKeyTimeout = localStorage.getItem(cacheKey + '_timeout');
      if (cachedKeyTimeout) {
        var timeout = parseInt(cachedKeyTimeout);
        if (timeout > Date.now()) {
          var cachedData = localStorage.getItem(cacheKey);
          if (cachedData) {
            return new Observable<T[]>((observer) => {
              observer.next(JSON.parse(cachedData));
              observer.complete();
            });
          }
        }
      }
    }

    return ajax<Response<T[]>>({
      url: `${environment.API_URL}api/${url}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.authService.tokenValue}`
      }
    }).pipe(
      tap(() => {}),
      map((response) => {
        if (cacheKey) {
          localStorage.setItem(cacheKey, JSON.stringify(response.response.data));
          localStorage.setItem(cacheKey + '_timeout', (Date.now() + cacheTimeout).toString());
        }
        return response.response.data;
      }),

      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: () => timer(CACHE_TIMEOUT)
      })
    );
  }

  entity: {
    observable: Observable<CountryDto[]>;
  };

  private _countries$: Observable<CountryDto[]>;
  get countries$() {
    if (!this._countries$) {
      this._countries$ = this.getLookupObservable<CountryDto>('location-service/country');
    }
    return this._countries$;
  }

  private _cities: Observable<CityDto[]>;
  get cities$() {
    if (!this._cities) {
      this._cities = this.getLookupObservable<CityDto>('location-service/city');
    }
    return this._cities;
  }

  private _turkeyCities: Observable<CityDto[]>;
  get turkeyCities$() {
    if (!this._turkeyCities) {
      this._turkeyCities = this.getLookupObservable<CityDto>('location-service/city?CountryCode=TUR');
    }
    return this._turkeyCities;
  }

  private _citiesByCountry: { [key: number]: Observable<CityDto[]> } = {};
  getCitiesByCountry$(countryId: number) {
    if (!this._citiesByCountry[countryId]) {
      this._citiesByCountry[countryId] = this.getLookupObservable<CityDto>(
        `location-service/city?CountryId=${countryId}`
      );
    }
    return this._citiesByCountry[countryId];
  }

  private _towns: Observable<TownDto[]>;
  get towns$() {
    if (!this._towns) {
      this._towns = this.getLookupObservable<TownDto>('location-service/town');
    }
    return this._towns;
  }

  private _townsByCity: { [key: number]: Observable<TownDto[]> } = {};
  getTownsByCity$(cityId: number) {
    if (!this._townsByCity[cityId]) {
      this._townsByCity[cityId] = this.getLookupObservable<TownDto>(`location-service/town?CityId=${cityId}`);
    }
    return this._townsByCity[cityId];
  }

  private _branches: Observable<BranchDto[]>;
  get branches$() {
    if (!this._branches) {
      this._branches = this.getLookupObservable<BranchDto>('location-service/branch', CACHE_TIMEOUT, 'branches');
    }
    return this._branches;
  }

  private _users: Observable<UserDto[]>;
  get users$() {
    if (!this._users) {
      this._users = this.getLookupObservable<UserDto>('auth-service/user');
    }
    return this._users;
  }

  private _allUsers: Observable<UserDto[]>;
  get allUsers$() {
    if (!this._allUsers) {
      this._allUsers = this.getLookupObservable<UserDto>('auth-service/user/all');
    }
    return this._allUsers;
  }

  private _consultants: Observable<UserDto[]>;
  get consultants$() {
    if (!this._consultants) {
      this._consultants = this.getLookupObservable<UserDto>('auth-service/user?roleName=Consultant');
    }
    return this._consultants;
  }

  private _supports: Observable<UserDto[]>;
  get supports$() {
    if (!this._supports) {
      this._supports = this.getLookupObservable<UserDto>('auth-service/user?roleName=Support');
    }
    return this._supports;
  }

  private _supervisors: Observable<UserDto[]>;
  get supervisors$() {
    if (!this._supervisors) {
      this._supervisors = this.getLookupObservable<UserDto>('auth-service/user?roleName=Supervisor');
    }
    return this._supervisors;
  }

  private _programManagers: Observable<UserDto[]>;
  get programManagers$() {
    if (!this._programManagers) {
      this._programManagers = this.getLookupObservable<UserDto>('auth-service/user?roleName=ProgramManager');
    }
    return this._programManagers;
  }

  private _visaResponsibles: Observable<UserDto[]>;
  get visaResponsibles$() {
    if (!this._visaResponsibles) {
      this._visaResponsibles = this.getLookupObservable<UserDto>('auth-service/user?roleName=VisaResponsible');
    }
    return this._visaResponsibles;
  }

  private _roles: Observable<RoleDto[]>;
  get roles$() {
    if (!this._roles) {
      this._roles = this.getLookupObservable<RoleDto>('auth-service/role');
    }
    return this._roles;
  }

  private _studyAbroadRepresentatives: Observable<StudyAbroadRepresentativeDto[]>;
  get studyAbroadRepresentatives$() {
    if (!this._studyAbroadRepresentatives) {
      this._studyAbroadRepresentatives = this.getLookupObservable<StudyAbroadPointDto>(
        'accounting-service/studyabroad/representative'
      );
    }
    return this._studyAbroadRepresentatives;
  }

  private _studyAbroadPoints: Observable<StudyAbroadPointDto[]>;
  get studyAbroadPoints$() {
    if (!this._studyAbroadPoints) {
      this._studyAbroadPoints = this.getLookupObservable<StudyAbroadPointDto>('accounting-service/studyabroad/point');
    }
    return this._studyAbroadPoints;
  }

  private _serviceProviders: Observable<ServiceProviderDto[]>;
  get serviceProviders$() {
    if (!this._serviceProviders) {
      this._serviceProviders = this.getLookupObservable<ServiceProviderDto>('accounting-service/serviceprovider');
    }
    return this._serviceProviders;
  }

  // ############# SCHOOLS #############

  private _schools: Observable<SchoolDto[]>;
  get schools$() {
    if (!this._schools) {
      this._schools = this.getLookupObservable<StudyAbroadPointDto>('student-service/school');
    }
    return this._schools;
  }

  private _schoolBranches: Observable<SchoolBranchDto[]>;
  get schoolBranches$() {
    if (!this._schoolBranches) {
      this._schoolBranches = this.getLookupObservable<SchoolBranchDto>('student-service/school-branch');
    }
    return this._schoolBranches;
  }

  private _schoolBranchesBySchool: { [key: number]: Observable<SchoolBranchDto[]> } = {};
  getSchoolBranchesBySchool$(schoolId: number) {
    if (!this._schoolBranchesBySchool[schoolId]) {
      this._schoolBranchesBySchool[schoolId] = this.getLookupObservable<StudyAbroadPointDto>(
        `student-service/school-branch?SchoolId=${schoolId}`
      );
    }
    return this._schoolBranchesBySchool[schoolId];
  }

  private _currencies: Observable<CurrencyDto[]>;
  get currencies$() {
    if (!this._currencies) {
      this._currencies = this.getLookupObservable<CurrencyDto>(
        'accounting-service/currency',
        CACHE_TIMEOUT,
        'currencies'
      );
    }
    return this._currencies;
  }

  private _accounts: Observable<AccountDto[]>;
  get accounts$() {
    if (!this._accounts) {
      this._accounts = this.getLookupObservable<AccountDto>('accounting-service/account');
    }
    return this._accounts;
  }

  private _currencyAccounts: { [key: number]: Observable<AccountDto[]> } = {};
  getAccountsByCurrencyId$(currencyId: number) {
    if (!this._currencyAccounts[currencyId]) {
      this._currencyAccounts[currencyId] = this.getLookupObservable<AccountDto>(
        `accounting-service/account?currencyId=${currencyId}`
      );
    }
    return this._currencyAccounts[currencyId];
  }

  private _accountCaseAccounts: { [key: number]: Observable<AccountDto[]> } = {};
  getAccountsByAccountCaseId$(accountCaseId: number) {
    if (!this._accountCaseAccounts[accountCaseId]) {
      this._accountCaseAccounts[accountCaseId] = this.getLookupObservable<AccountDto>(
        `accounting-service/account?accountCaseId=${accountCaseId}`
      );
    }
    return this._accountCaseAccounts[accountCaseId];
  }

  private _availableTransactionAccounts: Observable<AccountDto[]>;
  get availableTransactionAccounts$() {
    if (!this._availableTransactionAccounts) {
      this._availableTransactionAccounts = this.getLookupObservable<AccountDto>(
        'accounting-service/account/available-transaction-accounts'
      );
    }
    return this._availableTransactionAccounts;
  }

  private _accountCases: Observable<AccountCaseDto[]>;
  get accountCases$() {
    if (!this._accountCases) {
      this._accountCases = this.getLookupObservable<AccountCaseDto>('accounting-service/accountcase');
    }
    return this._accountCases;
  }

  private _corporateInquiries: Observable<CorporateInquiryDto[]>;
  get corporateInquiries$() {
    if (!this._corporateInquiries) {
      this._corporateInquiries = this.getLookupObservable<CorporateInquiryDto>('student-service/corporate-inquiry');
    }
    return this._corporateInquiries;
  }

  private _companyContacts: Observable<CompanyContactDto[]>;
  get companyContacts$() {
    if (!this._companyContacts) {
      this._companyContacts = this.getLookupObservable<CompanyContactDto>('student-service/company-contact/list');
    }
    return this._companyContacts;
  }

  private _webPortals: Observable<WebPortalDto[]>;
  get webPortals$() {
    if (!this._webPortals) {
      this._webPortals = this.getLookupObservable<WebPortalDto>('location-service/web-portal');
    }
    return this._webPortals;
  }

  private _programs: Observable<ProgramDto[]>;
  get programs$() {
    if (!this._programs) {
      this._programs = this.getLookupObservable<ProgramDto>('student-service/program');
    }
    return this._programs;
  }

  private _programsByProgramType: { [key: number]: Observable<ProgramDto[]> } = {};
  getProgramsByProgramType$(programType: number) {
    if (!this._programsByProgramType[programType]) {
      this._programsByProgramType[programType] = this.getLookupObservable<ProgramDto>(
        `student-service/program?ProgramType=${programType}`
      );
    }
    return this._programsByProgramType[programType];
  }

  private _invoiceInformations: Observable<InvoiceInformationDto[]>;
  get invoiceInformations$() {
    if (!this._invoiceInformations) {
      this._invoiceInformations = this.getLookupObservable<InvoiceInformationDto>(
        'accounting-service/invoiceinformation'
      );
    }
    return this._invoiceInformations;
  }
}
