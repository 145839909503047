import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-inquiry-form-change-user',
  template: `
    <utclub-modal title="Kullanici Degistir" [buttons]="buttons">
      <utclub-formly [form]="form" [fields]="fields" [model]="model"></utclub-formly>
    </utclub-modal>
  `
})
export class InquiryFormChangeUserComponent implements OnInit {
  model: any = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'toConsultantId',
      type: 'http-field',
      props: {
        label: 'Kullanici Seciniz',
        http: {
          url: 'auth-service/user?roleName=Consultant'
        },
        labelProp: 'fullName'
      }
    }
  ];
  buttons: UtclubButtonInterface[] = [
    {
      label: 'İptal',
      color: 'warn',
      click: () => {
        this.matDialogRef.close();
      },
      buttonType: 'button'
    },
    {
      label: 'Tamam',
      color: 'primary',
      click: () => {
        this.save();
      },
      buttonType: 'button'
    }
  ];
  invoiceInformation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private eventBusService: EventBusService,
    private matDialogRef: MatDialogRef<InquiryFormChangeUserComponent>,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private lookup: LookupService
  ) {}

  ngOnInit(): void {
    var inquiryIds = this.defaults.inquiryIds ?? [];
    if (this.defaults.inquiryId) {
      inquiryIds.push(this.defaults.inquiryId);
    }
    this.model = {
      inquiryIds: inquiryIds,
      toConsultantId: this.defaults.userId
    };
    this.form.patchValue({
      inquiryIds: inquiryIds,
      toConsultantId: this.defaults.userId
    });
  }

  save() {
    this.apiService.post('student-service/inquiry/change-user', this.model).subscribe(
      (response) => {
        this.matSnackBar.open('Successfully changed user', 'OK', { duration: 3000 });
        this.matDialogRef.close({
          refresh: true
        });
      },
      (error) => {
        this.matSnackBar.open(error.error.message, 'OK', { duration: 3000 });
      }
    );
  }
}
