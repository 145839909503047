import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { InquiryFollowUpDto } from 'src/app/api/models/inquiryFollowUpDto';
import { ApiService } from 'src/app/services/api.service';
import { InquiryFollowUpFormComponent } from './inquiry-follow-up-form/inquiry-follow-up-form.component';
import { Response } from '../../../../models/response.model';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';

@Component({
  selector: 'utclub-inquiry-form-follow-up',
  templateUrl: './inquiry-form-follow-up.component.html',
  styles: [
    `
      .bg-primary-light {
        background-color: rgba(99, 102, 241, 0.1);
      }
    `
  ],
  animations: [fadeInUp400ms, stagger20ms]
})
export class InquiryFormFollowUpComponent implements OnInit {
  @Input() inquiry: InquiryDto;
  @Output() inquiryUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() followUpsListed: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean = false;
  followUps: InquiryFollowUpDto[];

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.inquiry.followUps) {
      this.followUps = this.inquiry.followUps;
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.loading = true;
    this.apiService
      .get<Response<InquiryFollowUpDto[]>>('student-service/inquiry/' + this.inquiry.id + '/follow-ups')
      .subscribe((response) => {
        this.followUps = response.data;
        this.loading = false;
        this.cdr.detectChanges();
        this.followUpsListed.emit();
      });
  }

  openNewFollowUpDialog() {
    // if plannedStartDate is past set it to today
    var plannedStartDate = new Date(this.inquiry.plannedStartDate);
    console.warn(plannedStartDate);
    if (plannedStartDate < new Date()) {
      plannedStartDate = new Date();
    }

    this.dialog
      .open(InquiryFollowUpFormComponent, {
        width: '700px',
        data: {
          formType: 'create',
          inquiryId: this.inquiry.id,
          plannedStartDate: plannedStartDate,
          rating: this.inquiry.rating ?? null
        }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        this.loadData();
        this.inquiryUpdated.emit();
      });
  }
}
