<utclub-modal title="Tahsilat">
  <utclub-form
    *ngIf="formInterface"
    [form]="formInterface"
    [matDialogRef]="matDialogRef"
    (valueChanges)="valueChanges($event)"></utclub-form>
  <mat-progress-bar *ngIf="enrollmentDebitTransactionsLoading" mode="indeterminate" color="primary"></mat-progress-bar>
  <table class="w-full" *ngIf="enrollmentDebitTransactions">
    <thead>
      <tr>
        <th class="p-2">Tarih</th>
        <th class="p-2">Tip</th>
        <th class="p-2">Tutar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let enrollmentTransaction of enrollmentDebitTransactions">
        <td class="p-2">{{ enrollmentTransaction.date | date: 'dd/MM/yyyy' }}</td>
        <td class="p-2">
          <div
            *ngIf="enrollmentTransaction.transactionType == 1"
            class="rounded px-2 text-green bg-green/10"
            style="display: inline-block">
            Tahsilat
          </div>
          <div
            *ngIf="enrollmentTransaction.transactionType == 5"
            class="rounded px-2 text-red bg-red/10"
            style="display: inline-block">
            Borclandirma
          </div>
        </td>
        <td class="p-2">
          {{ enrollmentTransaction.amount | currency: enrollmentTransaction.currencyId | async }}
        </td>
      </tr>
    </tbody>
  </table>
</utclub-modal>
