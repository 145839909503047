import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';

export interface AccountCashDto {
  name: string;
  accounts: any;
}

export interface Currency {
  id: number;
  name: string;
  code: string;
  symbol: string;
  majorSymbol: string;
  majorName: string;
  minorSymbol: string;
  minorName: string;
}

interface AccountCashReportDto {
  currencies: Currency[];
  accounts: AccountCashDto[];
}

@Component({
  selector: 'vex-account-case-report',
  templateUrl: './account-cashes.component.html',
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class AccountCashesComponent implements OnInit {

  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  loading: boolean = false;

  columns: string[] = ['name'];
  currencies: any[] = [];
  currencyTotals = {};

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.currencyTotals = {};
    this.apiService.get<Response<AccountCashReportDto>>('accounting-service/account/account-cashes').subscribe(response => {
      this.loading = false;
     
      response.data.accounts.forEach((item: any) => {
        for (const [key, value] of Object.entries(item.accounts)) {
          if (this.currencyTotals[key]) {
            this.currencyTotals[key] += value;
          } else {
            this.currencyTotals[key] = value;
          }
          if (!this.columns.includes(key)) {
            this.columns.push(key);
            this.currencies.push(key);
          }
        }
      });

      // currencies to toFixed(2)
      this.currencies.forEach(currency => {
        this.currencyTotals[currency] = this.currencyTotals[currency].toFixed(2);
      });

      this.dataSource = new MatTableDataSource(response.data.accounts);
      this.cdr.detectChanges();
    });
  }
  rowClicked(row: any) {
  }
}
