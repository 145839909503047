<form [formGroup]="formGroup">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary" class="mb-2"></mat-progress-bar>

  <p class="text-red mb-2" *ngIf="errorMessage" [innerHtml]="errorMessage"></p>
  <div *ngIf="errors && errors.length > 0" class="mb-2">
    <ul>
      <li *ngFor="let error of errors" [innerHtml]="error.value" class="text-red"></li>
    </ul>
  </div>

  <formly-form [form]="formGroup" [fields]="fields" [model]="model"></formly-form>

  <ng-content></ng-content>

  <div class="flex items-center justify-end">
    <mat-spinner *ngIf="submitLoading" class="mr-2" diameter="30" mode="indeterminate" color="accent"></mat-spinner>
    <button (click)="onSubmit()" mat-raised-button color="primary" [disabled]="submitLoading || notChanged || formGroup.invalid">
      {{ 'actions.save' | translate }}
    </button>
  </div>
</form>
