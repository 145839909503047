<div class="card overflow-auto">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <utclub-button buttonType="button" [label]="'Filtre'" (click)="filterToggle()"
            [icon]="filterOpened ? 'mat:keyboard_arrow_down' : 'mat:keyboard_arrow_right'"></utclub-button>

        <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
            <mat-icon svgIcon="mat:refresh"></mat-icon>
        </button>

        <span class="flex-1"></span>

        <utclub-button *ngFor="let button of actions" [button]="button">
        </utclub-button>
    </div>

    <div *ngIf="filterOpened" @fadeInUp class="p-4">
        <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel">
        </utclub-formly>

        <div>
            <utclub-button buttonType="button" [label]="'Filtrele'" (click)="loadData()" class="mr-2">
            </utclub-button>
            <utclub-button buttonType="button" [label]="'Temizle'" [color]="'warn'" (click)="clearFilter()">
            </utclub-button>
        </div>
    </div>

    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

    <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table matSort
        (matSortChange)="sortChanged($event)" id="excel-table">

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
            <ng-container *ngIf="column.columnType === 'text'" [matColumnDef]="column.property"
                [sticky]="column.sticky">
                <th *matHeaderCellDef mat-header-cell>
                    {{ column.label }}
                </th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                    {{ row[column.property] }}
                </td>
            </ng-container>
            <ng-container *ngIf="column.columnType === 'enum'" [matColumnDef]="column.property">
                <th *matHeaderCellDef mat-header-cell>
                    {{ column.label }}
                </th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                    {{ row[column.property] | enum:column.enum }}
                </td>
            </ng-container>
            <ng-container *ngIf="column.columnType === 'entity'" [matColumnDef]="column.property">
                <th *matHeaderCellDef mat-header-cell>
                    {{ column.label }}
                </th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                    <span *ngIf="row[column.property]">
                        {{ row[column.property] | entity:column.entity | async }}
                    </span>
                </td>
            </ng-container>
            <ng-container *ngIf="column.columnType === 'date'" [matColumnDef]="column.property">
                <th *matHeaderCellDef mat-header-cell>
                    {{ column.label }}
                </th>
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                    <span *ngIf="row[column.property]">
                        {{ row[column.property] | date:'dd-MM-yyyy HH:mm':'+300' }}

                    </span>
                </td>
            </ng-container>
        </ng-container>

        <ng-container [matColumnDef]="'date'">
            <th *matHeaderCellDef mat-header-cell>Tarih</th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                {{ row.date | date:'dd-MM-yyyy' }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="'total'">
            <th *matHeaderCellDef mat-header-cell> Toplam </th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                {{ row.total | currency:row.currencyId | async }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="'invoiceStatus'">
            <th *matHeaderCellDef mat-header-cell>Fatura Durumu</th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                <div *ngIf="row.invoiceStatus == 0" class="rounded px-2 text-amber bg-amber/10"
                    style="display: inline-block;">
                    Odeme Beklemede
                </div>
                <div *ngIf="row.invoiceStatus == 1" class="rounded px-2 text-green bg-green/10"
                    style="display: inline-block;">
                    Odeme Alindi
                </div>
                <div *ngIf="row.invoiceStatus == 2" class="rounded px-2 text-red bg-green/10"
                    style="display: inline-block;">
                    Taslak
                </div>
                <div *ngIf="row.invoiceStatus == 3" class="rounded px-2 text-red bg-green/10"
                    style="display: inline-block;">
                    Batik
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ entity: row }"
                    [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                    <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp class="hover:bg-hover trans-ease-out" mat-row></tr>
    </table>

    <mat-paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="[10, 20, 50, 100]"
        [length]="totalItemCount" (page)="pageChanged($event)" class="sticky left-0">
    </mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-entity="entity" matMenuContent>
        <utclub-button *ngFor="let button of rowActions" [button]="button" [entity]="entity">
        </utclub-button>
    </ng-template>
</mat-menu>