import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { InquiryUtInformationUpdateDto } from 'src/app/api/models/inquiryUtInformationUpdateDto';
import { getStudentSourceFields } from 'src/app/components/utclub-formly/base-student-source-field';
import { LookupService } from 'src/app/services/lookup.service';
import { Enums } from 'src/app/services/enums';

@Component({
  selector: 'utclub-inquiry-form-ut-edit',
  template: `
    <div class="card overflow-auto w-full flex flex-col">
      <div class="flex items-center border-b-2 border-b-primary py-2 px-4">
        <h2 class="m-0 flex-auto">UT</h2>
      </div>

      <div class="p-4">
        <utclub-form *ngIf="formInterface" [form]="formInterface" (success)="success()"></utclub-form>
      </div>
    </div>
  `
})
export class InquiryFormUtEditComponent implements AfterContentInit {
  @Output() inquiryUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() inquiry: InquiryDto;
  formInterface: UtclubFormInterface;

  programTypeField: CustomFormlyFieldConfig = {
    key: 'programType',
    type: 'enum-select',
    props: {
      label: 'Form Tipi',
      required: true,
      enum: Enums.ProgramType
    }
  };
  enrollmentGroupField: CustomFormlyFieldConfig = {
    key: 'enrollmentGroupId',
    type: 'http-field',
    props: {
      label: 'Grup',
      required: true,
      http: {
        url: 'student-service/enrollment-group',
        parentProp: 'programType',
        parentPropQuery: 'programType'
      }
    },
    hideExpression: 'model.programType != 9'
  };
  branchField: CustomFormlyFieldConfig = {
    type: '#branch',
    props: {
      required: true
    }
  };

  @ViewChild(UtclubFormComponent, { static: false }) form: UtclubFormComponent;
  constructor(private lookUpService: LookupService) {}

  ngAfterContentInit(): void {
    var id = this.inquiry.id;
    var model: InquiryUtInformationUpdateDto = {
      branchId: this.inquiry.branchId,
      studentSource: this.inquiry.studentSource,
      referrerId: this.inquiry.referrerId,
      referrerText: this.inquiry.referrerText,
      programType: this.inquiry.programType,
      enrollmentGroupId: this.inquiry.enrollmentGroupId
    };
    var fields: CustomFormlyFieldConfig[] = [this.programTypeField, this.enrollmentGroupField, this.branchField];
    var studentSourceFields = getStudentSourceFields(this.lookUpService).fieldGroup;
    studentSourceFields.forEach((element) => {
      if (element.key == 'studentSource' && this.inquiry.studentSource !== 1) {
        element.props.options = element.props.options.filter((x) => x.value !== 1);
      }
      var field: CustomFormlyFieldConfig = { ...element };
      if (this.inquiry.studentSource == 1) {
        if (!field.props) {
          field.props = {};
        }
        field.props.disabled = true;
      }
      fields.push(field);
    });
    this.formInterface = {
      fields: fields,
      defaultModel: model,
      submitMethod: 'put',
      submitUrl: 'student-service/inquiry/' + id + '/ut-information'
    };
  }

  success() {
    location.reload();
  }
}
