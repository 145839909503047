<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto"> {{title}} </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col {{padding}}">
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="buttons && buttons.length > 0">
  <utclub-button *ngFor="let button of buttons" [button]="button" class="ml-2">
  </utclub-button>
</mat-dialog-actions>