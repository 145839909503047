import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormlyModule } from '@ngx-formly/core';
import { AccessTokenDto } from 'src/app/api/models/accessTokenDto';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'utclub-utclub-change-user-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatDivider,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,

    FormlyModule
  ],
  templateUrl: './utclub-change-user-dialog.component.html',
  styleUrl: './utclub-change-user-dialog.component.scss'
})
export class UtclubChangeUserDialogComponent {
  model: any = {};
  form = new FormGroup({});
  fields = [{ type: '#user' }];

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<UtclubChangeUserDialogComponent>,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  submitLoading = false;
  submit() {
    if (!this.model.userId) {
      this.snackbar.open('Kullanıcı seçiniz', 'Kapat', {
        duration: 2000
      });
      return;
    }
    this.submitLoading = true;
    this.cdr.detectChanges();
    this.apiService.post<Response<AccessTokenDto>>(`auth-service/auth/login-as/${this.model.userId}`, {}).subscribe({
      next: (response: Response<AccessTokenDto>) => {
        if (!response.hasError && response.data.tokenType == 'AccessToken') {
          this.authService.loginAs(response.data);
          location.href = '/';
          this.dialogRef.close();
        } else {
          var errorMessage = response.message ?? 'Bir hata oluştu';
          this.snackbar.open('Bir hata oluştu', 'Kapat', {
            duration: 2000
          });
        }
        this.submitLoading = false;
        this.cdr.detectChanges();
      },
      error: (error) => {
        var errorMessage = error.error.message ?? 'Bir hata oluştu';
        this.snackbar.open(errorMessage, 'OK');
        this.submitLoading = false;
        this.cdr.detectChanges();
      }
    });
  }
}
