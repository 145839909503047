import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { StudentFormComponent } from 'src/app/pages/student/student-form/student-form.component';

@Component({
  selector: 'utclub-inquiry-form-review',
  templateUrl: './inquiry-form-review.component.html',
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms]
})
export class InquiryFormReviewComponent {
  @Input() inquiry: InquiryDto;
  @Output() inquiryUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  openUpdateStudentDialog() {
    this.dialog
      .open(StudentFormComponent, {
        data: { id: this.inquiry.studentId }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        if (updatedEntity.refresh) {
          this.inquiryUpdated.emit();
        }
      });
  }
}
