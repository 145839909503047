import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'utclub-page',
  templateUrl: './utclub-page.component.html'
})
export class UtclubPageComponent implements OnInit {

  @Input() title: string;
  @Input() breadcrumbs: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
