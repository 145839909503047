
import { Pipe, PipeTransform } from '@angular/core';
import { EntityService } from '../services/entity.service';

@Pipe({
  name: 'entity'
})
export class EntityPipe implements PipeTransform {
  constructor(private entityService: EntityService) { }

  async transform(value: number, entityName: string): Promise<string> {
    var entityLabel = await this.entityService.getEntityLabel(entityName, value);
    return entityLabel;
  }
}