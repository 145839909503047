import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'formly-file-field',
  template: `
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label> {{ field.props.label }} </mat-label>
    <input #f_input type="file" [formControl]="control" [formlyAttributes]="field" hidden (change)="handleFileInputChange(f_input.files)" />
    <input [(ngModel)]="fileName" type="text" readonly matInput />
    <mat-icon matSuffix svgIcon="mat:attach_file" (click)="f_input.click()" class="cursor-pointer"></mat-icon>

    <mat-error *ngIf="control.hasError('required')">
      {{ field.props.label }} is required
    </mat-error>
    <mat-error *ngIf="control.hasError('maxFileSize')">
      File size should be less than 10MB
    </mat-error>
  </mat-form-field>

  <a *ngIf="form.value[field.key + 'Path']" [href]="env.CDN_URL + form.value[field.key + 'Path']"
    target="_blank" class="cursor-pointer underline text-blue-500">
    View File
  </a>
  `
})
export class FileFieldComponent extends FieldType<FormlyFieldConfig> implements AfterViewInit {

  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  constructor(
    private matSnackBar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.form.controls[this.field.key + 'Path'] = new FormControl();
    this.form.valueChanges.subscribe((value) => {
      if (value[this.field.key + 'Path']) {
        this.field.props.required = false;
      }
    });
  }
  env: any = environment;
  fileName: string;
  filePath: string;
  get control() {
    return this.formControl as FormControl
  }

  handleFileInputChange(files: FileList): void {
    if (files.length == 1) {
      var file = files[0];
      this.fileName = file.name;
      if (file.size / 1024 / 1024 > 10) {
        this.control.reset();
        this.fileName = null;
        this.control.setValue(null);
        this.matSnackBar.open('File size should be less than 10MB', 'OK', { duration: 3000 });
        return;
      }
    } else if (files.length > 1) {
      this.fileName = files.length + ' files selected';
      // foreach files and check size
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (file.size / 1024 / 1024 > 10) {
          this.control.setErrors({ maxFileSize: true });
          return;
        }
      }
    } else {
      this.fileName = '';
    }
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: 'input[type=file]',
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessor, multi: true }],
})
export class FileValueAccessor implements ControlValueAccessor {
  value: any;
  onChange = (_: any) => {
  }
  onTouched = () => {
  }

  writeValue(_value: any) {

  }
  registerOnChange(fn: any) {
    this.onChange = fn;

  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}