import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Response, PagedResponse } from './../../../../models/response.model';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { EnumsService } from 'src/app/services/enums.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

interface StudentDto {
  id: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'big-radio-field',
  template: `
    <div style="display: flex; justify-content: center;">
      <mat-button-toggle-group
        [formControl]="control"
        [formlyAttributes]="field"
        class="mb-4"
        [hideSingleSelectionIndicator]="true">
        <mat-button-toggle *ngFor="let option of list" [value]="option.value"> {{ option.label }} </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  `,
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms]
})
export class BigRadioField extends FieldType<CustomFormlyFieldConfig> implements OnInit {
  list: any[] = [];

  constructor(
    private apiService: ApiService,
    private enumsService: EnumsService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.field.props.options) {
      this.field.props.valueProp = 'value';
      this.field.props.labelProp = 'label';

      var options = this.field.props.options.map((item: any) => {
        return {
          value: item[this.field.props.valueProp],
          label: item[this.field.props.labelProp]
        };
      });
      if (!this.field.props.required && !this.field.props.multiple) options.unshift({ value: null, label: 'Seciniz' });
      this.field.props.options = options;

      this.list = options;
      this.cdr.detectChanges();
    }

    if (this.field.props.enum) {
      var list = [];
      for (let key in this.field.props.enum.values) {
        list.push({
          value: this.field.props.enum.values[key],
          label: this.translateService.instant(`enum.${this.field.props.enum.key}.${this.field.props.enum.values[key]}`)
        });
      }
      this.field.props.valueProp = 'value';
      this.field.props.labelProp = 'label';
      this.field.props.options = list;
      this.list = list;
    }

    if (this.field.claim) {
      var claims = this.authService.getClaimValues(this.field.claim);
      this.list = this.list.filter((x) => claims.includes(x.value.toString()));
    }

    this.cdr.detectChanges();
  }

  get control() {
    return this.formControl as FormControl;
  }
}
