import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UtclubButtonModule } from '../utclub-button/utclub-button.module';
import { UtclubConfirmDialogModule } from '../utclub-confirm-dialog/utclub-confirm-dialog.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UtclubTableComponent } from './utclub-table.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { UtclubFormlyModule } from '../utclub-formly/utclub-formly.module';



@NgModule({
  declarations: [
    UtclubTableComponent
  ],
  exports: [
    UtclubTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    UtclubButtonModule,
    UtclubConfirmDialogModule,
    UtclubFormlyModule,

    PipesModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressBarModule,
    MatCheckboxModule
  ]
})
export class UtclubTableModule { }
