import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response, PagedResponse } from '../models/response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  request<TResponse = Response | Response<any> | PagedResponse<any>>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    body?: any,
    options?: any
  ): Observable<HttpEvent<TResponse>> {
    var httpReq = new HttpRequest(method, `${environment.API_URL}api/${url}`, body, options);
    return this.httpClient.request<TResponse>(httpReq);
  }

  get<TResponse = Response | Response<any> | PagedResponse<any>>(url: string): Observable<TResponse> {
    return this.httpClient.get<TResponse>(`${environment.API_URL}api/${url}`);
  }

  post<TResponse = Response | Response<any> | PagedResponse<any>>(
    url: string,
    body: any,
    options = {}
  ): Observable<TResponse> {
    return this.httpClient.post<TResponse>(`${environment.API_URL}api/${url}`, body, options);
  }

  put<TResponse = Response | Response<any> | PagedResponse<any>>(url: string, body: any): Observable<TResponse> {
    return this.httpClient.put<TResponse>(`${environment.API_URL}api/${url}`, body);
  }

  delete(url: string) {
    return this.httpClient.delete<Response>(`${environment.API_URL}api/${url}`);
  }

  getHttpClient(): HttpClient {
    return this.httpClient;
  }
}
