import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading, NavigationTab } from '../interfaces/navigation-item.interface';
import { Observable, Subject, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: NavigationItem[] = [];
  navigationItems: Subject<NavigationItem[]> = new Subject<NavigationItem[]>();


  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor(
    private authService: AuthService
  ) { }

  setItems(items: NavigationItem[]) {
    var filteredItems: NavigationItem[] = [];

    if (this.authService.userValue) {
      var roles = this.authService.userValue.roles;
      filteredItems = items.filter(item => {
        if (item.children) {
          var filteredChildrens = item.children.filter(child => {
            var canAccess = true;

            if (child.roles) {
              canAccess = child.roles.some(role => roles.includes(role));
            }
            if (child.claims) {
              for (const claim of child.claims) {
                if (typeof claim === 'string') {
                  canAccess = this.authService.hasClaim(claim);
                } else {
                  canAccess = this.authService.hasClaimWithValue(claim.key, claim.value)
                }
              }
            }
            return canAccess;
          });
          item.children = filteredChildrens;
        }
        var canAccess = true;
        if (item.roles) {
          canAccess = item.roles.some(role => roles.includes(role));
        }
        if (item.claims) {
          for (const claim of item.claims) {
            if (typeof claim === 'string') {
              canAccess = this.authService.hasClaim(claim);
            } else {
              canAccess = this.authService.hasClaimWithValue(claim.key, claim.value)
            }
          }
        }
        return canAccess;
      });
    }

    this.items = filteredItems;
    this.navigationItems.next(filteredItems);
  }


  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  isTab(item: NavigationItem): item is NavigationTab {
    return item.type === 'tab';
  }
}
