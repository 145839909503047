import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nameFirstLetters"
})
export class NameFirstLettersPipe implements PipeTransform {
  transform(fullName: string): any {
    if (!fullName) {
      return "";
    }
    var nameParts = fullName.split(" ");
    if (nameParts.length == 1) {
      return nameParts[0][0];
    }
    if (nameParts.length > 2) {
      return nameParts[0][0] + nameParts[nameParts.length - 1][0];
    }
    return nameParts[0][0] + nameParts[1][0];
  }
}