import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticatorSetupDto } from 'src/app/api/models/authenticatorSetupDto';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'utclub-two-factor-authentication-setup',
  templateUrl: './two-factor-authentication-setup.component.html',
  styleUrls: ['./two-factor-authentication-setup.component.scss']
})
export class TwoFactorAuthenticationSetupComponent implements OnInit {
  is2faEnabled: boolean = false;
  loading: boolean = false;

  authenticatorSetupDto: AuthenticatorSetupDto;
  authenticatorSetupLoading: boolean = false;

  authenticatorVerifyLoading: boolean = false;

  form: UntypedFormGroup;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      code: ['']
    });
    this.loading = true;
    this.apiService.get<Response<boolean>>('auth-service/user/is-2fa-enabled').subscribe((res: any) => {
      this.is2faEnabled = res.data;
      if (!this.is2faEnabled) this.loadAuthenticatorSetup();
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  isAuthenticatorSetupLoaded = false;
  loadAuthenticatorSetup() {
    if (this.isAuthenticatorSetupLoaded) return;
    this.apiService.get<Response<AuthenticatorSetupDto>>('auth-service/authenticator/setup').subscribe((res: any) => {
      this.authenticatorSetupDto = res.data;
      this.authenticatorSetupLoading = false;
      this.cdr.detectChanges();
    });
  }

  authenticatorVerify(enabled) {
    this.authenticatorVerifyLoading = true;
    this.apiService.post<Response>('auth-service/authenticator/verify', {
      code: this.form.value.code,
      enabled: enabled
    }).subscribe((res: any) => {
      this.authenticatorVerifyLoading = false;
      this.is2faEnabled = enabled;
      if (!enabled) this.loadAuthenticatorSetup();
      this.cdr.detectChanges();
    });
  }
}
