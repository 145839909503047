import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

const CACHE_TIME = 1000 * 60 * 60;
const CACHE_TIME_KEY = 'enums-cache-time';
const CACHE_KEY = 'enums-cache';
const ENUM_CACHE_VERSION = 'enums-cache-version';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  cachedEnums: { key: string, value: { key: number, value: string }[] }[];
  cachedTime: Date;
  appVersion: string;

  constructor(private apiService: ApiService) {
    this.appVersion = require('../../../package.json').version;
    var cachedVersion = localStorage.getItem(ENUM_CACHE_VERSION);
    if (cachedVersion != this.appVersion) {
      localStorage.removeItem(CACHE_TIME_KEY);
      localStorage.removeItem(CACHE_KEY);
      localStorage.setItem(ENUM_CACHE_VERSION, this.appVersion);
    }

    var cachedEnumTime = localStorage.getItem(CACHE_TIME_KEY);
    if (!cachedEnumTime) {
      this.loadEnums();
      return;
    }

    if (new Date().getTime() > parseInt(cachedEnumTime)) {
      this.loadEnums();
      return;
    }

    var cachedEnums = localStorage.getItem(CACHE_KEY);
    if (cachedEnums) {
      this.cachedEnums = JSON.parse(cachedEnums);
    } else {
      this.loadEnums();
    }
  }

  sub: Observable<any>;
  async loadEnums() {
    // if (this.cachedTime && new Date().getTime() - this.cachedTime.getTime() < CACHE_TIME) {
    //   return;
    // }

    // if (!this.sub) {
    //   this.sub = this.apiService.get('variables/enums').pipe(take(1));
    // }

    // this.cachedEnums = result.data;
    // var cacheTime = new Date().getTime() + CACHE_TIME;
    // localStorage.setItem(CACHE_KEY, JSON.stringify(this.cachedEnums));
    // localStorage.setItem(CACHE_TIME_KEY, cacheTime.toString());

    return this.cachedEnums;
  }

  async getEnum(enumName: string): Promise<{ key: number, value: string }[]> {
    var cachedEnum = this.cachedEnums.find(x => x.key == enumName);
    if (!cachedEnum) {
      await this.loadEnums();
      cachedEnum = this.cachedEnums.find(x => x.key == enumName);
      if (!cachedEnum) {
        throw new Error(`Enum ${enumName} not found`);
      }
    }
    return cachedEnum.value;
  }

  async getEnumValue(enumName: string, key: number): Promise<string> {
    var enumValues = await this.getEnum(enumName);
    var enumValue = enumValues.find(x => x.key == key);
    if (!enumValue) {
      await this.loadEnums();
      enumValue = enumValues.find(x => x.key == key);
      if (!enumValue) {
        throw new Error(`Enum ${enumName} with key ${key} not found`);
      }
    }
    return enumValue.value;
  }
}
