import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { EnrollmentStatus } from 'src/app/api/models/enrollmentStatus';
import { StudentDto } from 'src/app/api/models/studentDto';
import { Student } from 'src/app/models/student.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'utclub-student',
  templateUrl: './utclub-student.component.html',
  styleUrls: ['./utclub-student.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms]
})
export class UtclubStudentComponent {
  @Input() student: StudentDto | Student;
  @Input() entityId: number;
  @Input() enrollmentStatus: EnrollmentStatus;
  @Input() programType: number;

  env: any;
  constructor(private matSnackBar: MatSnackBar) {
    this.env = environment;
  }

  copyToClipboard() {
    this.matSnackBar.open('Kopyalandı', 'OK', {
      duration: 2000
    });
  }
}
