import { AfterContentInit, AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { DebitFormComponent } from '../../debit/debit-form/debit-form.component';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';
import { Enums } from 'src/app/services/enums';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'utclub-credit-form',
  templateUrl: './credit-form.component.html',
  styleUrls: ['./credit-form.component.scss']
})
export class CreditFormComponent implements AfterContentInit, OnDestroy, AfterViewInit {
  enrollmentSubs: Subscription;
  schoolId: number;

  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<DebitFormComponent>,
    private eventBusService: EventBusService,
    private lookup: LookupService,
    private authService: AuthService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: [
        {
          key: 'creditType',
          type: 'select',
          props: {
            label: 'İslem Tipi',
            required: true,
            options: [
              { value: 1, label: 'Servis Saglayici | Eğitim' },
              { value: 2, label: 'Servis Saglayici | Operasyon' },
              { value: 3, label: 'Okul Komisyonu' },
              { value: 4, label: 'Program Ücreti' }
            ]
          }
        },
        {
          key: 'date',
          type: 'datepicker',
          defaultValue: new Date(),
          props: {
            label: 'Tarih',
            placeholder: '',
            required: false
          },
          expressions: {
            hide: (field: CustomFormlyFieldConfig) => {
              return !this.authService.isInRole('Manager') || !field.model.creditType;
            }
          }
        },
        //Student
        {
          key: 'enrollmentId',
          type: 'enrollment-field',
          props: {
            label: 'Ogrenci',
            placeholder: ''
          },
          expressions: { hide: '!model.creditType' }
        },
        // Related Branch
        {
          key: 'relatedBranchId',
          type: '#branch',
          expressions: {
            hide: 'model.creditType == null'
          }
        },
        // Service Provider
        {
          type: '#serviceProvider',
          props: {
            required: true
          },
          expressions: {
            hide: 'model.creditType != 1 && model.creditType != 2'
          }
        },
        // Service Provider Operation Type
        {
          key: 'creditTypeServiceProviderOperation',
          type: 'enum-select',
          props: {
            label: 'Alacak Turu',
            required: true,
            enum: Enums.CreditTypeServiceProviderOperation
          },
          expressions: {
            hide: 'model.creditType != 2'
          }
        },
        // School
        {
          key: 'schoolId',
          type: 'http-field',
          props: {
            label: 'Okul',
            http: {
              url: 'student-service/school'
            }
          },
          expressions: {
            hide: 'model.creditType != 3'
          }
        },
        // Amount
        {
          key: 'amount',
          type: 'input',
          props: {
            label: 'Tutar',
            required: true
          },
          expressions: {
            hide: 'model.creditType == null'
          }
        },
        // Currency
        {
          type: '#currency',
          props: {
            required: true
          },
          expressions: {
            hide: 'model.creditType == null'
          }
        },
        // Note
        {
          key: 'note',
          type: 'textarea',
          props: {
            label: 'Not',
            placeholder: '',
            required: false,
            rows: 3
          },
          expressions: {
            hide: 'model.creditType == null'
          }
        }
      ],
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/credit' : 'accounting-service/credit/' + id,
      loadDataUrl: !id ? null : 'accounting-service/credit/' + id
    };

    this.enrollmentSubs = this.eventBusService.on('enrollment-loaded', (data) => {
      this.schoolId = data.schoolId;
      this.form.model.schoolId = this.schoolId;
      this.form.model.relatedBranchId = data.branchId;
      this.form.formGroup.patchValue({ schoolId: this.schoolId, relatedBranchId: data.branchId });
    });
  }

  ngAfterViewInit(): void {
    this.form.formGroup;
  }

  ngOnDestroy(): void {
    this.enrollmentSubs.unsubscribe();
  }
}
