<div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">
  <div class="p-gutter flex-auto max-w-[400px]">
    <div class="mt-4 grid grid-cols-3">
      <a
        [routerLink]="feature.url"
        *ngFor="let feature of features"
        class="text-dark p-3 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded block no-underline">
        <mat-icon [svgIcon]="feature.icon" class="icon-xl" color="primary"></mat-icon>
        <div class="body-2 mt-2">{{ feature.label }}</div>
      </a>
    </div>
  </div>
</div>
