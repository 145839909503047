import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Enums } from 'src/app/services/enums';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-student-form',
  template: `
    <utclub-modal title="Student">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"> </utclub-form>
    </utclub-modal>
  `
})
export class StudentFormComponent implements AfterContentInit {
  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-4 gap-y-0',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'Ad',
            required: true
          }
        },
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Soyad',
            required: true
          }
        },
        {
          key: 'gender',
          type: 'select',
          props: {
            label: 'Cinsiyet',
            required: true,
            options: [
              { label: 'Erkek', value: 1 },
              { label: 'Kadin', value: 2 }
            ]
          }
        },
        {
          key: 'birthDate',
          type: 'datepicker',
          props: {
            required: true,
            label: 'Birth Date'
          }
        },
        {
          key: 'email',
          type: 'input',
          props: {
            label: 'Eposta',
            required: true
          },
          validators: {
            validation: ['email']
          }
        },
        {
          key: 'phoneNumber',
          type: 'input',
          props: {
            label: 'Telefon Numarasi',
            required: true,
            minLength: 10,
            maxLength: 12
          }
        },
        {
          key: 'photoFile',
          type: 'filepicker',
          props: {
            label: 'Photo'
          }
        },
        {
          type: '#turkeyCity',
          props: {
            required: true
          }
        },
        {
          key: 'identityNumber',
          type: 'input',
          props: {
            label: 'Kimlik Numarası',
            required: true,
            minLength: 11,
            maxLength: 11
          }
        },
        {
          key: 'educationLevel',
          type: 'enum-select',
          props: {
            label: 'Eğitim Seviyesi',
            required: true,
            enum: Enums.EducationLevel
          }
        },
        {
          key: 'lastSchool',
          type: 'input',
          props: {
            label: 'Eğitim Aldigi Son Okul'
          }
        },
        {
          key: 'address',
          type: 'input',
          props: {
            label: 'Adres',
            required: true
          }
        }
      ]
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<StudentFormComponent>,
    private lookUpService: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: this.fields,
      submitMethod: 'put',
      submitUrl: 'student-service/student/' + id,
      loadDataUrl: 'student-service/student/' + id,
      submitType: 'formdata'
    };
  }
}
