import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InquiryFormFollowUpComponent } from './inquiry-form-follow-up.component';
import { InquiryFollowUpFormComponent } from './inquiry-follow-up-form/inquiry-follow-up-form.component';
import { InquiryFollowUpFormModule } from './inquiry-follow-up-form/inquiry-follow-up-form.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ScrollbarModule } from 'src/@vex/components/scrollbar/scrollbar.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [
    InquiryFormFollowUpComponent
  ],
  exports: [
    InquiryFormFollowUpComponent
  ],
  imports: [
    CommonModule,
    InquiryFollowUpFormModule,

    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,

    ScrollbarModule,

    PipesModule
  ]
})
export class InquiryFormFollowUpModule { }
