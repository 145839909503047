import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './guards/auth.guards';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'accounting',
        loadChildren: () => import('./pages/accounting/accounting.module').then((m) => m.AccountingModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'location',
        loadChildren: () => import('./pages/location/location.module').then((m) => m.LocationModule)
      },
      {
        path: 'identity',
        loadChildren: () => import('./pages/identity/identity.module').then((m) => m.IdentityModule)
      },
      {
        path: 'premium',
        loadChildren: () => import('./pages/premium/premium.module').then((m) => m.PremiumModule)
      },
      {
        path: 'enrollment',
        loadChildren: () => import('./pages/enrollment/enrollment.module').then((m) => m.EnrollmentModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'inquiry',
        loadChildren: () => import('./pages/inquiry/inquiry.module').then((m) => m.InquiryModule)
      },
      {
        path: 'corporate-inquiry',
        loadChildren: () =>
          import('./pages/corporate-inquiry/corporate-inquiry.module').then((m) => m.CorporateInquiryModule)
      },
      {
        path: 'schools',
        loadChildren: () => import('./pages/schools/schools.module').then((m) => m.SchoolsModule)
      },
      {
        path: 'system',
        loadChildren: () => import('./pages/system/system.module').then((m) => m.SystemModule)
      },
      {
        path: 'education',
        loadChildren: () => import('./pages/education/education.module').then((m) => m.EducationModule)
      }
    ]
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
