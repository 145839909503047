import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { ReceiptFormComponent } from '../receipt/receipt-form/receipt-form.component';
import { PaymentFormComponent } from '../payment/payment-form/payment-form.component';
import { ExchangeFormComponent } from '../exchange/exchange-form/exchange-form.component';
import { TransferFormComponent } from '../transfer/transfer-form/transfer-form.component';
import { ApiService } from 'src/app/services/api.service';
import { PagedResponse } from 'src/app/models/response.model';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { DebitFormComponent } from '../debit/debit-form/debit-form.component';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { TransactionDto } from 'src/app/api/models/transactionDto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtclubConfirmDialogService } from 'src/app/components/utclub-confirm-dialog/utclub-confirm-dialog.service';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { Enums } from 'src/app/services/enums';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
  styles: [
    `
      table {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    `
  ]
})
export class DailyComponent implements OnInit, AfterViewInit, AfterContentInit {
  actions: UtclubButtonInterface[] = [];
  rowActions: UtclubButtonInterface[] = [];

  transactions: TransactionDto[];

  loading: boolean = false;
  pageSize: number = 50;
  page: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  preDateControl: FormControl = new FormControl();

  idColumn: TableColumn = { label: 'İslem No', property: 'id', type: 'text', visible: false };
  dateColumn: TableColumn = { label: 'Tarih', property: 'date', type: 'text', visible: true };
  officeColumn: TableColumn = { label: 'Ofis', property: 'office', type: 'text', visible: true };
  transactionColumn: TableColumn = { label: 'İslem', property: 'transaction', type: 'text', visible: true };
  detailColumn: TableColumn = { label: 'Detay', property: 'detail', type: 'text', visible: true };
  personColumn: TableColumn = { label: 'Kisi / Kurum', property: 'person', type: 'text', visible: true };
  accountColumn: TableColumn = { label: 'Hesap', property: 'account', type: 'text', visible: true };
  receiptColumn: TableColumn = { label: 'Tahsilat', property: 'receipt', type: 'text', visible: true };
  paymentColumn: TableColumn = { label: 'Odeme', property: 'payment', type: 'text', visible: true };
  noteColumn: TableColumn = { label: 'Not', property: 'note', type: 'text', visible: false };
  actionsColumn: TableColumn = { label: '', property: 'actions', type: 'text', visible: true };
  columns: TableColumn[] = [
    this.idColumn,
    this.dateColumn,
    this.officeColumn,
    this.transactionColumn,
    this.detailColumn,
    this.personColumn,
    this.accountColumn,
    this.receiptColumn,
    this.paymentColumn,
    this.noteColumn,
    this.actionsColumn
  ];

  isManager = false;
  isSuperManager = false;

  date = new Date().getTimezoneOffset();

  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private utclubConfirmDialogService: UtclubConfirmDialogService,
    private lookupService: LookupService
  ) {}

  ngAfterContentInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.isManager = this.authService.isInRole('Manager');
    this.isSuperManager = this.authService.isInRole('SuperManager');
    this.preDateControl.valueChanges.subscribe((value) => {
      switch (value) {
        case 1: //today
          var curr = new Date(); // get current date
          this.filterModel.startDate = this.datePipe.transform(curr, 'yyyy-MM-dd');
          this.filterModel.endDate = this.datePipe.transform(curr, 'yyyy-MM-dd');
          this.filterForm.patchValue({
            startDate: this.filterModel.startDate,
            endDate: this.filterModel.endDate
          });
          break;
        case 2:
          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          var last = first + 7; // last day is the first day + 6
          // 2023-05-03T21:00:00.000Z
          this.filterModel.startDate = this.datePipe.transform(new Date(curr.setDate(first)), 'yyyy-MM-dd');
          this.filterModel.endDate = this.datePipe.transform(new Date(curr.setDate(last)), 'yyyy-MM-dd');
          this.filterForm.patchValue({
            startDate: this.filterModel.startDate,
            endDate: this.filterModel.endDate
          });
          break;
        case 3: //this month
          var now = new Date(); // get current date
          var startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          this.filterModel.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
          this.filterModel.endDate = this.datePipe.transform(
            new Date(startDate.getFullYear(), now.getMonth() + 1, 0),
            'yyyy-MM-dd'
          );
          this.filterForm.patchValue({
            startDate: this.filterModel.startDate,
            endDate: this.filterModel.endDate
          });
          break;
        default:
          break;
      }
      this.loadData();
    });

    this.rowActions.push({
      label: 'Duzenle',
      icon: 'mat:edit',
      click: (row: any) => {
        var model = { formType: 'update', id: null };
        var date = new Date(row.date);
        var today = new Date();
        if (today.toDateString() !== date.toDateString()) {
          if (!this.authService.hasClaim('CanEditTransactions')) {
            this.matSnackBar.open('Eski islemleri duzenleme yetkiniz yok', 'OK');
            return;
          }
        }

        var component;
        switch (row.transactionType) {
          case 1:
            component = ReceiptFormComponent;
            model.id = row.receiptId;
            break;
          case 2:
            component = PaymentFormComponent;
            model.id = row.paymentId;
            break;
          case 3:
            component = ExchangeFormComponent;
            model.id = row.exchangeId;
            break;
          case 4:
            component = TransferFormComponent;
            model.id = row.transferId;
            break;
          case 5:
            component = DebitFormComponent;
            model.id = row.debitId;
            break;
          default:
            break;
        }
        this.matDialog
          .open(component, {
            width: '700px',
            data: model
          })
          .afterClosed()
          .subscribe((updatedEntity) => {
            if (updatedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      },
      buttonType: 'menu-item'
    });
    this.rowActions.push({
      label: 'Yazdir',
      icon: 'mat:print',
      click: (row: any) => {
        switch (row.transactionType) {
          case 1:
            window.open(environment.API_URL + 'api/print-service/transaction/receipt/' + row.id + '/html', '_blank');
            break;
          default:
            this.matSnackBar.open('Yazdirma islemi sadece gelirler icin mevcuttur', 'OK');
            break;
        }
      },
      buttonType: 'menu-item'
    });
    if (this.isManager) {
      this.rowActions.push({
        label: 'Sil',
        icon: 'mat:delete',
        click: (row: any) => {
          var date = new Date(row.date);
          var today = new Date();
          if (today.toDateString() !== date.toDateString()) {
            if (!this.authService.hasClaim('CanEditTransactions')) {
              this.matSnackBar.open('Eski islemleri silme yetkiniz yok', 'OK');
              return;
            }
          }
          var deleteUrl;
          switch (row.transactionType) {
            case 1:
              deleteUrl = `accounting-service/receipt/${row.receiptId}`;
              break;
            case 2:
              deleteUrl = `accounting-service/payment/${row.paymentId}`;
              break;
            case 3:
              deleteUrl = `accounting-service/exchange/${row.exchangeId}`;
              break;
            case 4:
              deleteUrl = `accounting-service/transfer/${row.transferId}`;
              break;
            default:
              break;
          }
          this.utclubConfirmDialogService.deleteConfirmDialog(deleteUrl, () => {
            this.loadData();
          });
        },
        buttonType: 'menu-item'
      });
    }

    this.actions.push({
      tooltip: 'Tahsilat',
      icon: 'mat:add',
      buttonType: 'mini-fab',
      click: () => {
        this.matDialog
          .open(ReceiptFormComponent, {
            width: '500px',
            height: 'auto',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    });

    this.actions.push({
      tooltip: 'Odeme',
      icon: 'mat:remove',
      buttonType: 'mini-fab',
      cssClass: 'ml-2',
      click: () => {
        this.matDialog
          .open(PaymentFormComponent, {
            width: '500px',
            height: 'auto',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    });

    this.actions.push({
      tooltip: 'Doviz',
      icon: 'mat:currency_exchange',
      cssClass: 'ml-2',
      buttonType: 'mini-fab',
      click: () => {
        this.matDialog
          .open(ExchangeFormComponent, {
            width: '500px',
            height: 'auto',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    });

    this.actions.push({
      tooltip: 'Transfer',
      icon: 'mat:sync',
      buttonType: 'mini-fab',
      cssClass: 'ml-2',
      click: () => {
        this.matDialog
          .open(TransferFormComponent, {
            width: '500px',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    });

    this.actions.push({
      tooltip: 'Borclandirma',
      icon: 'mat:money_off',
      buttonType: 'mini-fab',
      cssClass: 'ml-2',
      click: () => {
        this.matDialog
          .open(DebitFormComponent, {
            width: '500px',
            height: 'auto',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    });

    if (this.isManager) {
      this.actions.push({
        tooltip: 'Excel',
        icon: 'mat:file_download',
        buttonType: 'mini-fab',
        cssClass: 'ml-2',
        click: () => {
          this.excel();
        }
      });
    }
  }

  filter() {
    this.page = 0;
    this.loadData();
  }
  loadData() {
    this.apiService
      .post<PagedResponse<TransactionDto>>('accounting-service/transaction/filter', {
        ...this.filterModel,
        pageSize: this.pageSize,
        page: this.page
      })
      .subscribe((response) => {
        this.transactions = response.data;
        this.paginator.length = response.itemsCount;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
  excel() {
    this.apiService
      .post<PagedResponse<TransactionDto>>(
        'accounting-service/transaction/filter/excel',
        {
          ...this.filterModel,
          paging: false
        },
        { responseType: 'blob' }
      )
      .subscribe((response) => {
        FileSaver.saveAs(response, 'transactions.xlsx');
      });
  }

  rowClicked(row) {}
  sortChanged(event) {}
  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadData();
  }
  get visibleColumns() {
    return this.columns.filter((column) => column.visible);
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  openHistory(transactionId: number) {
    this.matDialog.open(TransactionHistoryComponent, {
      width: '500px',
      data: {
        transactionId
      }
    });
  }

  filterOpened: boolean = false;
  filterModel: any = {};
  filterForm = new FormGroup({});
  filterFields: CustomFormlyFieldConfig[] = null;
  filterToggle() {
    if (!this.filterFields) {
      this.filterFields = [
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'input',
              key: 'transactionId',
              props: {
                label: 'Islem No',
                placeholder: '',
                description: 'Coklu islem icin virgul ile ayiriniz.'
              }
            },
            {
              type: 'input',
              key: 'enrollmentId',
              props: {
                label: 'Ogrenci No',
                placeholder: '',
                description: 'Coklu islem icin virgul ile ayiriniz.'
              }
            },
            {
              type: 'datepicker',
              key: 'startDate',
              props: {
                label: 'Baslangic Tarihi',
                placeholder: ''
              }
            },
            {
              type: 'datepicker',
              key: 'endDate',
              props: {
                label: 'Bitis Tarihi',
                placeholder: ''
              }
            },
            {
              type: '#branch',
              key: 'relatedBranchIds',
              props: {
                multiple: true
              }
            },
            {
              type: '#user',
              key: 'executorUserIds',
              props: {
                label: 'İslemi Yapan',
                multiple: true
              }
            },
            {
              key: 'accountIds',
              type: '#availableTransactionAccountField',
              props: {
                label: 'Hesap',
                multiple: true
              }
            },
            {
              type: '#currency'
            }
          ]
        },
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'enum-select',
              key: 'transactionTypes',
              props: {
                label: 'İslem Tipi',
                placeholder: 'İslem tipi seciniz',
                multiple: true,
                enum: Enums.TransactionType
              }
            },
            {
              key: 'person',
              type: 'input',
              props: {
                label: 'Kisi',
                placeholder: ''
              }
            },
            {
              key: 'amount',
              type: 'input',
              props: {
                label: 'Tutar',
                placeholder: ''
              }
            },
            {
              key: 'note',
              type: 'input',
              props: {
                label: 'Not',
                placeholder: ''
              }
            }
          ]
        },
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          expressions: {
            hide: (model: any) => {
              return !this.filterModel.transactionTypes?.includes(1);
            }
          },
          props: { label: 'Tahsilat' },
          fieldGroup: [
            {
              type: 'enum-select',
              key: 'receiptTypes',
              props: {
                label: 'Tahsilat Tipi',
                placeholder: 'Tahsilat tipi seciniz',
                multiple: true,
                enum: Enums.ReceiptType
              },
              expressions: {
                hide: (model: any) => {
                  return !this.filterModel.transactionTypes?.includes(1);
                }
              }
            },
            {
              key: 'receiptTypeEducationDepartments',
              type: 'enum-select',
              hide: false,
              props: {
                label: 'Department',
                placeholder: '',
                multiple: true,
                enum: Enums.ReceiptTypeEducationDepartments
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(1) && this.filterModel.receiptTypes?.includes(1)
                  );
                }
              }
            },
            {
              key: 'receiptTypeEducationReceiptUnits',
              type: 'enum-select',
              hide: false,
              props: {
                label: 'Gelir Kalemleri',
                placeholder: '',
                multiple: true,
                enum: Enums.ReceiptTypeEducationReceiptUnits
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(1) && this.filterModel.receiptTypes?.includes(1)
                  );
                }
              }
            },
            {
              key: 'receiptTypeOperationReceiptUnits',
              type: 'enum-select',
              hide: true,
              props: {
                label: 'Gelir Kalemleri',
                placeholder: '',
                multiple: true,
                enum: Enums.ReceiptTypeOperationReceiptUnits
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(1) && this.filterModel.receiptTypes?.includes(2)
                  );
                }
              }
            }
          ]
        },
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          expressions: {
            hide: (model: any) => {
              return !this.filterModel.transactionTypes?.includes(2);
            }
          },
          fieldGroup: [
            {
              type: 'enum-select',
              key: 'paymentTypes',
              props: {
                label: 'Odeme Tipi',
                placeholder: 'Odeme tipi seciniz',
                multiple: true,
                enum: Enums.PaymentType
              },
              expressions: {
                hide: (model: any) => {
                  return !this.filterModel.transactionTypes?.includes(2);
                }
              }
            },
            //Education
            {
              key: 'paymentTypeEducationDepartments',
              type: 'enum-select',
              hide: false,
              props: {
                label: 'Department',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypeEducationDepartment
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(1)
                  );
                }
              }
            },
            {
              key: 'paymentTypeEducationPaymentUnits',
              type: 'enum-select',
              hide: false,
              props: {
                label: 'Gider Kalemleri',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypeEducationPaymentUnit
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(1)
                  );
                }
              }
            },
            //Operation
            {
              key: 'paymentTypeOperationPaymentUnits',
              type: 'enum-select',
              hide: true,
              props: {
                label: 'Gider Kalemleri',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypeOperationPaymentUnit
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(2)
                  );
                }
              }
            },
            //Staff
            {
              key: 'paymentTypeStaffPaymentUnits',
              type: 'enum-select',
              hide: true,
              props: {
                label: 'Gider Kalemleri',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypeStaffPaymentUnit
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(3)
                  );
                }
              }
            },
            //YEN
            {
              key: 'paymentTypeYENDepartments',
              type: 'enum-select',
              hide: true,
              props: {
                label: 'YEN Departmani',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypeYENDepartment
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(4)
                  );
                }
              }
            },

            {
              key: 'studyAbroadPointIds',
              type: '#studyAbroadPoint',
              hide: false,
              props: {
                required: true,
                multiple: true
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) &&
                    this.filterModel.paymentTypes?.includes(4) &&
                    this.filterModel.paymentTypeYENDepartments?.includes(2)
                  );
                }
              }
            },
            {
              key: 'studyAbroadRepresentativeIds',
              type: '#studyAbroadRepresentative',
              hide: false,
              props: {
                required: true,
                multiple: true
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) &&
                    this.filterModel.paymentTypes?.includes(4) &&
                    this.filterModel.paymentTypeYENDepartments?.includes(1)
                  );
                }
              }
            },

            //Partnership
            {
              key: 'paymentTypePartnerships',
              type: 'enum-select',
              hide: true,
              props: {
                label: 'Partner',
                placeholder: '',
                multiple: true,
                enum: Enums.PaymentTypePartnership
              },
              expressions: {
                hide: (model: any) => {
                  return !(
                    this.filterModel.transactionTypes?.includes(2) && this.filterModel.paymentTypes?.includes(5)
                  );
                }
              }
            }
          ]
        }
      ];
    }
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    var s: number[] = [];
    s;
    s.filter;
    this.loadData();
  }
}
