import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';

@Component({
  selector: 'formly-custom-select',
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>
      <input
        [matDatepicker]="datepickerRef"
        [matDatepickerFilter]="field.dateFilter"
        [formControl]="control"
        [formlyAttributes]="field"
        matInput />
      <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #datepickerRef></mat-datepicker>
      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
      <mat-hint *ngIf="to.description">{{ to.description }}</mat-hint>
    </mat-form-field>
  `
})
export class DatepickerFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit {
  ngOnInit(): void {}

  get control() {
    return this.formControl as FormControl;
  }

  clearDate() {
    this.control.setValue(null);
  }
}
