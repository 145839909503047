import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubStudentComponent } from './utclub-student.component';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  exports: [
    UtclubStudentComponent
  ],
  declarations: [
    UtclubStudentComponent
  ],
  imports: [
    CommonModule,
    
    MatIconModule,
    ClipboardModule,

    PipesModule
  ]
})
export class UtclubStudentModule { }
