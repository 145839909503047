import { Component } from '@angular/core';

@Component({
  selector: 'utclub-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  sections: any[] = [
    {
      label: 'Genel Bilgiler',
      key: 'general',
    },
    {
      label: 'Iki Faktorlu Dogrulama',
      key: 'two-factor-authentication',
    }
  ];
  // section: string = 'general';
  section: string = 'two-factor-authentication';

  sectionChanged(event: any) {
    this.section = event.options[0].value;
  }
}
