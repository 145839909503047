<utclub-page title="Inquiry Detail" [breadcrumbs]="['Inquiries', 'Inquiry Detail']">
  <div toolbar>
    <div class="sticky left-0 flex items-center" *ngIf="!loading && inquiry">
      <button mat-mini-fab class="mr-2" matTooltip="Teklif Ver" color="primary" (click)="openOfferDialog()">
        <mat-icon svgIcon="mat:attach_money"></mat-icon>
      </button>

      <button
        *ngIf="inquiry.status == 1"
        mat-mini-fab
        class="mr-2"
        matTooltip="Kayit Yap"
        color="primary"
        (click)="openEnrollmentDialog()">
        <mat-icon svgIcon="mat:assignment"></mat-icon>
      </button>

      <button mat-mini-fab class="mr-2" matTooltip="Kullanici Degistir" color="primary" (click)="openUserDialog()">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </button>
      @if (inquiry.status == 4) {
        <button
          mat-mini-fab
          class="mr-2"
          matTooltip="Formu Aktif Et"
          color="primary"
          (click)="openToBeActivateDialog()">
          <mat-icon svgIcon="mat:play_arrow"></mat-icon>
        </button>
      }

      <span class="flex-1"></span>

      <utclub-rating *ngIf="inquiry" [rating]="inquiry.rating"></utclub-rating>
    </div>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <div class="grid grid-cols-2 gap-4 mb-4" *ngIf="!loading && inquiry">
    <div class="card" #reviewCard>
      <utclub-inquiry-form-review [inquiry]="inquiry" (inquiryUpdated)="loadData(false)"></utclub-inquiry-form-review>
    </div>
    <div class="card overflow-auto" #taskCard>
      <utclub-inquiry-form-follow-up
        [inquiry]="inquiry"
        (followUpsListed)="followUpsListed()"
        (inquiryUpdated)="loadData(false)"></utclub-inquiry-form-follow-up>
    </div>
  </div>

  <mat-tab-group
    #tabGroup
    *ngIf="!loading && inquiry"
    class="vex-tabs rounded"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [selectedIndex]="activeTabIndex"
    (selectedIndexChange)="onTabChange($event)">
    <mat-tab id="general" label="General">
      <ng-template matTabContent>
        <div class="container p-0 grid grid-cols-2 gap-4 mb-4">
          <div>
            <utclub-inquiry-form-ut-edit
              [inquiry]="inquiry"
              (inquiryUpdated)="loadData(false)"></utclub-inquiry-form-ut-edit>
          </div>
          <div class="p-0 grid grid-cols-1 gap-4">
            <utclub-inquiry-form-personal-edit
              [inquiry]="inquiry"
              (inquiryUpdated)="loadData(false)"></utclub-inquiry-form-personal-edit>
            <utclub-inquiry-form-extra-edit
              [inquiry]="inquiry"
              (inquiryUpdated)="loadData(false)"></utclub-inquiry-form-extra-edit>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab id="offers" label="Offers">
      <ng-template matTabContent>
        <utclub-inquiry-form-offers [id]="id" [inquiry]="inquiry"></utclub-inquiry-form-offers>
      </ng-template>
    </mat-tab>
    <mat-tab id="logs" label="Logs">
      <ng-template matTabContent>
        <utclub-student-log [id]="inquiry.studentId"></utclub-student-log>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</utclub-page>
