import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { LookupService } from 'src/app/services/lookup.service';

export interface InquiryProgramInformationUpdateDto {
  preferredCountries?: Array<number> | null;
  programId?: number | null;
}

@Component({
  selector: 'utclub-inquiry-form-personal-edit',
  template: `
    <div class="card overflow-auto w-full flex flex-col">
      <div class="flex items-center border-b-2 border-b-primary py-2 px-4">
        <h2 class="m-0 flex-auto">Program</h2>
      </div>
      <div class="p-4">
        <utclub-form *ngIf="formInterface" [form]="formInterface" (success)="success($event)"></utclub-form>
      </div>
    </div>
  `
})
export class InquiryFormProgramEditComponent implements AfterContentInit {
  @Output() inquiryUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() inquiry: InquiryDto;
  formInterface: UtclubFormInterface;

  @ViewChild(UtclubFormComponent, { static: false }) form: UtclubFormComponent;
  constructor(private lookUpService: LookupService) {}

  ngAfterContentInit(): void {
    var id = this.inquiry.id;

    var preferredCountries = [];
    this.inquiry.preferredCountry1Id && preferredCountries.push(this.inquiry.preferredCountry1Id);
    this.inquiry.preferredCountry2Id && preferredCountries.push(this.inquiry.preferredCountry2Id);
    this.inquiry.preferredCountry3Id && preferredCountries.push(this.inquiry.preferredCountry3Id);

    var model: InquiryProgramInformationUpdateDto = {
      preferredCountries: preferredCountries,
      programId: this.inquiry.programId
    };
    this.formInterface = {
      fields: [
        {
          key: 'preferredCountries',
          type: '#country',
          props: {
            label: 'Tercih Edilen Ulkeler',
            multiple: true
          }
        },
        {
          key: 'programId',
          type: 'http-field',
          props: {
            label: 'Program',
            http: {
              url: `student-service/program?ProgramType=${this.inquiry.programType}`
            }
          }
        }
      ],
      defaultModel: model,
      submitMethod: 'put',
      submitUrl: 'student-service/inquiry/' + id + '/program-information'
    };
  }

  success(event) {
    this.inquiryUpdated.emit();
  }
}
