import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from 'src/app/services/api.service';
import { CustomFormlyFieldConfig } from '../utclub-formly/formly/custom-formly-field-config';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export interface UtclubFormInterface {
  fields: CustomFormlyFieldConfig[];
  defaultModel?: any;

  submitMethod: 'post' | 'put';
  submitUrl: string;
  submitType?: 'json' | 'formdata';

  loadDataUrl?: string;
}

@Component({
  selector: 'utclub-form',
  templateUrl: './utclub-form.component.html'
})
export class UtclubFormComponent implements AfterContentInit {
  public formGroup: FormGroup;
  public model: any;
  public fields: CustomFormlyFieldConfig[];

  @Input() form: UtclubFormInterface;

  @Output() prepareModel: EventEmitter<any> = new EventEmitter();
  @Output() modelLoaded: EventEmitter<any> = new EventEmitter();

  @Input() matDialogRef: MatDialogRef<any>;

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  submitRequest;
  submitLoading: boolean = false;

  loading: boolean = false;

  public errorMessage: string = '';
  errors: { key: string; value: string }[] = [];

  notChanged: boolean = false;
  firstModel: any;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngAfterContentInit(): void {
    this.prepareForm();
    this.prepareFields();
    this.loadData();
    this.formGroup.valueChanges.subscribe((value) => {
      this.valueChanges.emit(value);
    });
  }
  loadData() {
    if (!this.form.loadDataUrl) {
      return;
    }
    this.loading = true;
    this.apiService.get(this.form.loadDataUrl).subscribe(
      (response) => {
        this.model = response.data;
        this.formGroup.patchValue(this.model);
        if (this.modelLoaded) {
          this.modelLoaded.emit(this.model);
        }
        this.loadFirstModel();
        this.formGroup.markAsTouched();
        this.loading = false;
        this.formGroup.markAsTouched();
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  loadFirstModel() {
    this.firstModel = JSON.parse(JSON.stringify(this.model));
    this.notChanged = JSON.stringify(this.firstModel) == JSON.stringify(this.model);
  }
  prepareFields() {
    this.fields = this.form.fields;
  }
  prepareForm() {
    this.formGroup = new FormGroup({});
    this.model = this.form.defaultModel || {};
    this.loadFirstModel();
    this.formGroup.valueChanges.subscribe((value) => {
      this.notChanged = JSON.stringify(this.firstModel) == JSON.stringify(this.model);
    });
  }

  onSubmit() {
    if (this.prepareModel) {
      this.prepareModel.emit(this.model);
    }
    this.setSaveLoading(true);
    if (!this.form.submitType || this.form.submitType == 'json') {
      this.jsonSubmit();
    } else if (this.form.submitType == 'formdata') {
      this.formDataSubmit();
    }
  }

  jsonSubmit() {
    this.submitRequest = this.apiService.request(this.form.submitMethod, this.form.submitUrl, this.model).subscribe({
      next: (response) => {
        if (response instanceof HttpResponse) {
          this.onSuccess(response);
          this.setSaveLoading(false);
        }
      },
      error: (error) => {
        if (error.error) {
          if (error.error.errors) this.errors = error.error.errors;
          if (error.error.message) this.errorMessage = error.error.message;
        } else {
          this.errorMessage = this.translateService.instant('errors.unknown');
        }
        this.setSaveLoading(false);
      }
    });
  }

  // recursive function to append form data
  formDataAppend(formData: FormData, fields: any[]) {
    for (let field of fields) {
      if (field.fieldGroup) {
        this.formDataAppend(formData, field.fieldGroup);
      } else if (field.type == 'filepicker' && this.model[field.key]) {
        if (field.props.multiple) {
          for (const file of this.model[field.key]) {
            formData.append(field.key, file);
          }
        } else {
          formData.append(field.key, this.model[field.key][0]);
        }
      } else if (field.type == 'datepicker' && this.model[field.key]) {
        if (this.model[field.key] instanceof Date) {
          formData.append(field.key, this.model[field.key].toISOString());
        } else if (this.model[field.key] instanceof moment) {
          formData.append(field.key, this.model[field.key].toISOString());
        }
      } else if (this.model[field.key]) {
        formData.append(field.key, this.model[field.key]);
      }
    }
  }

  formDataSubmit() {
    var formData = new FormData();
    this.formDataAppend(formData, this.fields);
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    var options = { headers };

    this.submitRequest = this.apiService
      .request(this.form.submitMethod, this.form.submitUrl, formData, options)
      .subscribe({
        next: (response) => {
          if (response instanceof HttpResponse) {
            this.onSuccess(response);
            this.setSaveLoading(false);
          }
        },
        error: (error) => {
          if (error.error) {
            if (error.error.errors) this.errors = error.error.errors;
            if (error.error.message) this.errorMessage = error.error.message;
          } else {
            this.errorMessage = this.translateService.instant('errors.unknown');
          }
          this.setSaveLoading(false);
        }
      });
  }

  onSuccess(response: any) {
    this.success.emit(response);
    if (this.matDialogRef) {
      this.matDialogRef.close({
        refresh: true,
        response: response
      });
    } else {
      this.loadFirstModel();
      this.loadData();
    }
  }

  setSaveLoading(loading: boolean) {
    this.submitLoading = loading;
    if (this.submitLoading) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
      this.submitRequest = null;
    }
    this.cdr.detectChanges();
  }
}
