<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Kullanıcı Değiştir</h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col p-4">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-raised-button [mat-dialog-close]="true" type="button">Kapat</button>
  @if (submitLoading) {
    <mat-spinner diameter="24"></mat-spinner>
  }
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="submitLoading" type="button">Kaydet</button>
</mat-dialog-actions>
