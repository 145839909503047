import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvoiceSendEmailDto } from 'src/app/api/models/invoiceSendEmailDto';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'utclub-invoice-form-send-email',
  templateUrl: './invoice-form-send-email.component.html'
})
export class InvoiceFormSendEmailComponent {
  model: InvoiceSendEmailDto = {};
  form = new FormGroup({});
  loading = false;
  formFields: CustomFormlyFieldConfig[] = [

    {
      key: 'invoiceId',
      type: 'input',
      hide: true,
    },
    {
      key: 'toAddresses',
      type: 'repeat',
      props: {
        label: 'Email',
      },
      fieldArray: {
        type: 'input',
        key: 'toAddresses',
        props: {
          label: 'Email:',
          required: true,
        },
      },
    },
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Baslik'
      }
    },
    {
      key: 'message',
      type: 'textarea',
      props: {
        label: 'Mesaj',
        rows: 5
      }
    }
  ];

  buttons: UtclubButtonInterface[] = [
    {
      label: 'İptal',
      color: 'warn',
      click: () => {
        this.matDialogRef.close();
      },
      buttonType: 'button'
    },
    {
      label: 'Gonder',
      color: 'primary',
      click: () => {
        this.save();
      },
      buttonType: 'button'
    }
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private matDialogRef: MatDialogRef<InvoiceFormSendEmailComponent>,
    private apiService: ApiService
  ) {
    this.model.invoiceId = defaults.invoiceId;
    this.model.toAddresses = [""]
  }

  save() {
    this.loading = true;
    this.apiService.post('accounting-service/invoice/send-email', this.model).subscribe((res) => {
      this.loading = false;
      this.matDialogRef.close();
    }, () => {
      this.loading = false;
    });

  }
}
