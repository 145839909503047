import { ChangeDetectorRef, Component, ElementRef, NgIterable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { MatSelect } from '@angular/material/select';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { Observable, Subscription, map } from 'rxjs';

@Component({
  selector: 'formly-cached-select',
  template: `
    <ng-container>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label> {{ field.props.label }} </mat-label>

        <mat-select
          #selectInput
          [multiple]="field.props.multiple"
          [required]="field.props.required"
          [formControl]="control"
          [formlyAttributes]="field"
          (opened)="onOpened()"
          (closed)="onClosed()">
          <input
            matInput
            #searchInput
            [formControl]="searchCtrl"
            class="mat-mdc-option"
            style="width: calc(100% - 32px);" />

          <!-- <mat-option #loading *ngIf="loadingItems">
            <div class="flex items-center justify-between">
              <span> Yükleniyor... </span>
              <span>
                <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
              </span>
            </div>
          </mat-option> -->

          <mat-option *ngIf="anyErrors" (click)="setObs()">
            <div class="flex items-center justify-between text-red-500">
              <span>Bir hata oluştu</span>
              <button mat-icon-button>
                <mat-icon svgIcon="mat:refresh"></mat-icon>
              </button>
            </div>
          </mat-option>

          <ng-container *ngIf="observableOptions | async as optionList">
            <mat-option *ngFor="let option of optionList | search: searchCtrl.value" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
  `
})
export class CachedSelectFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit, OnDestroy {
  searchCtrl = new FormControl();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('selectInput') selectInput: MatSelect;

  observableOptions: Observable<{ value: any; label: string }[]>;
  observableItems: Observable<any[]>;

  itemsUpdateSubs: Subscription;
  constructor(
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {
    if (!this.field.props.valueProp) {
      this.field.props.valueProp = 'id';
    }
    if (!this.field.props.labelProp) {
      this.field.props.labelProp = 'name';
    }
    this.setObs();

    this.formControl.valueChanges.subscribe((value: any) => {
      this.observableItems.subscribe((items) => {
        this.eventBusService.emit(
          new EventData(
            this.field.key.toString(),
            items.find((x) => x[this.field.props.valueProp] == value)
          )
        );
      });
    });
  }

  items: any[];
  anyErrors: boolean = false;
  setObs() {
    if (!this.field.props.observableProp && this.field.props.observable) {
      this.setObservable();
    } else if (this.field.props.observableProp && this.field.props.observable) {
      this.setObservableProp();
    } else {
      throw new Error('observable is required');
    }
  }

  ngOnDestroy(): void {
    if (this.itemsUpdateSubs) {
      this.itemsUpdateSubs.unsubscribe();
    }
  }

  setItems(items: any[]) {
    var mappedItems;
    if (this.field.props.labelFunc) {
      mappedItems = items.map((item: any) => {
        return {
          value: item[this.field.props.valueProp],
          label: this.field.props.labelFunc(item)
        };
      });
    }

    if (this.field.props.labelProp) {
      mappedItems = items.map((item: any) => {
        return {
          value: item[this.field.props.valueProp],
          label: item[this.field.props.labelProp]
        };
      });
    }

    if (!this.field.props.labelFunc && !this.field.props.labelProp) {
      throw new Error('labelFunc or labelProp is required');
    }

    return mappedItems;
  }

  sendObservableItems() {
    this.observableItems.subscribe((items) => {
      this.eventBusService.emit(new EventData(this.field.key + 'ItemsLoaded', items));
    });
  }

  setObservable() {
    var observable = this.field.props.observable;
    try {
      this.observableOptions = observable.pipe(map((items: any[]) => this.setItems(items)));
    } catch (e) {
      this.anyErrors = true;
      this.cdr.detectChanges();
    }
    this.observableOptions = observable.pipe(map((items: any[]) => this.setItems(items)));
    this.observableItems = observable;
    this.sendObservableItems();
  }

  setObservableProp() {
    var prop = this.field.props.observableProp;
    this.form.controls[prop].valueChanges.subscribe((value: any) => {
      if (!value) return;
      this.observablePropValuUpdated(value);
    });

    if (this.model[prop]) this.observablePropValuUpdated(this.model[prop]);
  }

  observablePropValuUpdated(value) {
    var observable = this.field.props.observable;
    this.observableOptions = observable(value).pipe(map((items: any[]) => this.setItems(items)));
    this.observableItems = observable(value);
    this.sendObservableItems();
  }

  onOpened() {
    this.searchCtrl.setValue('');
    this.searchInput.nativeElement.focus();
  }

  onClosed() {
    this.searchCtrl.setValue('');
  }

  get control() {
    return this.formControl as FormControl;
  }
}
