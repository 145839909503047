import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtclubConfirmDialogComponent } from './utclub-confirm-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { UtclubButtonInterface } from '../utclub-button/utclub-button.component';

@Injectable({
  providedIn: 'root'
})
export class UtclubConfirmDialogService {

  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService
  ) { }

  deleteConfirmDialog(deleteUrl: string, afterDelete?: (entity: any) => void) {
    var deleteButtons: UtclubButtonInterface[] = [
      {
        label: 'Hayir',
        color: 'primary',
        click: () => {
          dialog.close();
        },
        buttonType: 'button'
      },
      {
        label: 'Evet',
        color: 'warn',
        click: () => {
          this.apiService.delete(deleteUrl).subscribe(response => {
            dialog.close();
            if (afterDelete) {
              afterDelete(response);
            }
          });
        },
        buttonType: 'button'
      }
    ];
    var dialog = this.matDialog.open(UtclubConfirmDialogComponent, {
      data: {
        title: 'Kayit Silme',
        description: 'Bu kaydi silmek istediginize emin misiniz?',
        width: '300px',
        buttons: deleteButtons
      }
    });

    return dialog;
  }

  confirmDialog(title: string, description: string, buttons: UtclubButtonInterface[]) {
    var dialog = this.matDialog.open(UtclubConfirmDialogComponent, {
      data: {
        title: title,
        description: description,
        width: '300px',
        buttons: [
          ...buttons,
          {
            label: 'Tamam',
            color: 'primary',
            click: () => {
              dialog.close();
            },
            buttonType: 'button'
          }
        ]
      }
    });
    return dialog;
  }
}
