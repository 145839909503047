import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { EnumsService } from './services/enums.service';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { megamenu } from './components/utclub-tab/navigations';
import { SignalrService } from './services/signalr.service';
import { AuthService } from './services/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Enums } from './services/enums';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private enumsService: EnumsService,
    private signalrService: SignalrService,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private translateService: TranslateService
  ) {
    Settings.defaultLocale = this.localeId;

    var cachedLang = localStorage.getItem('lang');
    if (!cachedLang) {
      cachedLang = 'tr';
      localStorage.setItem('lang', cachedLang);
    }
    this.translateService.use(cachedLang);

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (name: string, namespace: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/logos/${name}.svg`);

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/flags/${name}.svg`);
        }
      }
    );

    this.configService.updateConfig({
      sidenav: {
        title: 'UTCLUB',
        imageUrl: '/assets/favicon.png',
        showCollapsePin: true,
        search: {
          visible: false
        }
      },
      footer: {
        visible: false
      }
    });
    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    var navigationItems: NavigationItem[] = [];
    megamenu.forEach((item) => {
      navigationItems.push({
        type: 'link',
        label: item.label,
        route: item.url,
        icon: item.icon,
        claims: item.claims
      });
    });
    this.navigationService.setItems(navigationItems);
  }
  ngOnInit(): void {
    this.signalrService.installConnection();

    if (this.swUpdate.isEnabled) {
      // this.swUpdate..subscribe(() => {
      //   if (confirm("You're using an old version of the control panel. Want to update?")) {
      //     window.location.reload();
      //   }
      // });
    }
  }
}
