<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <utclub-button
      buttonType="button"
      [label]="'Filtre'"
      (click)="filterToggle()"
      [icon]="filterOpened ? 'mat:keyboard_arrow_down' : 'mat:keyboard_arrow_right'"></utclub-button>

    <mat-button-toggle-group class="ml-3" [formControl]="preDateControl" [hideSingleSelectionIndicator]="true">
      <mat-button-toggle [value]="1">Bugun</mat-button-toggle>
      <mat-button-toggle [value]="2">Bu Hafta</mat-button-toggle>
      <mat-button-toggle [value]="3">Bu Ay</mat-button-toggle>
    </mat-button-toggle-group>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="mr-4 flex-none"
      mat-icon-button
      matTooltip="Kolonlari seC"
      type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <utclub-button *ngFor="let button of actions" [button]="button"> </utclub-button>
  </div>

  <div *ngIf="filterOpened" @fadeInUp class="p-4">
    <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel"> </utclub-formly>
    <div>
      <utclub-button buttonType="button" [label]="'Filtrele'" (click)="filter()" class="mr-2"></utclub-button>
      <utclub-button buttonType="button" [label]="'Temizle'" (click)="clearFilter()"> </utclub-button>
    </div>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && transactions">
    <thead>
      <tr>
        <th *ngFor="let column of visibleColumns">{{ column.label }}</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let transaction of transactions">
        <tr *ngIf="transaction.transactionType == 1" style="border-bottom: 1px solid #a7a7a7">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">{{ transaction.receipt.accountName }}</td>
          <td *ngIf="receiptColumn.visible">
            <div class="rounded px-2 text-green bg-green/10 cursor-pointer">
              {{ transaction.receipt.amount | currency: transaction.receipt.accountCurrencyId | async }}
            </div>
          </td>
          <td *ngIf="paymentColumn.visible"></td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
        <tr *ngIf="transaction.transactionType == 2" style="border-bottom: 1px solid #a7a7a7">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">
            {{ transaction.payment.accountName }}
          </td>
          <td *ngIf="receiptColumn.visible"></td>
          <td *ngIf="paymentColumn.visible">
            <div class="rounded px-2 text-red bg-red/10">
              {{ transaction.payment.amount | currency: transaction.payment.accountCurrencyId | async }}
            </div>
          </td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
        <tr *ngIf="transaction.transactionType == 3" style="border-bottom: 1px solid #e0e0e0">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">
            {{ transaction.exchange.fromAccountName }}
          </td>
          <td *ngIf="receiptColumn.visible"></td>
          <td *ngIf="paymentColumn.visible">
            <div class="rounded px-2 text-red bg-red/10">
              {{
                transaction.exchange.fromAccountAmount | currency: transaction.exchange.fromAccountCurrencyId | async
              }}
            </div>
          </td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
        <tr *ngIf="transaction.transactionType == 3" style="border-bottom: 1px solid #a7a7a7">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">{{ transaction.exchange.toAccountName }}</td>
          <td *ngIf="receiptColumn.visible">
            <div class="rounded px-2 text-green bg-green/10 cursor-pointer">
              {{ transaction.exchange.toAccountAmount | currency: transaction.exchange.toAccountCurrencyId | async }}
            </div>
          </td>
          <td *ngIf="paymentColumn.visible"></td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
        <tr *ngIf="transaction.transactionType == 4" style="border-bottom: 1px solid #e0e0e0">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">
            {{ transaction.transfer.fromAccountName }}
          </td>
          <td *ngIf="receiptColumn.visible"></td>
          <td *ngIf="paymentColumn.visible">
            <div class="rounded px-2 text-red bg-red/10">
              {{ transaction.transfer.amount | currency: transaction.transfer.fromAccountCurrencyId | async }}
            </div>
          </td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
        <tr *ngIf="transaction.transactionType == 4" style="border-bottom: 1px solid #a7a7a7">
          <ng-template *ngTemplateOutlet="tplTransactionId; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionInfo; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionOffice; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionType; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionDetail; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionPerson; context: { $implicit: transaction }"></ng-template>
          <td *ngIf="accountColumn.visible">
            {{ transaction.transfer.toAccountName }}
          </td>
          <td *ngIf="receiptColumn.visible">
            <div class="rounded px-2 text-green bg-green/10 cursor-pointer">
              {{ transaction.transfer.amount | currency: transaction.transfer.toAccountCurrencyId | async }}
            </div>
          </td>
          <td *ngIf="paymentColumn.visible"></td>
          <ng-template *ngTemplateOutlet="tplTransactionNote; context: { $implicit: transaction }"></ng-template>
          <ng-template *ngTemplateOutlet="tplTransactionButton; context: { $implicit: transaction }"></ng-template>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ng-template #tplTransactionInfo let-transaction>
    <td *ngIf="dateColumn.visible">
      <span> {{ transaction.executorUserName }} </span> <br />
      <span>
        <mat-icon
          svgIcon="mat:history"
          *ngIf="transaction.lastModifiedAt"
          class="mr-2"
          (click)="openHistory(transaction.id)"
          matTooltip="{{ transaction.lastModifiedAt | date: 'dd-MM-yyyy HH:mm' : '+300' }} tarihinde, {{
            transaction.lastModifiedBy
          }} tarafindan duzenlendi.">
        </mat-icon>
        {{ transaction.date | date: 'dd-MM-yyyy HH:mm' : '+300' }}
      </span>
    </td>
  </ng-template>

  <ng-template #tplTransactionType let-transaction>
    <td *ngIf="transactionColumn.visible">
      <div [ngSwitch]="transaction.transactionType">
        <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block">Tahsilat</div>
        <div *ngSwitchCase="2" class="rounded px-2 text-red bg-red/10" style="display: inline-block">Odeme</div>
        <div *ngSwitchCase="3" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block">Doviz</div>
        <div *ngSwitchCase="4" class="rounded px-2 text-cyan bg-cyan/10" style="display: inline-block">Transfer</div>
      </div>
    </td>
  </ng-template>

  <ng-template #tplTransactionDetail let-transaction>
    <td *ngIf="detailColumn.visible">
      <div *ngIf="transaction.transactionType == 1" class="text-center">
        <span> {{ transaction.receipt.receiptType | enum: 'ReceiptType' | async }} </span> <br />

        <span *ngIf="transaction.receipt.receiptType == 1">
          {{ transaction.receipt.receiptTypeEducationDepartments | enum: 'ReceiptTypeEducationDepartments' | async }}
        </span>
        <br *ngIf="transaction.receipt.receiptType == 1" />
        <span *ngIf="transaction.receipt.receiptType == 1">
          {{ transaction.receipt.receiptTypeEducationReceiptUnit | enum: 'ReceiptTypeEducationReceiptUnits' | async }}
        </span>

        <span *ngIf="transaction.receipt.receiptType == 2">
          {{ transaction.receipt.receiptTypeOperationReceiptUnit | enum: 'ReceiptTypeOperationReceiptUnits' | async }}
        </span>
      </div>

      <div *ngIf="transaction.transactionType == 2" class="text-center">
        <span> {{ transaction.payment.paymentType | enum: 'PaymentType' | async }} </span> <br />

        <span *ngIf="transaction.payment.paymentType == 1">
          {{ transaction.payment.paymentTypeEducationDepartment | enum: 'PaymentTypeEducationDepartment' | async }}
        </span>
        <br *ngIf="transaction.payment.paymentType == 1" />
        <span *ngIf="transaction.payment.paymentType == 1">
          {{ transaction.payment.paymentTypeEducationPaymentUnit | enum: 'PaymentTypeEducationPaymentUnit' | async }}
        </span>

        <span *ngIf="transaction.payment.paymentType == 2">
          {{ transaction.payment.paymentTypeOperationPaymentUnit | enum: 'PaymentTypeOperationPaymentUnit' | async }}
        </span>

        <span *ngIf="transaction.payment.paymentType == 3">
          {{ transaction.payment.paymentTypeStaffPaymentUnit | enum: 'PaymentTypeStaffPaymentUnit' | async }}
        </span>

        <span *ngIf="transaction.payment.paymentType == 4">
          {{ transaction.payment.paymentTypeYENDepartment | enum: 'PaymentTypeYENDepartment' | async }}
        </span>
        <br *ngIf="transaction.payment.paymentType == 4" />

        <span *ngIf="transaction.payment.paymentType == 5">
          {{ transaction.payment.paymentTypePartnership | enum: 'PaymentTypePartnership' | async }}
        </span>
      </div>
    </td>
  </ng-template>

  <ng-template #tplTransactionOffice let-transaction>
    <td *ngIf="officeColumn.visible">
      <span> {{ transaction.relatedBranchName }} </span>
    </td>
  </ng-template>

  <ng-template #tplTransactionId let-transaction>
    <td *ngIf="idColumn.visible">
      <span> #{{ transaction.id }} </span>
    </td>
  </ng-template>

  <ng-template #tplTransactionPerson let-transaction>
    <td *ngIf="personColumn.visible">
      <span *ngIf="transaction.transactionType == 1 && transaction.receipt.receiptType == 1">
        {{ transaction.receipt.enrollmentName }} <br />
        #{{ transaction.receipt.enrollmentId }}
      </span>

      <span *ngIf="transaction.transactionType == 2 && transaction.payment.paymentType == 1">
        {{ transaction.payment.enrollmentName }} <br />
        #{{ transaction.payment.enrollmentId }}
      </span>
      <span *ngIf="transaction.transactionType == 2 && transaction.payment.paymentType == 3">
        {{ transaction.payment.staffName }}
      </span>
      <span
        *ngIf="
          transaction.transactionType == 2 &&
          transaction.payment.paymentType == 4 &&
          transaction.payment.paymentTypeYENDepartment == 1
        ">
        {{ transaction.payment.studyAbroadRepresentativeName }}
      </span>
      <span
        *ngIf="
          transaction.transactionType == 2 &&
          transaction.payment.paymentType == 4 &&
          transaction.payment.paymentTypeYENDepartment == 2
        ">
        {{ transaction.payment.studyAbroadPointName }}
      </span>
      <span *ngIf="transaction.transactionType == 2 && transaction.payment.paymentType == 5">
        {{ transaction.payment.paymentTypePartnership | enum: 'PaymentTypePartnership' | async }}
      </span>

      <span *ngIf="transaction.transactionType == 3 && transaction.exchange.enrollmentId">
        {{ transaction.exchange.enrollmentName }} <br />
        #{{ transaction.exchange.enrollmentId }}
      </span>
      <span *ngIf="transaction.transactionType == 3 && transaction.exchange.isExchangeForBranch">
        {{ transaction.relatedBranchName }}
      </span>
    </td>
  </ng-template>

  <ng-template #tplTransactionButton let-transaction>
    <td *ngIf="actionsColumn.visible">
      <button
        (click)="$event.stopPropagation()"
        [matMenuTriggerData]="{ entity: transaction }"
        [matMenuTriggerFor]="actionsMenu"
        mat-icon-button
        type="button">
        <mat-icon svgIcon="mat:more_horiz"></mat-icon>
      </button>
    </td>
  </ng-template>

  <ng-template #tplTransactionNote let-transaction>
    <td *ngIf="noteColumn.visible">
      {{ transaction.note }}
    </td>
  </ng-template>

  <mat-paginator [pageSize]="pageSize" (page)="pageChanged($event)" class="sticky left-0"> </mat-paginator>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-entity="entity" matMenuContent>
    <utclub-button *ngFor="let button of rowActions" [button]="button" [entity]="entity"> </utclub-button>
  </ng-template>
</mat-menu>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns" class="mat-menu-item block">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>
