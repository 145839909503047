import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { InquiryDto } from 'src/app/api/models/inquiryDto';
import { ApiService } from 'src/app/services/api.service';
import { Response } from '../../../models/response.model';
import { MatDialog } from '@angular/material/dialog';
import { InquiryOfferFormComponent } from './inquiry-offer-form/inquiry-offer-form.component';
import { InquiryFormChangeUserComponent } from './inquiry-form-forms/inquiry-form-change-user.component';
import { InquiryFormFollowUpComponent } from './inquiry-form-follow-up/inquiry-form-follow-up.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EnrollmentCreateFormComponent } from '../../enrollment/enrollment-create-form/enrollment-create-form.component';
import { StudentFormComponent } from '../../student/student-form/student-form.component';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { InquiryFormActivateDialogComponent } from './inquiry-form-activate-dialog/inquiry-form-activate-dialog.component';

@Component({
  selector: 'utclub-inquiry-form',
  styleUrls: ['./inquiry-form.component.scss'],
  templateUrl: './inquiry-form.component.html'
})
export class InquiryFormComponent implements AfterViewInit {
  id: number;

  loading: boolean = false;
  inquiry: InquiryDto;

  tabs: { label: string; index: number }[] = [
    { label: 'general', index: 0 },
    { label: 'offers', index: 1 },
    { label: 'logs', index: 2 }
  ];
  activeTabIndex: number = 0;

  @ViewChild(InquiryFormFollowUpComponent) followUps: InquiryFormFollowUpComponent;

  @ViewChild('taskCard') followUpCard: ElementRef;
  @ViewChild('reviewCard') reviewCard: ElementRef;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      this.loadData();
    });
  }

  onTabChange(event) {
    var tab = this.tabs.find((x) => x.index == event);
    if (!tab) return;
    window.history.replaceState({}, '', `/inquiry/detail/${this.id}/${tab.label}`);
  }

  loadData(loading: boolean = true) {
    this.loading = loading;
    this.cdr.detectChanges();
    this.apiService
      .get<Response<InquiryDto>>(`student-service/inquiry/${this.id}?includeFollowUps=true`)
      .subscribe((response) => {
        this.inquiry = response.data;
        this.titleService.setTitle(
          `INQ#${this.inquiry.id} | ${this.inquiry.student.firstName} ${this.inquiry.student.lastName}`
        );
        this.loading = false;
        this.cdr.detectChanges();
        this.initTab();
        this.calculateCardHeight();
      });
  }

  initTab() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['tab']) {
        var tabs = this.tabGroup._tabs.toArray();
        for (let index = 0; index < tabs.length; index++) {
          const tab = tabs[index];
          if (tab._implicitContent.elementRef.nativeElement.parentElement.id == params['tab']) {
            this.activeTabIndex = index;
            break;
          }
        }
      }
    });
  }

  openOfferDialog() {
    this.dialog
      .open(InquiryOfferFormComponent, {
        width: '900px',
        minWidth: '900px',
        data: {
          inquiryId: this.id,
          programType: this.inquiry.programType
        }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        if (updatedEntity.refresh) {
          this.loadData(false);
        }
      });
  }

  openEnrollmentDialog() {
    this.dialog
      .open(EnrollmentCreateFormComponent, {
        width: '900px',
        minWidth: '900px',
        data: {
          inquiryId: this.id
        }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        if (updatedEntity.id) {
          this.router.navigate(['/enrollment/detail', updatedEntity.id]);
        }
      });
  }

  openUserDialog() {
    this.dialog
      .open(InquiryFormChangeUserComponent, {
        width: '500px',
        data: {
          inquiryId: this.id
        }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        if (updatedEntity.refresh) {
          this.loadData(false);
        }
        this.followUps.loadData();
      });
  }

  calculateCardHeight() {
    // find highest card and set the other card's height to it
    const followUpCardHeight = this.followUpCard.nativeElement.offsetHeight;
    const reviewCardHeight = this.reviewCard.nativeElement.offsetHeight;
    if (followUpCardHeight > reviewCardHeight) {
      this.reviewCard.nativeElement.style.height = followUpCardHeight + 'px';
    } else {
      this.followUpCard.nativeElement.style.height = reviewCardHeight + 'px';
    }
  }
  followUpsListed() {
    this.calculateCardHeight();
  }

  openToBeActivateDialog() {
    this.dialog
      .open(InquiryFormActivateDialogComponent, {
        width: '500px',
        data: {
          inquiryId: this.id
        }
      })
      .afterClosed()
      .subscribe((updatedEntity) => {
        if (updatedEntity.refresh) {
          this.loadData(false);
        }
      });
  }
}
