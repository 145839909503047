import { Component, ContentChildren, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { DebitFormComponent } from './debit-form/debit-form.component';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { DebitDto } from 'src/app/api/models/debitDto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Enums } from 'src/app/services/enums';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-debit',
  templateUrl: './debit.component.html',
  animations: [fadeInUp400ms, stagger40ms]
})
export class DebitComponent implements OnInit {
  actions: UtclubButtonInterface[] = [
    {
      tooltip: 'Borclandirma',
      icon: 'mat:money_off',
      buttonType: 'mini-fab',
      cssClass: 'ml-2',
      click: () => {
        this.matDialog
          .open(DebitFormComponent, {
            width: '500px',
            data: {
              formType: 'create'
            }
          })
          .afterClosed()
          .subscribe((addedEntity) => {
            if (addedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      }
    }
  ];
  rowActions: UtclubButtonInterface[] = [];

  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loading: boolean = false;
  columns: string[] = ['date', 'debitType', 'type', 'amount', 'note', 'actions'];

  pageSize: number = 50;
  pageIndex: number = 0;

  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private lookup: LookupService
  ) {}

  ngAfterViewInit(): void {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.loadData();
  }

  ngOnInit(): void {
    this.rowActions.push({
      label: 'Duzenle',
      icon: 'mat:edit',
      click: (row: DebitDto) => {
        if (row.debitType == 4) {
          this.matSnackBar.open("Ofis ofise borclandirma turu sadece 'Gunlukten' duzenlenebilir.", 'Tamam');
          return;
        }
        this.matDialog
          .open(DebitFormComponent, {
            width: '700px',
            data: { formType: 'update', id: row.id }
          })
          .afterClosed()
          .subscribe((updatedEntity) => {
            if (updatedEntity?.refresh) {
              this.loadData();
              this.paginator.pageIndex = 0;
            }
          });
      },
      buttonType: 'menu-item'
    });
  }

  loadData() {
    this.apiService
      .post<PagedResponse<any[]>>('accounting-service/debit/filter-debits', {
        ...this.filterModel,
        page: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize
      })
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  rowClicked(row) {
    console.log('rowClicked', row);
  }
  sortChanged(event) {
    console.log('sortChanged', event);
  }
  pageChanged(event) {
    console.log('pageChanged', event);
  }

  filterOpened: boolean = false;
  filterModel: any = {};
  filterForm = new FormGroup({});
  filterFields: CustomFormlyFieldConfig[] = null;
  filterToggle() {
    if (!this.filterFields) {
      this.filterFields = [
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'input',
              key: 'debitId',
              props: {
                label: 'Islem No',
                placeholder: '',
                description: 'Coklu islem icin virgul ile ayiriniz.'
              }
            },
            {
              type: 'datepicker',
              key: 'startDate',
              props: {
                label: 'Baslangic Tarihi',
                placeholder: ''
              }
            },
            {
              type: 'datepicker',
              key: 'endDate',
              props: {
                label: 'Bitis Tarihi',
                placeholder: ''
              }
            }
          ]
        },
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'enum-select',
              key: 'debitType',
              props: {
                label: 'Borc Tipi',
                enum: Enums.DebitType
              }
            },
            {
              key: 'staffId',
              type: '#user',
              props: {
                label: 'Personel'
              },
              expressions: {
                hide: 'model.debitType != 2'
              }
            },
            {
              type: '#serviceProvider',
              expressions: {
                hide: 'model.debitType != 3'
              }
            },
            {
              key: 'debitTypeStudent',
              type: 'enum-select',
              props: {
                label: 'Borclandirma Turu',
                placeholder: '',
                enum: Enums.DebitTypeEnrollment
              },
              expressions: {
                hide: 'model.debitType != 1'
              }
            },
            {
              key: 'debitTypeStaff',
              type: 'enum-select',
              props: {
                label: 'Borclandirma Turu',
                enum: Enums.DebitTypeStaff
              },
              expressions: {
                hide: 'model.debitType != 2'
              }
            },
            {
              key: 'enrollmentId',
              type: 'enrollment-field',
              props: {
                label: 'Ogrenci',
                placeholder: '',
                required: false
              },
              expressions: { hide: 'model.debitType != 1 && model.debitType != 3' }
            }
          ]
        }
      ];
    }
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    this.loadData();
  }
}
