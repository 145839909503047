import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UtclubButtonInterface } from '../utclub-button/utclub-button.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'utclub-confirm-dialog',
  template: `
    <utclub-modal [title]="title" [buttons]="buttons">
      <p *ngIf="description" class="mt-6">{{ description }}</p>
    </utclub-modal>
  `
})
export class UtclubConfirmDialogComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() buttons: UtclubButtonInterface[] = [];
  @Input() description: string;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any) {}

  ngOnInit(): void {
    if (this.defaults.title) this.title = this.defaults.title;
    if (this.defaults.buttons) this.buttons = this.defaults.buttons;
    if (this.defaults.description) this.description = this.defaults.description;
  }

  ngAfterViewInit(): void {}
}
