import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentLogComponent } from './student-log.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { StudentLogValueLineComponent } from './student-log-value-line.component';
import { StudentLogTitleComponent } from './student-log-title.component';

@NgModule({
  declarations: [StudentLogComponent, StudentLogValueLineComponent, StudentLogTitleComponent],
  exports: [StudentLogComponent],
  imports: [CommonModule, MatProgressBarModule, MatListModule, MatIconModule, MatTabsModule, PipesModule]
})
export class StudentLogModule {}
