import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'utclub-rating',
  templateUrl: './utclub-rating.component.html'
})
export class UtclubRatingComponent implements OnInit {
  @Input() rating: number;
  @Input() isInquiryRating: boolean = true;

  enumString: string = 'InquiryRating';

  ngOnInit(): void {
    if (!this.isInquiryRating) {
      this.enumString = 'CorporateRating';
    }
  }
}
