<utclub-page [breadcrumbs]="['Profile']" [title]="'Profile'">
  <div class="card flex flex-col sm:flex-row">
    <div class="p-4 flex-none">
      <mat-selection-list class="w-300 max-w-full" (selectionChange)="sectionChanged($event)" [multiple]="false">
        <mat-list-option *ngFor="let item of sections" [value]="item.key" [selected]="item.key == section">
          {{ item.label }}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="border-l flex-auto">
      <div class="p-4" *ngIf="section == 'general'">
        <h2 class="title mt-0 mb-4">Genel Bilgiler</h2>
      </div>

      <div class="p-4" *ngIf="section == 'two-factor-authentication'">
        <h2 class="title mt-0 mb-4">Iki Faktorlu Dogrulama</h2>
        <utclub-two-factor-authentication-setup></utclub-two-factor-authentication-setup>
      </div>
    </div>
  </div>
</utclub-page>
