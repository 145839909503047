import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StudentFormComponent } from "./student-form.component";
import { UtclubModalModule } from "src/app/components/utclub-modal/utclub-modal.module";
import { UtclubFormModule } from "src/app/components/utclub-form/utclub-form.module";
import { UtclubFormlyModule } from "src/app/components/utclub-formly/utclub-formly.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";

@NgModule({
  declarations: [StudentFormComponent],
  exports: [StudentFormComponent],
  imports: [CommonModule, UtclubModalModule, UtclubFormModule, UtclubFormlyModule, ReactiveFormsModule, FormlyModule],
})
export class StudentFormModule {}
