<div *ngIf="selection.selected.length > 0" class="p-4">
  <div class="flex items-center" *ngFor="let selected of selection.selected">
    <span class="flex-1"> {{ selected.schoolBranchName }} | {{ selected.programId | entity: 'Program' | async }} </span>
    <button mat-icon-button type="button" (click)="deleteSelect(selected)">
      <mat-icon class="accent" svgIcon="mat:delete"></mat-icon>
    </button>
  </div>
</div>

<div @fadeInUp class="p-4">
  <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel"> </utclub-formly>
</div>

<div class="overflow-scroll">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table matSort>
    <ng-container [matColumnDef]="'schoolBranchId'">
      <th *matHeaderCellDef mat-header-cell>School Branch</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.schoolBranchName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="'programId'">
      <th *matHeaderCellDef mat-header-cell>Program</th>
      <td *matCellDef="let row" mat-cell>
        @if (row.customTitle) {
          {{ row.customTitle }}
        } @else {
          {{ row.programId | entity: 'Program' | async }}
        }
      </td>
    </ng-container>

    <ng-container [matColumnDef]="'campaignExpireDate'">
      <th *matHeaderCellDef mat-header-cell>Kampanya Geçerlilik</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.campaignExpireDate | date: 'dd-MM-yyyy' }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="'priceExpireDate'">
      <th *matHeaderCellDef mat-header-cell>Fiyat Geçerlilik</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.priceExpireDate | date: 'dd-MM-yyyy' }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="'actions'" stickyEnd>
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox
          (change)="$event ? selection.toggle(row) : null"
          (click)="$event.stopPropagation()"
          [checked]="selection.isSelected(row)"
          color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns" @fadeInUp class="hover:bg-hover trans-ease-out" mat-row></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[10, 20, 50, 100]"
    [length]="totalItemCount"
    (page)="pageEvent($event)"
    class="sticky left-0">
  </mat-paginator>
</div>
