import { AfterContentInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UtclubConfirmDialogService } from 'src/app/components/utclub-confirm-dialog/utclub-confirm-dialog.service';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Enums } from 'src/app/services/enums';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-payment-form',
  template: `
    <utclub-modal title="Odeme">
      <utclub-form
        *ngIf="formInterface"
        [form]="formInterface"
        [matDialogRef]="matDialogRef"
        (success)="onSuccess($event)"></utclub-form>
    </utclub-modal>
  `
})
export class PaymentFormComponent implements AfterContentInit, OnDestroy {
  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  enrollmentSubs: Subscription;

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'paymentType',
      type: 'big-radio',
      props: {
        label: 'İslem Tipi',
        required: true,
        enum: Enums.PaymentType
      },
      expressions: {
        hide: 'model.paymentType'
      }
    },
    {
      key: 'date',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Tarih',
        placeholder: '',
        required: false
      },
      expressions: {
        hide: (field: CustomFormlyFieldConfig) => {
          return !this.authService.isInRole('Manager') || !field.model.paymentType;
        }
      }
    },
    {
      key: 'enrollmentId',
      type: 'enrollment-field',
      props: {
        label: 'Ogrenci',
        required: false
      },
      expressions: {
        hide: 'model.paymentType != 1 || (model.paymentType == 1 && model.paymentTypeEducationDepartment == 5) && [10,9,8,7,5,12].includes(model.paymentTypeEducationPaymentUnit)'
      }
    },
    {
      type: '#enrollmentGroup',
      expressions: { hide: 'model.paymentTypeEducationDepartment != 5' }
    },
    {
      key: 'relatedBranchId',
      type: '#branch',
      props: {
        required: true
      },
      expressions: {
        hide: '!model.paymentType'
      }
    },
    //Education
    {
      key: 'paymentTypeEducationDepartment',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Departman',
        required: true,
        enum: Enums.PaymentTypeEducationDepartment
      },
      expressions: {
        hide: 'model.paymentType != 1'
      }
    },
    {
      key: 'paymentTypeEducationPaymentUnit',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Gider Kalemi',
        required: true,
        enum: Enums.PaymentTypeEducationPaymentUnit
      },
      expressions: {
        hide: 'model.paymentType != 1'
      }
    },
    {
      type: '#serviceProvider',
      hide: false,
      props: {
        required: true
      },
      expressions: {
        hide: `model.paymentTypeEducationPaymentUnit != 5
                && model.paymentTypeEducationPaymentUnit != 8
                && model.paymentTypeEducationPaymentUnit != 13
                && model.paymentTypeEducationPaymentUnit != 11`
      }
    },
    //Operation,
    {
      key: 'paymentTypeOperationPaymentUnit',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Gider Kalemi',
        required: true,
        enum: Enums.PaymentTypeOperationPaymentUnit
      },
      expressions: {
        hide: 'model.paymentType != 2'
      }
    },
    //Staff
    {
      key: 'paymentTypeStaffPaymentUnit',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Gider Kalemi',
        required: true,
        enum: Enums.PaymentTypeStaffPaymentUnit
      },
      expressions: {
        hide: 'model.paymentType != 3'
      }
    },
    {
      key: 'staffPremiumDate',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Prim Tarihi',
        placeholder: '',
        description: 'Prim tarihinde yilin dogru secilmesi onemlidir.',
        required: false
      },
      expressions: {
        hide: 'model.paymentTypeStaffPaymentUnit != 2'
      }
    },
    {
      key: 'staffId',
      type: '#user',
      props: {
        label: 'Personel'
      },
      expressions: {
        hide: 'model.paymentType != 3'
      }
    },
    //YEN
    {
      key: 'paymentTypeYENDepartment',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Departman',
        required: true,
        enum: Enums.PaymentTypeYENDepartment
      },
      expressions: {
        hide: 'model.paymentType != 4'
      }
    },
    {
      key: 'studyAbroadPointId',
      type: '#studyAbroadPoint',
      hide: false,
      props: {
        required: true
      },
      expressions: {
        hide: 'model.paymentType != 4 || model.paymentTypeYENDepartment != 2'
      }
    },
    {
      key: 'studyAbroadRepresentativeId',
      type: '#studyAbroadRepresentative',
      hide: false,
      props: {
        required: true
      },
      expressions: {
        hide: 'model.paymentType != 4 || model.paymentTypeYENDepartment != 1'
      }
    },
    {
      key: 'staffPremiumDate',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Prim Tarihi',
        placeholder: '',
        description: 'Prim tarihinde yilin dogru secilmesi onemlidir.',
        required: false
      },
      expressions: {
        hide: 'model.paymentType != 4'
      }
    },
    //Partnership
    {
      key: 'paymentTypePartnership',
      type: 'enum-select',
      hide: false,
      props: {
        label: 'Partner',
        required: true,
        enum: Enums.PaymentTypePartnership
      },
      expressions: {
        hide: 'model.paymentType != 5'
      }
    },
    {
      key: 'accountId',
      type: '#availableTransactionAccountField',
      props: {
        required: true
      },
      expressions: {
        hide: '!model.paymentType'
      }
    },
    {
      key: 'amount',
      type: 'input',
      props: {
        label: 'Tutar',
        placeholder: '',
        required: true
      },
      expressions: {
        hide: '!model.paymentType'
      }
    },
    {
      key: 'note',
      type: 'textarea',
      props: {
        label: 'Not',
        placeholder: '',
        required: false,
        rows: 3
      },
      expressions: {
        hide: '!model.paymentType'
      }
    }
  ];
  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<PaymentFormComponent>,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private apiService: ApiService,
    private utclubConfirmDialogService: UtclubConfirmDialogService,
    private lookup: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: this.fields,
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/payment' : 'accounting-service/payment/' + id,
      loadDataUrl: !id ? null : 'accounting-service/payment/' + id
    };
    this.enrollmentSubs = this.eventBusService.on('enrollment-loaded', (data) => {
      this.form.formGroup.controls['relatedBranchId'].setValue(data.branchId);
      switch (data.programType) {
        case 1:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(2);
          break;
        case 2:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(6);
          break;
        case 3:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(4);
          break;
        case 4:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(3);
          break;
        case 5:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(1);
          break;
        case 6:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(7);
          break;
        case 7:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(8);
          break;
        case 8:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(9);
          break;
        case 9:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(5);
          break;
        case 10:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(10);
          break;
        default:
          this.form.formGroup.controls['paymentTypeEducationDepartment'].setValue(0);
          break;
      }
      if (data.enrollmentGroupId) this.form.model.enrollmentGroupId = data.enrollmentGroupId;
    });
  }

  ngOnDestroy(): void {
    this.enrollmentSubs.unsubscribe();
  }
  onSuccess(response) {
    // if (this.form.model.branchId != this.branch.id) {
    //   this.apiService
    //     .post('accounting-service/debit/get-branch-debit-balance', {
    //       branchId: this.id,
    //       mainAccountId: this.account.id,
    //       currencyId: this.account.currencyId
    //     })
    //     .subscribe((response) => {
    //       if (response.data.amount <= 0) {
    //         this.utclubConfirmDialogService.confirmDialog(
    //           'Dikkat!',
    //           `${this.account.name} hesabinda ${this.branch.name} ofisinin parasi kalmamistir, lutfen para bozdurunuz.`,
    //           []
    //         );
    //       }
    //     });
    // }
  }
}
