import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface UtclubButtonInterface {
  label?: string;
  icon?: string;
  click?: (entity: any) => void;
  dblClick?: (entity: any) => void;
  url?: string;
  color?: 'red' | 'green' | 'warn' | 'purple' | 'primary' | 'default';
  disabled?: boolean;
  tooltip?: string;
  cssClass?: string;

  entity?: any;

  buttonType: 'button' | 'icon-button' | 'fab' | 'mini-fab' | 'menu-item';
  loading?: boolean;
}

@Component({
  selector: 'utclub-button',
  template: `
  <button *ngIf="buttonType == 'button'" mat-raised-button [routerLink]="url"
    (click)="clicked()" (dblclick)="dblClicked()" [color]="color" [matTooltip]="tooltip" [class]="cssClass"
    [attr.disabled]="disabled">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <span *ngIf="label.startsWith('i18n:')" [translate]="label.substring(5)"></span>
    <span *ngIf="!label.startsWith('i18n:')">{{label}}</span>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </button>
  
  <button *ngIf="buttonType == 'icon-button'" mat-icon-button [routerLink]="url"
    (click)="clicked()" (dblclick)="dblClicked()" [color]="color" [matTooltip]="tooltip" [class]="cssClass"
    [attr.disabled]="disabled">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </button>

  <button *ngIf="buttonType == 'fab'" mat-fab [routerLink]="url"
    (click)="clicked()" (dblclick)="dblClicked()" [color]="color" [matTooltip]="tooltip" [class]="cssClass"
    [attr.disabled]="disabled">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </button>

  <button *ngIf="buttonType == 'mini-fab'" mat-mini-fab [routerLink]="url"
    (click)="clicked()" (dblclick)="dblClicked()" [color]="color" [matTooltip]="tooltip" [class]="cssClass"
    [attr.disabled]="disabled">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </button>

  <button *ngIf="buttonType == 'menu-item'" mat-menu-item [routerLink]="url"
    (click)="clicked()" (dblclick)="dblClicked()" [matTooltip]="tooltip" [class]="cssClass"
    [attr.disabled]="disabled">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <span *ngIf="label.startsWith('i18n:')" [translate]="label.substring(5)"></span>
    <span *ngIf="!label.startsWith('i18n:')">{{label}}</span>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </button>

  `

})
export class UtclubButtonComponent implements OnInit, OnChanges {

  @Input() label: string;
  @Input() icon: string;
  @Input() click: (entity: any) => void;
  @Input() dblClick: (entity: any) => void;
  @Input() url: string;
  @Input() color: 'red' | 'green' | 'warn' | 'purple' | 'primary' | 'default' = 'primary';
  @Input() disabled: boolean;
  @Input() tooltip: string;
  @Input() buttonType: 'button' | 'icon-button' | 'fab' | 'mini-fab' | 'menu-item' = 'button';
  @Input() cssClass: string;

  @Input() entity: any;

  @Input() button: UtclubButtonInterface;
  @Input() loading: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.button) {
      this.loadFromButton(this.button);
    }
  }

  ngOnInit(): void {
    if (this.button) {
      this.loadFromButton(this.button);
    }
  }

  loadFromButton(button: UtclubButtonInterface) {
    this.label = this.button.label;
    this.icon = this.button.icon;
    this.click = this.button.click;
    this.dblClick = this.button.dblClick;
    this.url = this.button.url;
    this.color = this.button.color ?? 'primary';
    this.disabled = this.button.disabled;
    this.tooltip = this.button.tooltip;
    if (this.button.entity) {
      this.entity = this.button.entity;
    }
    this.buttonType = this.button.buttonType ?? 'button';
    this.cssClass = this.button.cssClass;
    this.loading = this.button.loading;
  }


  timer;
  preventSimpleClick;
  clicked() {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 200;
    var that = this;
    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        if (that.click) {
          that.click(this.entity);
        }
      }
    }, 200);
  }

  dblClicked() {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    if (this.dblClick) {
      this.dblClick(this.entity);
    }
  }
}
