import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubFormlyComponent } from './utclub-formly.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { FormlyCustomModule } from './formly/formly-custom.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DirectivesModule } from 'src/app/directives/directives.module';



@NgModule({
  declarations: [
    UtclubFormlyComponent
  ],
  exports: [
    UtclubFormlyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,

    PipesModule,
    DirectivesModule,

    MatButtonModule,
    MatProgressBarModule
  ]
})
export class UtclubFormlyModule { }
