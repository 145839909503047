import { Pipe, PipeTransform } from '@angular/core';
import { EnumsService } from '../services/enums.service';
import { Enums } from '../services/enums';
import { TranslateService } from '@ngx-translate/core';
import { EntityService } from '../services/entity.service';

@Pipe({
  name: 'partnerType'
})
export class PartnerTypePipe implements PipeTransform {
  constructor(private entityService: EntityService) {}

  async transform(partnerId: number, partnerType: number): Promise<string> {
    var partnerEntity = '';
    var str = '';
    switch (partnerType) {
      case 1:
        partnerEntity = 'StudyAbroadRepresentative';
        str = 'Yurtdışı Eğitim Danışmanı';
        break;
      case 2:
        partnerEntity = 'StudyAbroadPoint';
        str = 'Yurtdışı Eğitim Noktası';
        break;
      case 3:
        partnerEntity = 'School';
        str = 'Okul';
        break;
      case 4:
        partnerEntity = 'CorporateInquiry';
        str = 'Kurumsal Form';
        break;
      case 5:
        partnerEntity = 'WebPortal';
        str = 'Web Portal';
        break;
    }
    str += ', ' + (await this.entityService.getEntityLabel(partnerEntity, partnerId));
    return str;
  }
}
