import { Pipe, PipeTransform } from '@angular/core';
import { EntityService } from '../services/entity.service';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
  currencies: {
    id: number;
    name: string;
    code: string;
    symbol: string;
  }[] = [
    {
      id: 7,
      name: 'Turkish Lira',
      code: 'TRY',
      symbol: '₺'
    },
    {
      id: 6,
      name: 'New Zealand Dollar',
      code: 'NZD',
      symbol: 'NZ$'
    },
    {
      id: 5,
      name: 'Australian Dollar',
      code: 'AUD',
      symbol: 'A$'
    },
    {
      id: 4,
      name: 'Canadian Dollar',
      code: 'CAD',
      symbol: 'C$'
    },
    {
      id: 3,
      name: 'British Pound',
      code: 'GBP',
      symbol: '£'
    },
    {
      id: 2,
      name: 'Euro',
      code: 'EUR',
      symbol: '€'
    },
    {
      id: 1,
      name: 'US Dollar',
      code: 'USD',
      symbol: '$'
    }
  ];

  constructor(
    private entityService: EntityService,
    private decimalPipe: DecimalPipe
  ) {}

  async transform(value: number, currencyId: any, property: string = 'symbol'): Promise<string> {
    if (currencyId === undefined || currencyId === null || currencyId === 0) {
      return this.decimalPipe.transform(value, '1.2-2');
    }
    var currency = this.currencies.find((c) => c.id === currencyId);
    if (!currency) {
      return this.decimalPipe.transform(value, '1.2-2');
    }
    return this.decimalPipe.transform(value, '1.2-2') + ' ' + currency[property];
  }
}
