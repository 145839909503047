import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { LookupService } from 'src/app/services/lookup.service';

export interface InvoiceInformation {
  name: string;
  address: string;
  taxNumber: string;
  taxOffice: string;
  phoneNumber: string;
  email: string;
  city: string;
  country: string;
  schoolId: number | null;
}

@Component({
  selector: 'utclub-invoice-information-form',
  template: `
    <utclub-modal title="Fatura Bilgisi">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"></utclub-form>
    </utclub-modal>
  `
})
export class InvoiceInformationFormComponent implements AfterContentInit {
  formInterface: UtclubFormInterface;

  matDialogRef: MatDialogRef<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<InvoiceInformationFormComponent>,
    private lookup: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: [
        {
          key: 'title',
          type: 'input',
          props: {
            label: 'Baslik',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'name',
          type: 'input',
          props: {
            label: 'İsim',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'address',
          type: 'input',
          props: {
            label: 'Adres',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'taxNumber',
          type: 'input',
          props: {
            label: 'Vergi Numarasi',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'phoneNumber',
          type: 'input',
          props: {
            label: 'Telefon Numarasi',
            placeholder: ''
          }
        },
        {
          key: 'contactName',
          type: 'input',
          props: {
            label: 'Yetkili Isim',
            placeholder: ''
          }
        },
        {
          key: 'email',
          type: 'input',
          props: {
            label: 'E-posta',
            placeholder: ''
          }
        },
        {
          key: 'city',
          type: 'input',
          props: {
            label: 'Sehir',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'country',
          type: 'input',
          props: {
            label: 'Ulke',
            placeholder: '',
            required: true
          }
        },
        {
          key: 'zip',
          type: 'input',
          props: {
            label: 'ZIP',
            placeholder: '',
            required: true
          }
        },
        {
          type: '#school'
        },
        {
          type: '#serviceProvider'
        }
      ],
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/invoiceinformation' : 'accounting-service/invoiceinformation/' + id,
      loadDataUrl: !id ? null : 'accounting-service/invoiceinformation/' + id
    };
  }
}
