import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { EnumPipe } from './enum.pipe';
import { CurrencyPipe } from './currency.pipe';
import { SearchPipe } from './search.pipe';
import { EntityPipe } from './entity.pipe';
import { NameFirstLettersPipe } from './name-first-letters.pipe';
import { PartnerTypePipe } from './partner-type.pipe';

@NgModule({
  declarations: [EnumPipe, CurrencyPipe, SearchPipe, EntityPipe, NameFirstLettersPipe, PartnerTypePipe],
  exports: [EnumPipe, CurrencyPipe, SearchPipe, EntityPipe, NameFirstLettersPipe, PartnerTypePipe],
  providers: [DecimalPipe],
  imports: [CommonModule]
})
export class PipesModule {}
