import { Component, Input } from '@angular/core';
import { CustomStudentLogDto } from './student-log.component';

@Component({
  selector: 'student-log-title',
  template: `
    <h4>
      {{ title }}
      <span class="ml-3 text-hint text-xs"> {{ log.userName }} {{ log.createdAt | date: 'dd/MM/yyyy HH:mm' }} </span>
    </h4>
  `
})
export class StudentLogTitleComponent {
  @Input() title: string;
  @Input() log: CustomStudentLogDto;
}
