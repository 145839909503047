import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Enums } from 'src/app/services/enums';

const fields: CustomFormlyFieldConfig[] = [
  {
    key: 'rating',
    type: 'select',
    props: {
      label: 'Rating',
      required: true,
      options: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '0' }
      ]
    }
  },

  {
    key: 'plannedStartDate',
    type: 'monthpicker',
    props: {
      label: 'Planned Start Date'
    },
    expressions: {
      hide: '!model.rating || model.rating == 4'
    }
  },
  {
    key: 'followUpDate',
    type: 'datepicker',
    props: {
      label: 'Follow Up Date',
      required: true
    },
    expressions: {
      hide: '!model.rating || model.rating == 4'
    }
  },
  {
    key: 'comment',
    type: 'textarea',
    props: {
      label: 'Comment',
      required: true,
      rows: 4
    },
    expressions: {
      hide: '!model.rating || model.rating == 4'
    }
  },

  {
    key: 'cancelledType',
    type: 'enum-select',
    props: {
      label: 'Cancelled Reason',
      required: true,
      enum: Enums.InquiryCancelledType
    },
    expressions: {
      hide: '!model.rating || model.rating == 1 || model.rating == 2 || model.rating == 3'
    }
  },
  {
    key: 'comment',
    type: 'textarea',
    props: {
      label: 'Comment',
      required: true,
      rows: 4
    },
    expressions: {
      hide: '!model.rating || model.rating == 1 || model.rating == 2 || model.rating == 3'
    }
  }
];

@Component({
  selector: 'utclub-inquiry-follow-up-form',
  template: `
    <utclub-modal title="Follow Up">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"></utclub-form>
    </utclub-modal>
  `
})
export class InquiryFollowUpFormComponent implements AfterContentInit {
  formInterface: UtclubFormInterface;

  matDialogRef: MatDialogRef<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<InquiryFollowUpFormComponent>
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: fields,
      defaultModel: {
        inquiryId: this.defaults.inquiryId,
        plannedStartDate: this.defaults.plannedStartDate,
        rating: this.defaults.rating
      },
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'student-service/inquiry/follow-up' : 'student-service/inquiry/follow-up/' + id,
      loadDataUrl: !id ? null : 'student-service/inquiry/follow-up/' + id
    };
  }
}
