import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UtclubTablePageComponent } from 'src/app/components/utclub-table/utclub-table-page.component';
import { UtclubTableComponent } from 'src/app/components/utclub-table/utclub-table.component';
import { ApiService } from 'src/app/services/api.service';
import { LookupService } from 'src/app/services/lookup.service';
import { CreditFormComponent } from '../credit-form/credit-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Enums } from 'src/app/services/enums';

@Component({
  selector: 'utclub-credit-filter',
  templateUrl: './credit-filter.component.html',
  styleUrls: ['./credit-filter.component.scss']
})
export class CreditFilterComponent extends UtclubTablePageComponent implements AfterViewInit {
  filterFields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-4 gap-4',
      fieldGroup: [
        {
          key: 'enrollmentId',
          type: 'input',
          props: {
            label: 'Ogrenci'
          }
        },
        {
          type: '#branch'
        }
      ]
    },
    {
      fieldGroupClassName: 'grid grid-cols-4 gap-4',
      fieldGroup: [
        {
          key: 'creditType',
          type: 'enum-select',
          props: {
            label: 'Islem Tipi',
            enum: Enums.CreditType
          }
        },
        {
          key: 'schoolId',
          type: 'http-field',
          props: {
            label: 'Okul',
            http: {
              url: 'student-service/school'
            }
          },
          expressions: {
            hide: 'model.creditType != 3'
          }
        }
      ]
    }
  ];
  @ViewChild('utclubTable', { static: true }) table: UtclubTableComponent;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService
  ) {
    super(
      {
        url: 'accounting-service/credit/filter'
      },
      apiService,
      cdr
    );
    this.columns = [
      { property: 'date', visible: true, label: 'Tarih', columnType: 'date' },
      { property: 'creditType', visible: true, label: 'Islem Tipi', columnType: 'enum', enum: 'CreditType' },
      { property: 'detail', visible: true, label: 'Detay', columnType: 'custom' },
      { property: 'amount', visible: true, label: 'Tutar', columnType: 'custom' },
      { property: 'note', visible: true, label: 'Açıklama', columnType: 'text' },
      { label: 'Actions', visible: true, property: 'actions', columnType: 'actions' }
    ];
  }

  ngAfterViewInit(): void {
    this.table.addCreateAction(CreditFormComponent);
    this.table.addUpdateAction(CreditFormComponent);
    super.ngAfterViewInit();
  }
}
