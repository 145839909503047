import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubModalModule } from 'src/app/components/utclub-modal/utclub-modal.module';
import { UtclubFormlyModule } from 'src/app/components/utclub-formly/utclub-formly.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatDialogModule } from '@angular/material/dialog';
import { InquiryFollowUpFormComponent } from './inquiry-follow-up-form.component';
import { UtclubFormModule } from 'src/app/components/utclub-form/utclub-form.module';



@NgModule({
  declarations: [
    InquiryFollowUpFormComponent
  ],
  imports: [
    CommonModule,

    UtclubModalModule,
    UtclubFormModule,

    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    DirectivesModule,

    MatDialogModule
  ]
})
export class InquiryFollowUpFormModule { }
