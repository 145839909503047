import { ChangeDetectorRef, Component, ElementRef, NgIterable, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { CustomFormlyFieldConfig } from "../custom-formly-field-config";
import { MatSelect } from "@angular/material/select";
import { EventBusService, EventData } from "src/app/services/event-bus.service";
import { Observable, Subscription, map } from "rxjs";

@Component({
  selector: "formly-select",
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>
      <mat-select #selectInput [formControl]="control" [formlyAttributes]="field" (opened)="onOpened()">
        <input matInput #searchInput [formControl]="searchCtrl" placeholder="Ara.." class="mat-mdc-option" style="width: calc(100% - 32px);" />
        <mat-option *ngFor="let option of selectOptions | search : searchCtrl.value" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
})
export class SelectFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit, OnDestroy {
  searchCtrl = new FormControl();
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("selectInput") selectInput: MatSelect;

  selectOptions: { value: any; label: string }[] = [];

  itemsUpdateSubs: Subscription;
  constructor(private eventBusService: EventBusService) {
    super();
  }

  ngOnDestroy(): void {
    if (this.itemsUpdateSubs) {
      this.itemsUpdateSubs.unsubscribe();
    }
  }

  async ngOnInit() {
    if (!this.field.props.valueProp) {
      this.field.props.valueProp = "id";
    }
    if (!this.field.props.labelProp) {
      this.field.props.labelProp = "name";
    }

    if (!this.field.props.options) throw new Error("options is required");
    this.selectOptions = this.field.props.options.map((element) => {
      return {
        value: element[this.field.props.valueProp],
        label: element[this.field.props.labelProp],
      };
    });

    this.formControl.valueChanges.subscribe((value: any) => {
      var item = this.field.props.options.find((x) => x[this.field.props.valueProp] == value);
      this.eventBusService.emit(new EventData(this.field.key.toString(), item));
    });
  }

  onOpened() {
    this.searchCtrl.setValue("");
    this.searchInput.nativeElement.focus();
  }

  get control() {
    return this.formControl as FormControl;
  }
}
