<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading"> Bildirimler </div>
      <!-- <div class="dropdown-subheading">You have {{ notifications.length }} new notifications.</div> -->
    </div>

    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button>
  </div>


  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <div class="dropdown-content">
    <a *ngFor="let notification of notifications" [class.read]="notification.readAt"
      (click)="notificationClicked(notification)" class="notification flex items-center" matRipple>
      <mat-icon svgIcon="mat:doorbell" class="notification-icon text-primary flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ notification.title }}</div>
        <div class="notification-description">{{ notification.createdAt | relativeDateTime }}</div>
      </div>
      <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ entity: notification }"
        [matMenuTriggerFor]="singleNotificationSettingsMenu" mat-icon-button type="button">
        <mat-icon class="notification-chevron flex-none" svgIcon="mat:more_vert"></mat-icon>
      </button>
    </a>
  </div>
  <mat-paginator [pageSize]="pageSize" (page)="pageChanged($event)" class="sticky left-0">
  </mat-paginator>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="readNotificaitons()">
    <mat-icon svgIcon="mat:mark_email_read"></mat-icon>
    <span>Okundu olarak isaretle</span>
  </button>

  <button mat-menu-item (click)="archiveNotificaitons()">
    <mat-icon svgIcon="mat:archive"></mat-icon>
    <span>Arsivle</span>
  </button>

  <button mat-menu-item (click)="loadData()">
    <mat-icon svgIcon="mat:refresh"></mat-icon>
    <span>Yenile</span>
  </button>

</mat-menu>

<mat-menu #singleNotificationSettingsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-entity="entity" matMenuContent>
    <button mat-menu-item (click)="readNotificaiton(entity)">
      <mat-icon svgIcon="mat:mark_email_read"></mat-icon>
      <span>Okundu olarak isaretle</span>
    </button>

    <button mat-menu-item (click)="archiveNotificaiton(entity)">
      <mat-icon svgIcon="mat:archive"></mat-icon>
      <span>Arsivle</span>
    </button>
  </ng-template>
</mat-menu>