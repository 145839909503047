import { ApiService } from 'src/app/services/api.service';
import { CustomFormlyFieldConfig } from './../custom-formly-field-config';

export class HttpOptionsExtension {
  constructor(private apiService: ApiService) { }

  postPopulate(field: CustomFormlyFieldConfig): void {
    
  }
};

export function registerHttpOptionsExtension(apiService: ApiService) {
  return {
    extensions: [
      {
        name: 'http-options-extension',
        extension: new HttpOptionsExtension(apiService)
      }
    ]
  };
}