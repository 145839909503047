import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubFormComponent } from './utclub-form.component';
import { MatButtonModule } from '@angular/material/button';
import { FormlyModule } from '@ngx-formly/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    UtclubFormComponent
  ],
  exports: [
    UtclubFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,

    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,

    FormlyModule,
    ReactiveFormsModule
  ]
})
export class UtclubFormModule { }
