import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (!items || !items.length) return items;
    if (!searchTxt || !searchTxt.length) return items;

    const regexp = new RegExp(searchTxt, 'i');
    const properties = Object.keys(items[0]);
    return [
      ...items.filter((item) => {
        return properties.some((property) => regexp.test(item[property]));
      })
    ];
  }
}
