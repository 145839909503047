import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { ApiService } from 'src/app/services/api.service';
import { NotificationFilterDto } from 'src/app/api/models/notificationFilterDto';
import { Response } from 'src/app/models/response.model';
import { EventBusService } from 'src/app/services/event-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit, OnDestroy {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  unreadCount: number = 0;

  unreadSubscription: Subscription;
  createdNotificationSubscription: Subscription;

  constructor(
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private apiService: ApiService,
    private eventBusService: EventBusService
  ) { }

  ngOnInit() {
    var filterDto: NotificationFilterDto = {
      archived: false,
      read: false
    };
    this.apiService.post<Response<number>>('notification-service/notification/count', filterDto).subscribe(response => {
      this.unreadCount = response.data;
      this.cd.markForCheck();
    });

    this.unreadSubscription = this.eventBusService.on('DecreaseUnreadMessages', (data) => {
      if (data.count == 0) {
        this.unreadCount = 0;
      } else {
        this.unreadCount += data.count;
      }
      this.cd.markForCheck();
    });

    this.createdNotificationSubscription = this.eventBusService.on('NotificationCreated', (data) => {
      this.unreadCount += 1;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unreadSubscription.unsubscribe();
    this.createdNotificationSubscription.unsubscribe();
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
