<div class="relative flex-none">
  <div
    class="w-10 h-10 rounded flex-none flex items-center justify-center border"
    [class.text-gray]="rating == 0"
    [class.bg-gray-light]="rating == 0"
    [class.border-gray]="rating == 0"
    [class.text-amber]="rating == 1"
    [class.bg-amber-light]="rating == 1"
    [class.border-amber]="rating == 1"
    [class.text-primary]="rating == 2"
    [class.bg-primary-light]="rating == 2"
    [class.border-primary]="rating == 2"
    [class.text-green]="rating == 3"
    [class.bg-green-light]="rating == 3"
    [class.border-green]="rating == 3"
    [class.text-red]="rating == 4"
    [class.bg-red-light]="rating == 4"
    [class.border-red]="rating == 4">
    {{ rating | enum: enumString | async }}
  </div>
</div>
