import { AfterContentInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { TransactionDto } from 'src/app/api/models/transactionDto';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'utclub-debit-account-transactions-modal',
  templateUrl: './debit-account-transactions-modal.component.html'
})
export class DebitAccountTransactionsModalComponent implements AfterContentInit, OnInit {
  debitAccountId: number;

  transactions: TransactionDto[];
  loading: boolean = false;

  pageSize: number = 10;
  page: number = 0;
  public buttons: UtclubButtonInterface[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.debitAccountId = data.debitAccountId;
  }

  ngOnInit(): void {
  }
  ngAfterContentInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    var body = {
      debitAccountId: this.debitAccountId,
      pageSize: this.pageSize,
      page: this.page
    };
    this.apiService.post<PagedResponse<TransactionDto>>('accounting-service/debit/get-transactions-from-debit-account-id', body).subscribe(response => {
      this.transactions = response.data;

      this.paginator.length = response.itemsCount;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadData();
  }
}
