import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { megamenu, navigations } from 'src/app/components/utclub-tab/navigations';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

export interface MegaMenuFeature {
  icon: string;
  label: string;
  url: string;
  id: string;
  sidenav?: NavigationItem[];
  pageLoadType: 'tab' | 'page';
  claims?: Array<string | { key: string; value: any }>;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent implements OnInit {
  features: MegaMenuFeature[] = [];

  constructor(
    private popoverRef: PopoverRef<MegaMenuComponent>,
    private navigationService: NavigationService,
    private router: Router,
    private location: Location,
    private authService: AuthService
  ) {}

  ngOnInit() {
    var megamenus: MegaMenuFeature[] = megamenu.filter((item) => {
      var canAccess = true;
      if (item.claims) {
        for (const claim of item.claims) {
          if (typeof claim === 'string') {
            canAccess = this.authService.hasClaim(claim);
          } else {
            canAccess = this.authService.hasClaimWithValue(claim.key, claim.value);
          }
        }
      }
      return canAccess;
    });
    this.features = megamenus;
  }

  close(feature) {
    this.popoverRef.close();
  }

  changeNavigation(feature) {
    var url = this.router.url.replace('/', '');
    var currenctFeature = this.features.find((f) => f.url == url);

    if (!currenctFeature) {
      this.router.navigate([feature.url]);
    }
    if (currenctFeature.pageLoadType != 'tab' && feature.pageLoadType == 'tab') {
      this.router.navigate([feature.url]);
    }

    this.navigationService.setItems(feature.sidenav);
    if (this.router.url == '/' || this.router.url == '') {
      this.router.navigate([feature.url]);
    } else {
      this.location.replaceState(feature.url);
    }
    this.close(feature);
  }
}
