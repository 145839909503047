<div class="card overflow-auto">
  <utclub-table
    #utclubTable
    [columns]="columns"
    [dataSource]="dataSource"
    [(pageSize)]="pageSize"
    [(pageIndex)]="pageIndex"
    [totalItemCount]="totalItemCount"
    [loading]="loading"
    (pageChanged)="pageChanged()"
    [(filterModel)]="filterModel"
    [filterFields]="filterFields"
    (filter)="loadData()">
    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef>Detay</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.creditType == 1">
          {{ element.serviceProviderId | entity: 'ServiceProvider' | async }}
        </span>
        <span *ngIf="element.creditType == 2">
          {{ element.schoolId | entity: 'School' | async }}
        </span>
        <br *ngIf="element.creditType == 1 || element.creditType == 2" />
        <span>
          #{{ element.enrollmentId }}  {{ element.enrollmentName }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Miktar</th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{ element.amount | currency: element.currencyId | async }}
        </span>
      </td>
    </ng-container>
  </utclub-table>
</div>
