import { Component, Input, OnInit } from '@angular/core';
import { UtclubButtonInterface } from '../utclub-button/utclub-button.component';

@Component({
  selector: 'utclub-modal',
  templateUrl: './utclub-modal.component.html'
})
export class UtclubModalComponent implements OnInit {

  @Input() title: string;
  @Input() padding: string = "p-4";

  @Input() buttons: UtclubButtonInterface[];

  constructor() { }

  ngOnInit(): void {
  }

}
