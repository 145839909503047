import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core";

export class AutoCompeteOffExtension {
  postPopulate(field: FormlyFieldConfig): void {
    if (!field.props) {
      field.props = {};
    }
    if (!field.props.attributes) {
      field.props.attributes = {};
    }
    if (!field.props.attributes.autocomplete) {
      field.props.attributes.autocomplete = "off";
    }
  }
}

export function registerAutoCompeteOffExtension() {
  return {
    extensions: [
      {
        name: "autocomplete-off",
        extension: new AutoCompeteOffExtension(),
      },
    ],
  };
}
