<vex-page-layout>
  <vex-secondary-toolbar [current]="title">
    <vex-breadcrumbs [crumbs]="breadcrumbs" class="flex-auto"></vex-breadcrumbs>

    <ng-content select="[toolbar]"></ng-content>
  </vex-secondary-toolbar>

  <vex-page-layout-content class="container py-4">
    <ng-content></ng-content>
  </vex-page-layout-content>
</vex-page-layout>