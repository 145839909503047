import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnrollmentCreateFormComponent } from './enrollment-create-form.component';
import { UtclubModalModule } from 'src/app/components/utclub-modal/utclub-modal.module';
import { UtclubFormModule } from 'src/app/components/utclub-form/utclub-form.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [
    EnrollmentCreateFormComponent
  ],
  exports: [
    EnrollmentCreateFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,

    FormlyModule,

    UtclubModalModule,
    UtclubFormModule
  ]
})
export class EnrollmentCreateFormModule { }
