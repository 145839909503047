import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
@Component({
  selector: 'formly-field-tabs',
  template: `
    <mat-expansion-panel class="mb-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ to.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-4">
        <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
      </div>
    </mat-expansion-panel>
  `,
  styles: [
    `
      :host {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    `
  ]
})
export class ExpansionFieldComponent extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }
    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }
}
