import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { InvoiceInformationFormComponent } from './invoice-information-form/invoice-information-form.component';
import { UtclubTablePageComponent } from 'src/app/components/utclub-table/utclub-table-page.component';
import { UtclubTableComponent } from 'src/app/components/utclub-table/utclub-table.component';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-invoice-information',
  templateUrl: './invoice-information.component.html'
})
export class InvoiceInformationComponent extends UtclubTablePageComponent implements AfterViewInit {
  filterFields: CustomFormlyFieldConfig[] = [
    {
      key: 'search',
      type: 'input',
      props: {
        label: 'Ara'
      }
    },
    {
      type: '#school'
    }
  ];
  @ViewChild('utclubTable', { static: true }) table: UtclubTableComponent;
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService
  ) {
    super(
      {
        url: 'accounting-service/invoiceinformation/filter'
      },
      apiService,
      cdr
    );
    this.columns = [
      { property: 'title', visible: true, label: 'Baslik', columnType: 'text' },
      { property: 'name', visible: true, label: 'Isim', columnType: 'text' },
      { label: 'Actions', visible: true, property: 'actions', columnType: 'actions' }
    ];
  }

  ngAfterViewInit(): void {
    this.table.addCreateAction(InvoiceInformationFormComponent);
    this.table.addUpdateAction(InvoiceInformationFormComponent);
    super.ngAfterViewInit();
  }
}
