<form [formGroup]="form" (keydown.enter)="onSubmit()">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <p class="text-red" *ngIf="errorMessage" [innerHtml]="errorMessage"></p>

  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>

  <ng-content></ng-content>

  <button *ngIf="showSubmit" (click)="onSubmit()" mat-raised-button color="primary">Kaydet</button>
</form>
