import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtclubButtonComponent } from './utclub-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    UtclubButtonComponent
  ],
  exports: [
    UtclubButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,

    TranslateModule
  ]
})
export class UtclubButtonModule { }
