import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtclubButtonInterface } from '../utclub-button/utclub-button.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { PagedResponse } from 'src/app/models/response.model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UtclubTableColumn } from 'src/@vex/interfaces/table-column.interface';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { Enums } from 'src/app/services/enums';
import { LookupService } from 'src/app/services/lookup.service';
import { Enrollment } from 'src/app/models/enrollment.model';

@Component({
  selector: 'utclub-utclub-select-enrollment',
  templateUrl: './utclub-select-enrollment.component.html',
  styleUrls: ['./utclub-select-enrollment.component.scss'],
  animations: [fadeInUp400ms, stagger40ms]
})
export class UtclubSelectEnrollmentComponent implements OnInit {
  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading: boolean = false;
  pageSize: number = 10;
  pageIndex: number = 0;
  totalItemCount: number = 0;

  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private matDialogRef: MatDialogRef<UtclubSelectEnrollmentComponent>,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {}

  rowClick(enrollment: Enrollment) {
    this.matDialogRef.close(enrollment);
  }

  columns: UtclubTableColumn[] = [
    { property: 'name', visible: true, label: 'Ad', columnType: 'custom' },
    { property: 'type', visible: true, label: 'Tip', columnType: 'custom' },
    { property: 'ut', visible: true, label: 'Sube', columnType: 'custom' },
    { property: 'status', visible: true, label: 'Durum', columnType: 'custom' }
  ];

  loadData() {
    this.loading = true;
    this.apiService
      .post<PagedResponse<Enrollment>>('student-service/enrollment/filter?controlPermissions=false', {
        ...this.filterModel,
        page: this.paginator?.pageIndex ?? this.pageIndex,
        pageSize: this.paginator?.pageSize ?? this.pageSize
      })
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response.data);
        this.totalItemCount = response.itemsCount;
        this.paginator.length = response.itemsCount;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
  pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  trackByProperty<T>(index: number, column: UtclubTableColumn) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns.filter((column) => column.visible).map((column) => column.property);
  }

  filterFields = [
    {
      fieldGroupClassName: 'grid grid-cols-2 gap-3 gap-y-0',
      fieldGroup: [
        {
          type: 'input',
          key: 'firstName',
          props: {
            label: 'Ad'
          }
        },
        {
          type: 'input',
          key: 'lastName',
          props: {
            label: 'Soyad'
          }
        },
        {
          type: '#branch'
        },
        {
          type: 'enum-select',
          key: 'programType',
          props: {
            label: 'Kayit Tipi',
            enum: Enums.ProgramType
          }
        }
      ]
    }
  ];
  filterModel: any = {};
  filterForm = new FormGroup({});
}
