<utclub-modal title="Create Enrollment">
  <p class="text-red mb-2" *ngIf="errorMessage" [innerHtml]="errorMessage"></p>
  <div *ngIf="errors && errors.length > 0" class="mb-2">
    <ul>
      <li *ngFor="let error of errors" [innerHtml]="error.value" class="text-red"></li>
    </ul>
  </div>

  <mat-horizontal-stepper #stepper [linear]="true">
    <mat-step [stepControl]="personalFormGroup" label="Personal Information">
      <mat-progress-bar mode="determinate" *ngIf="loading"></mat-progress-bar>
      <formly-form [form]="personalFormGroup" [fields]="personalFields" [model]="model"></formly-form>

      <div class="flex">
        <div class="flex-1"></div>
        <button mat-raised-button matStepperNext color="primary">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="utFormGroup" label="UT Information">
      <formly-form [form]="utFormGroup" [fields]="utFields" [model]="model"></formly-form>
      <div class="flex">
        <button mat-raised-button matStepperPrevious color="accent">Back</button>
        <div class="flex-1"></div>
        <button mat-raised-button matStepperNext color="primary">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="programInformationFormGroup" label="Program Information">
      <formly-form [form]="programInformationFormGroup" [fields]="programFields" [model]="model"></formly-form>
      <div class="flex">
        <button mat-raised-button matStepperPrevious color="accent">Back</button>
        <div class="flex-1"></div>
        <mat-spinner *ngIf="submitLoading" class="mr-2" diameter="30" mode="indeterminate" color="accent"></mat-spinner>
        <button mat-raised-button color="primary" (click)="submit()" [disabled]="submitLoading">Submit</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</utclub-modal>
