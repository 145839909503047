<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span> Okul Bakiyeleri </span>
    </h2>

    <div class="bg-foreground rounded-full border px-2 max-w-[200px] flex-auto flex items-center ml-4">
      <mat-select [(ngModel)]="currencyId" (valueChange)="onCurrencyIdChange($event)">
        <mat-option *ngFor="let currency of currencies" [value]="currency.id">
          {{currency.name}}
        </mat-option>
      </mat-select>
    </div>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let item of data" (opened)="item.expanded = true;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ item.schoolName }}
        </mat-panel-title>
        <mat-panel-description>
          <p class="text-secondary m-0 mr-2 w-32">
            <span class="font-medium text-red"> {{ item.credit | currency:item.currencyId | async }} </span>
          </p>
          <p class="text-secondary m-0 mr-2 w-32">
            <span class="font-medium text-green"> {{ item.receipt | currency:item.currencyId | async }} </span>
          </p>
          <p class="text-secondary m-0 w-32">
            <span class="font-medium text-blue"> {{ item.diff | currency:item.currencyId | async }} </span>
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <utclub-school-balances-transactions *ngIf="item.expanded" [currencyId]="item.currencyId"
        [schoolId]="item.schoolId"></utclub-school-balances-transactions>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-entity="entity" matMenuContent>
    <button mat-menu-item>
      <mat-icon svgIcon="mat:close"></mat-icon>
      <span> Hesabi Kapat </span>
    </button>
  </ng-template>
</mat-menu>