import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtclubButtonInterface } from 'src/app/components/utclub-button/utclub-button.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'utclub-inquiry-send-offer-form',
  templateUrl: './inquiry-send-offer-form.component.html',
  styleUrls: ['./inquiry-send-offer-form.component.scss']
})
export class InquirySendOfferFormComponent {
  model: any = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email'
      }
    }
  ];
  buttons: UtclubButtonInterface[] = [
    {
      label: 'İptal',
      color: 'warn',
      click: () => {
        this.matDialogRef.close();
      },
      buttonType: 'button'
    },
    {
      label: 'Tamam',
      color: 'primary',
      click: () => {
        this.save();
      },
      buttonType: 'button'
    }
  ];
  invoiceInformation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private matDialogRef: MatDialogRef<InquirySendOfferFormComponent>,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    var inquiryIds = this.defaults.inquiryIds ?? [];
    if (this.defaults.inquiryId) {
      inquiryIds.push(this.defaults.inquiryId);
    }
    this.model = {
      offerId: this.defaults.offerId,
      email: this.defaults.email
    };
    this.form.patchValue(this.model);
  }

  save() {
    this.apiService.post('student-service/offer/send-mail', this.model).subscribe(
      (response) => {
        this.matSnackBar.open('Teklif başarıyla gönderildi.', 'OK', { duration: 3000 });
        this.matDialogRef.close({
          refresh: true
        });
      },
      (error) => {
        this.matSnackBar.open(error.error.message, 'OK', { duration: 3000 });
      }
    );
  }
}
