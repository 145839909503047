<mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

<mat-tab-group
  #tabGroup
  class="vex-tabs rounded"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  *ngIf="!loading && data">
  <mat-tab *ngFor="let logCategory of logCategories" [label]="logCategory.name">
    <div class="card">
      <ng-container *ngFor="let item of logCategory.logs">
        <mat-list-item *ngIf="item.logType == 1" class="p-4">
          <ng-container *ngTemplateOutlet="log1; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2" class="p-4">
          <ng-container *ngTemplateOutlet="log1; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 3" class="p-4">
          <ng-container *ngTemplateOutlet="log3; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1001" class="p-4">
          <ng-container *ngTemplateOutlet="log1001; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1002" class="p-4">
          <ng-container *ngTemplateOutlet="log1002; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1003" class="p-4">
          <ng-container *ngTemplateOutlet="log1003; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1004" class="p-4">
          <ng-container *ngTemplateOutlet="log1004; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1006" class="p-4">
          <ng-container *ngTemplateOutlet="log1006; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1007" class="p-4">
          <ng-container *ngTemplateOutlet="log1007; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1008" class="p-4">
          <ng-container *ngTemplateOutlet="log1008; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1009" class="p-4">
          <ng-container *ngTemplateOutlet="log1009; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1010" class="p-4">
          <ng-container *ngTemplateOutlet="log1010; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 1011" class="p-4">
          <ng-container *ngTemplateOutlet="log1011; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2001" class="p-4">
          <ng-container *ngTemplateOutlet="log2001; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2003" class="p-4">
          <ng-container *ngTemplateOutlet="log2003; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2004" class="p-4">
          <ng-container *ngTemplateOutlet="log2004; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2005" class="p-4">
          <ng-container *ngTemplateOutlet="log2005; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2008" class="p-4">
          <ng-container *ngTemplateOutlet="log2008; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2010" class="p-4">
          <ng-container *ngTemplateOutlet="log2010; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2011" class="p-4">
          <ng-container *ngTemplateOutlet="log2011; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2012" class="p-4">
          <ng-container *ngTemplateOutlet="log2012; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2013" class="p-4">
          <ng-container *ngTemplateOutlet="log2013; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2014" class="p-4">
          <ng-container *ngTemplateOutlet="log2014; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2015" class="p-4">
          <ng-container *ngTemplateOutlet="log2015; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2016" class="p-4">
          <ng-container *ngTemplateOutlet="log2016; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2017" class="p-4">
          <ng-container *ngTemplateOutlet="log2017; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2018" class="p-4">
          <ng-container *ngTemplateOutlet="log2018; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2019" class="p-4">
          <ng-container *ngTemplateOutlet="log2019; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2020" class="p-4">
          <ng-container *ngTemplateOutlet="log2020; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2021" class="p-4">
          <ng-container *ngTemplateOutlet="log2021; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2101" class="p-4">
          <ng-container *ngTemplateOutlet="log2101; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="item.logType == 2102" class="p-4">
          <ng-container *ngTemplateOutlet="log2102; context: { $implicit: item }"> </ng-container>
        </mat-list-item>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #log let-item>
  <div matLine>
    <student-log-title title="______" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1 let-item>
  <div matLine>
    <student-log-title title="Student Created" [log]="item"></student-log-title>
    <student-log-value-line
      title="First Name"
      oldValue="{{ item.oldVal.FirstName }}"
      newValue="{{ item.newVal.FirstName }}"></student-log-value-line>
    <student-log-value-line
      title="Last Name"
      oldValue="{{ item.oldVal.LastName }}"
      newValue="{{ item.newVal.LastName }}"></student-log-value-line>
    <student-log-value-line
      title="Email"
      oldValue="{{ item.oldVal.Email }}"
      newValue="{{ item.newVal.Email }}"></student-log-value-line>
    <student-log-value-line
      title="Phone Number"
      oldValue="{{ item.oldVal.PhoneNumber }}"
      newValue="{{ item.newVal.PhoneNumber }}"></student-log-value-line>
    <student-log-value-line
      title="Gender"
      oldValue="{{ item.oldVal.Gender | enum: 'Gender' | async }}"
      newValue="{{ item.newVal.Gender | enum: 'Gender' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Identity Number"
      oldValue="{{ item.oldVal.IdentityNumber }}"
      newValue="{{ item.newVal.IdentityNumber }}"></student-log-value-line>
    <student-log-value-line
      title="Birth Date"
      oldValue="{{ item.oldVal.BirthDate | date: 'dd/MM/yyyy' }}"
      newValue="{{ item.newVal.BirthDate | date: 'dd/MM/yyyy' }}"></student-log-value-line>
    <student-log-value-line
      title="City"
      oldValue="{{ item.oldVal.CityId | entity: 'City' | async }}"
      newValue="{{ item.newVal.CityId | entity: 'City' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Photo"
      oldValue="{{ item.oldVal.Photo }}"
      newValue="{{ item.newVal.Photo }}"></student-log-value-line>
    <student-log-value-line
      title="Education Level"
      oldValue="{{ item.oldVal.EducationLevel | enum: 'EducationLevel' | async }}"
      newValue="{{ item.newVal.EducationLevel | enum: 'EducationLevel' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Last School"
      oldValue="{{ item.oldVal.LastSchool }}"
      newValue="{{ item.newVal.LastSchool }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2 let-item>
  <div matLine>
    <student-log-title title="Student Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Photo"
      oldValue="{{ item.oldVal.Photo }}"
      newValue="{{ item.newVal.Photo }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log1001 let-item>
  <div matLine>
    <student-log-title title="Inquiry Created" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log3 let-item>
  <div matLine>
    <student-log-title title="Student Imported" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1002 let-item>
  <div matLine>
    <student-log-title title="Inquiry Follow Up Created" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1003 let-item>
  <div matLine>
    <student-log-title title="Inquiry Cancelled" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1004 let-item>
  <div matLine>
    <student-log-title title="Inquiry UT Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Branch"
      oldValue="{{ item.oldVal.BranchId | entity: 'Branch' | async }}"
      newValue="{{ item.newVal.BranchId | entity: 'Branch' | async }}"></student-log-value-line>

    <student-log-value-line
      title="Student Source"
      oldValue="{{ item.oldVal.StudentSource | enum: 'StudentSource' | async }}"
      newValue="{{ item.newVal.StudentSource | enum: 'StudentSource' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Referrer Id"
      oldValue="{{ item.oldVal.ReferrerId }}"
      newValue="{{ item.newVal.ReferrerId }}"></student-log-value-line>
    <student-log-value-line
      title="Referrer Text"
      oldValue="{{ item.oldVal.ReferrerText }}"
      newValue="{{ item.newVal.ReferrerText }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log1006 let-item>
  <div matLine>
    <student-log-title title="Inquiry Program Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Preferred Country 1"
      oldValue="{{ item.oldVal.PreferredCountry1Id | entity: 'Country' | async }}"
      newValue="{{ item.newVal.PreferredCountry1Id | entity: 'Country' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Preferred Country 2"
      oldValue="{{ item.oldVal.PreferredCountry2Id | entity: 'Country' | async }}"
      newValue="{{ item.newVal.PreferredCountry2Id | entity: 'Country' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Preferred Country 3"
      oldValue="{{ item.oldVal.PreferredCountry3Id | entity: 'Country' | async }}"
      newValue="{{ item.newVal.PreferredCountry3Id | entity: 'Country' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Program"
      oldValue="{{ item.oldVal.ProgramId | entity: 'Program' | async }}"
      newValue="{{ item.newVal.ProgramId | entity: 'Program' | async }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log1007 let-item>
  <div matLine>
    <student-log-title title="Inquiry Imported" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1008 let-item>
  <div matLine>
    <student-log-title title="Inquiry Offer Created" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1009 let-item>
  <div matLine>
    <student-log-title title="Inquiry Enrolled" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log1010 let-item>
  <div matLine>
    <student-log-title title="Inquiry User Change" [log]="item"></student-log-title>
    <student-log-value-line
      title="User"
      oldValue="{{ item.oldVal.UserName }}"
      newValue="{{ item.newVal.UserName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log1011 let-item>
  <div matLine>
    <student-log-title title="Inquiry Extra Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Extra 1"
      oldValue="{{ item.oldVal.Extra1 }}"
      newValue="{{ item.newVal.Extra1 }}"></student-log-value-line>
    <student-log-value-line
      title="Extra 2"
      oldValue="{{ item.oldVal.Extra2 }}"
      newValue="{{ item.newVal.Extra2 }}"></student-log-value-line>
    <student-log-value-line
      title="Extra 3"
      oldValue="{{ item.oldVal.Extra3 }}"
      newValue="{{ item.newVal.Extra3 }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2001 let-item>
  <div matLine>
    <student-log-title title="Enrollment Created" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log2003 let-item>
  <div matLine>
    <student-log-title title="Enrollment Cancelled" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log2004 let-item>
  <div matLine>
    <student-log-title title="Enrollment UT Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Branch"
      oldValue="{{ item.oldVal.BranchId | entity: 'Branch' | async }}"
      newValue="{{ item.newVal.BranchId | entity: 'Branch' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Student Source"
      oldValue="{{ item.oldVal.StudentSource | enum: 'StudentSource' | async }}"
      newValue="{{ item.newVal.StudentSource | enum: 'StudentSource' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Referrer Id"
      oldValue="{{ item.oldVal.ReferrerId }}"
      newValue="{{ item.newVal.ReferrerId }}"></student-log-value-line>
    <student-log-value-line
      title="Referrer Text"
      oldValue="{{ item.oldVal.ReferrerText }}"
      newValue="{{ item.newVal.ReferrerText }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2005 let-item>
  <div matLine>
    <student-log-title title="Enrollment Advisors Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Consultant"
      oldValue="{{ item.oldVal.ConsultantId | entity: 'User' | async }}"
      newValue="{{ item.newVal.ConsultantId | entity: 'User' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Support"
      oldValue="{{ item.oldVal.SupportId | entity: 'User' | async }}"
      newValue="{{ item.newVal.SupportId | entity: 'User' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Supervisor"
      oldValue="{{ item.oldVal.SupervisorId | entity: 'User' | async }}"
      newValue="{{ item.newVal.SupervisorId | entity: 'User' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Manager"
      oldValue="{{ item.oldVal.ManagerId | entity: 'User' | async }}"
      newValue="{{ item.newVal.ManagerId | entity: 'User' | async }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2008 let-item>
  <div matLine>
    <student-log-title title="Enrollment Debit Updated" [log]="item"></student-log-title>
  </div>
</ng-template>

<ng-template #log2010 let-item>
  <div matLine>
    <student-log-title title="Enrollment Visa Application Date Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Visa Application Date"
      oldValue="{{ item.oldVal.VisaApplicationDate | date: 'dd/MM/yyyy' }}"
      newValue="{{ item.newVal.VisaApplicationDate | date: 'dd/MM/yyyy' }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2011 let-item>
  <div matLine>
    <student-log-title title="Enrollment Visa Result Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Visa Result"
      oldValue="{{ item.oldVal.VisaResult }}"
      newValue="{{ item.newVal.VisaResult }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2012 let-item>
  <div matLine>
    <student-log-title title="Enrollment Flight Date Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Flight Date"
      oldValue="{{ item.oldVal.FlightDate | date: 'dd/MM/yyyy' }}"
      newValue="{{ item.newVal.FlightDate | date: 'dd/MM/yyyy' }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2013 let-item>
  <div matLine>
    <student-log-title title="Enrollment Dates Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Program Duration"
      oldValue="{{ item.oldVal.ProgramDuration }}"
      newValue="{{ item.newVal.ProgramDuration }}"></student-log-value-line>
    <student-log-value-line
      title="Program Start Date"
      oldValue="{{ item.oldVal.ProgramStartDate | date: 'dd/MM/yyyy' }}"
      newValue="{{ item.newVal.ProgramStartDate | date: 'dd/MM/yyyy' }}"></student-log-value-line>
    <student-log-value-line
      title="Program End Date"
      oldValue="{{ item.oldVal.ProgramEndDate | date: 'dd/MM/yyyy' }}"
      newValue="{{ item.newVal.ProgramEndDate | date: 'dd/MM/yyyy' }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2014 let-item>
  <div matLine>
    <student-log-title title="Enrollment School Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="School"
      oldValue="{{ item.oldVal.SchoolId | entity: 'School' | async }}"
      newValue="{{ item.newVal.SchoolId | entity: 'School' | async }}"></student-log-value-line>
    <student-log-value-line
      title="School Branch"
      oldValue="{{ item.oldVal.SchoolBranchId | entity: 'SchoolBranch' | async }}"
      newValue="{{ item.newVal.SchoolBranchId | entity: 'SchoolBranch' | async }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2015 let-item>
  <div matLine>
    <student-log-title title="Enrollment Location Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Country"
      oldValue="{{ item.oldVal.ProgramCountryName }}"
      newValue="{{ item.newVal.ProgramCountryName }}"></student-log-value-line>
    <student-log-value-line
      title="City"
      oldValue="{{ item.oldVal.ProgramCityName }}"
      newValue="{{ item.newVal.ProgramCityName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2016 let-item>
  <div matLine>
    <student-log-title title="Enrollment Language School Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Program"
      oldValue="{{ item.oldVal.ProgramName }}"
      newValue="{{ item.newVal.ProgramName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2017 let-item>
  <div matLine>
    <student-log-title title="Enrollment Summer School Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Program"
      oldValue="{{ item.oldVal.ProgramName }}"
      newValue="{{ item.newVal.ProgramName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2018 let-item>
  <div matLine>
    <student-log-title title="Enrollment Academic Infrmation Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Program"
      oldValue="{{ item.oldVal.ProgramName }}"
      newValue="{{ item.newVal.ProgramName }}"></student-log-value-line>
    <student-log-value-line
      title="Placement Channel"
      oldValue="{{ item.oldVal.PlacementChannel | enum: 'EnrollmentAcademicPlacementChannel' | async }}"
      newValue="{{
        item.newVal.PlacementChannel | enum: 'EnrollmentAcademicPlacementChannel' | async
      }}"></student-log-value-line>
    <student-log-value-line
      title="Service Provider"
      oldValue="{{ item.oldVal.ServiceProviderName }}"
      newValue="{{ item.newVal.ServiceProviderName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2019 let-item>
  <div matLine>
    <student-log-title title="Enrollment Internship Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Program"
      oldValue="{{ item.oldVal.ProgramName }}"
      newValue="{{ item.newVal.ProgramName }}"></student-log-value-line>
    <student-log-value-line
      title="Job Placement"
      oldValue="{{ item.oldVal.JobPlacement | enum: 'EnrollmentJobPlacementType' | async }}"
      newValue="{{ item.newVal.JobPlacement | enum: 'EnrollmentJobPlacementType' | async }}"></student-log-value-line>
    <student-log-value-line
      title="Job Placement Service Provider"
      oldValue="{{ item.oldVal.JobPlacementServiceProviderName }}"
      newValue="{{ item.newVal.JobPlacementServiceProviderName }}"></student-log-value-line>
    <student-log-value-line
      title="DS Service Provider"
      oldValue="{{ item.oldVal.DsServiceProviderName }}"
      newValue="{{ item.newVal.DsServiceProviderName }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2020 let-item>
  <div matLine>
    <student-log-title title="Enrollment Language School Invoice Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Gross"
      oldValue="{{ item.oldVal.Gross }}"
      newValue="{{ item.newVal.Gross }}"></student-log-value-line>
    <student-log-value-line
      title="Net"
      oldValue="{{ item.oldVal.Net }}"
      newValue="{{ item.newVal.Net }}"></student-log-value-line>
    <student-log-value-line
      title="Education Fee"
      oldValue="{{ item.oldVal.EducationFee }}"
      newValue="{{ item.newVal.EducationFee }}"></student-log-value-line>
    <student-log-value-line
      title="Currency"
      oldValue="{{ item.oldVal.CurrencyId | entity: 'Currency' | async }}"
      newValue="{{ item.newVal.CurrencyId | entity: 'Currency' | async }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2021 let-item>
  <div matLine>
    <student-log-title title="Enrollment Summer School Invoice Information Updated" [log]="item"></student-log-title>
    <student-log-value-line
      title="Pricing Type"
      oldValue="{{ item.oldVal.PricingType | enum: 'SummerSchoolIndividualPricingType' | async }}"
      newValue="{{
        item.newVal.PricingType | enum: 'SummerSchoolIndividualPricingType' | async
      }}"></student-log-value-line>
    <student-log-value-line
      title="Gross"
      oldValue="{{ item.oldVal.Gross }}"
      newValue="{{ item.newVal.Gross }}"></student-log-value-line>
    <student-log-value-line
      title="Net"
      oldValue="{{ item.oldVal.Net }}"
      newValue="{{ item.newVal.Net }}"></student-log-value-line>
    <student-log-value-line
      title="Education Fee"
      oldValue="{{ item.oldVal.EducationFee }}"
      newValue="{{ item.newVal.EducationFee }}"></student-log-value-line>
    <student-log-value-line
      title="Package Fee"
      oldValue="{{ item.oldVal.PackageFee }}"
      newValue="{{ item.newVal.PackageFee }}"></student-log-value-line>
    <student-log-value-line
      title="Currency"
      oldValue="{{ item.oldVal.CurrencyId | entity: 'Currency' | async }}"
      newValue="{{ item.newVal.CurrencyId | entity: 'Currency' | async }}"></student-log-value-line>
  </div>
</ng-template>

<ng-template #log2101 let-item>
  <div matLine>
    <student-log-title title="Enrollment Task Updated" [log]="item"></student-log-title>
    <p>Task Id: {{ item.newVal.TaskId }}</p>
    <p>Task Type: {{ item.newVal.TaskType }}</p>
  </div>
</ng-template>

<ng-template #log2102 let-item>
  <div matLine>
    <student-log-title title="Enrollment Task Completed" [log]="item"></student-log-title>
    <p>Task Id: {{ item.newVal.TaskId }}</p>
    <p>Task Type: {{ item.newVal.TaskType }}</p>
  </div>
</ng-template>
